import { OverviewNavBar } from "@/pages/Overview/layout/OverviewNavBar/OverviewNavBar";
import { Box, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { ChartLoader } from "./components/ChartLoader";
import { ChartSidebar } from "./components/ChartSidebar";

export const CustomCharts = () => {
  const [activeCharts, setActiveCharts] = useState<number[]>([]);

  const onChartSelect = useCallback(
    (id: number, checked: boolean) => {
      if (checked) {
        setActiveCharts([...activeCharts, id]);
      } else {
        setActiveCharts(activeCharts.filter((chartId) => chartId !== id));
      }
    },
    [activeCharts],
  );

  return (
    <>
      <OverviewNavBar />
      <Box>
        <Stack direction="row" gap={2}>
          <ChartSidebar
            activeCharts={activeCharts}
            selectChart={onChartSelect}
          />
          <ChartContainer activeCharts={activeCharts} />
        </Stack>
      </Box>
    </>
  );
};

const ChartContainer = ({ activeCharts }: { activeCharts: number[] }) => (
  <Stack
    gap={2}
    flex={1}
    sx={{
      overflowX: "auto",
      paddingX: 2,
      maxHeight: "calc(100vh - 96px)",
    }}
  >
    {activeCharts.map((chartId) => (
      <ChartLoader key={chartId} chartId={chartId} />
    ))}
  </Stack>
);
