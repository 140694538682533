import { formatNumber } from "@/utils/formatNumber";
import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Title,
  BoxPlotSeries,
  ScatterSeries,
  Legend,
  LineSeries,
} from "react-jsx-highcharts";

import { HighchartsCustomProvider } from "../highcharts";

interface BoxPlotProps {
  title: string;
  seriesName: string;
  categories: string[];
  boxplotData: number[][];
  scatterData: number[][];
  trendData?: number[][];
  yAxisTitle?: string;
}

export const BoxPlot = ({
  title,
  seriesName,
  categories,
  boxplotData,
  scatterData,
  trendData,
  yAxisTitle = "Weight (g)",
}: BoxPlotProps) => {
  return (
    <HighchartsCustomProvider>
      <HighchartsChart accessibility={{ enabled: false }} plotOptions={{}}>
        <Chart backgroundColor={"transparent"} type="boxplot" />
        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>
        <Tooltip useHTML />
        <XAxis
          labels={{ style: { color: "white" }, padding: 8 }}
          categories={categories}
        ></XAxis>
        <Legend
          itemStyle={{ color: "white" }}
          itemHoverStyle={{ color: "lightblue" }}
        ></Legend>
        <YAxis
          labels={{ style: { color: "white" } }}
          title={{ text: yAxisTitle, style: { color: "white" } }}
          showLastLabel
        >
          <BoxPlotSeries
            name={seriesName}
            fillColor="transparent"
            lineWidth={2}
            medianColor="white"
            medianDashStyle="LongDash"
            tooltip={{
              headerFormat: "<em>{point.key}</em><br>",
              pointFormatter: function (this) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const point = this as unknown as any;
                return `
                <b><span style='color:${point.series.color}'>●</span> ${
                  point.series.name
                }</b><br>
                Maximum: ${formatNumber(point.high)}<br>
                Upper quartile: ${formatNumber(point.q3)}<br>
                Median: ${formatNumber(point.median)}<br>
                Lower quartile: ${formatNumber(point.q1)}<br>
                Minimum: ${formatNumber(point.low)}
                `;
              },
            }}
            data={boxplotData}
          />
          <ScatterSeries
            name="Outliers"
            marker={{ fillColor: "orange", enabled: true, symbol: "dot" }}
            tooltip={{
              headerFormat: "{point.key}<br>",
              pointFormat: `<b><span style="color:orange">●</span> {series.name}</b><br>Value: {point.y}`,
            }}
            data={scatterData}
          />
          {trendData && (
            <LineSeries name="Trend" data={trendData} color="#ff00c3" />
          )}
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
