import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { useMachineContext } from "@/context/machine-context";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Stack } from "@mui/material";
import { useState } from "react";
import { SpeedInfo } from "../MachineDetail/components/SpeedInfo";
import { useGetDataListExternal } from "./api/useGetDataListExternal";
import { PhysicalAsset } from "./components/PhysicalAsset";
import { PhysicalDropdownList } from "./components/PhysicalDropdownList";
import { useTranslate } from "@/i18n/config";

const getDefaultAssets = (machine: string): string[] => {
  const localStorageData: { [key: string]: string[] } = JSON.parse(
    localStorage.getItem("physicalsFavorites") || "{}",
  );
  return Object.keys(localStorageData).length !== 0
    ? localStorageData[machine]
      ? localStorageData[machine]
      : []
    : [];
};

export const PhysicalMeasures = () => {
  const { machine } = useMachineContext();
  const { data } = useGetDataListExternal();
  const [selectedAssets, setSelectedAssets] = useState<string[]>(
    getDefaultAssets(machine!.machine),
  );
  const translate = useTranslate();
  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection />
        {data ? (
          <PhysicalDropdownList
            asset_list={data}
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
          />
        ) : null}
      </MachineViewNavBar>

      <Stack gap={4}>
        {data?.length ? (
          selectedAssets.map((asset) => (
            <PhysicalAsset asset={asset} key={asset} />
          ))
        ) : (
          <ShowEmptyData title={translate("physicals")}></ShowEmptyData>
        )}
        {machine?.hasSpeed ? <SpeedInfo /> : null}
      </Stack>
    </>
  );
};
