/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, LinearProgress, Stack, styled } from "@mui/material";
import { motion } from "framer-motion";
import { ContainerLayout } from "@/features/LandingPage/components/ContainerLayout";

const CategoryContainer = styled(ContainerLayout)({
  cursor: "pointer",
});

const generateRatioText = (amount: number = 0, total: number = 0) => {
  if (total === 0) {
    return "0%";
  } else {
    return `${Math.round((amount / total) * 100)}%`;
  }
};

const generateActiveStateBorder = (isActive: boolean, color: string) => {
  if (isActive) {
    return `2px solid ${color}`;
  } else {
    return "2px solid #00000000";
  }
};

export const LineStateCategory = ({
  total,
  stateAmount,
  stateString,
  stateColor,
  activeState,
  onStateSelected,
}: {
  total: number;
  stateString: string;
  stateAmount: number;
  stateColor: string;
  activeState: string | null;
  onStateSelected: () => void;
}) => {
  return (
    <CategoryContainer
      component={motion.div}
      flex={1}
      onClick={onStateSelected}
      // @ts-ignore
      whileHover={{ scale: 1.02 }}
      sx={{
        color: stateColor,
        minWidth: "10rem",
        fontweight: "bold",
        border: generateActiveStateBorder(
          activeState === stateString,
          stateColor,
        ),
      }}
    >
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="column"
      >
        <Box sx={{ fontWeight: "bold", pb: 0.5 }}>{stateString}</Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          pb={0.5}
        >
          <Box>{generateRatioText(stateAmount, total)}</Box>
          <Box>
            {stateAmount}/{total}
          </Box>
        </Box>
      </Stack>
      <LinearProgress
        color="inherit"
        variant="determinate"
        value={(stateAmount / total) * 100}
      />
    </CategoryContainer>
  );
};
