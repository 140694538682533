import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type TimeSelectionsListResponse = ThingworxError | TimeSelectionsListResult;

const TimeSelectionsListData = z.object({
  timelapse: z.string(),
  dateStart: z.number(),
  dateEnd: z.number(),
  id: z.number(),
});

export type TimeSelectionsListData = z.infer<typeof TimeSelectionsListData>;

const TimeSelectionsListResult = z.object({
  response: z.literal(true),
  list: z.array(TimeSelectionsListData),
});

type TimeSelectionsListResult = z.infer<typeof TimeSelectionsListResult>;

type Payload = {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  timeSelection: string;
};

export const useGetTimeSelectionList = () => {
  const { machine } = useMachineContext();

  const { dates, timeSelection } = useTimeSelection();

  const { appKey } = useFirebaseContext();

  return useQuery<TimeSelectionsListData[]>({
    queryKey: [
      "time-selections-list",
      timeSelection,
      dates.dateStart,
      dates.dateEnd,
      machine?.machine,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, TimeSelectionsListResponse>({
        appKey,
        payload: {
          dateStart: dates.dateStart,
          dateEnd: dates.dateEnd,
          machineName: machine?.machine || "",
          timeSelection,
        },
        url: "dig.c.timeSelectionUtility_thing/Services/getTimeSelectionsList",
      });

      if (!response.response) throw new Error(response.errorString);

      return TimeSelectionsListResult.parse(response).list;
    },
    enabled: !!machine?.machine,
  });
};
