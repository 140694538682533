import { z } from "zod";
import { useTranslate } from "@/i18n/config";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { Card } from "@/components/Layout/Card";
import { useGetAlarmsByRange } from "../api/useGetAlarmsByRange";
import { parseAlarmsStatesLog } from "../utils/parseAlarmsStatesLog";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { getCustomHeightFromN } from "../utils/getCustomHeightFromN";

export const ErrorStateModal = ({
  dateStart,
  dateEnd,
  isOpen,
  close,
}: {
  dateStart: number;
  dateEnd: number;
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetAlarmsByRange({
    dateStart,
    dateEnd,
  });

  if (isLoading)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="lg" fullWidth>
        <DialogTitle>
          {translate("stop_imputation.machine_states_log_by_imputation")}
        </DialogTitle>
        <DialogContent>
          <SkeletonCard height={150} />
        </DialogContent>
      </Dialog>
    );

  if (error) return <div>{translate("user_feedback.an_error_occurred")}</div>;

  if (!data) return <div>{translate("user_feedback.no_data")}</div>;

  const { categories, chartData } = parseAlarmsStatesLog({
    data: data.data,
    color: "#dd0000",
  });

  return (
    <Dialog onClose={close} open={isOpen} maxWidth="lg" fullWidth>
      <DialogTitle>{translate("alarms.alarms_detail")}</DialogTitle>
      <DialogContent>
        <Card>
          <XRangeChart
            categories={categories}
            data={chartData}
            customHeight={getCustomHeightFromN(categories.length)}
            title=""
            tooltipOutside={false}
            tooltipFormatter={function () {
              const pointData = z
                .object({
                  x: z.number(),
                  x2: z.number(),
                  color: z.string(),
                  name: z.string(),
                })
                .safeParse(this.point);

              if (!pointData.success) return "";

              const { x, x2, color, name } = pointData.data;

              const dateStart = TimeHelpers.parseTimestampToString({
                timestamp: x,
              });
              const dateEnd = TimeHelpers.parseTimestampToString({
                timestamp: x2,
              });
              return `Start: ${dateStart}<br>
                End: ${dateEnd}<br>
                <span style="color:${color}">●</span> <b>${name}</b>`;
            }}
          />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          {translate("actions.back")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
