import { useState } from "react";
import { Tabs, Tab, Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Descriptive } from "./Descriptive";
import { ConditionMonitoring } from "./ConditionMonitoring";
import { BatchRecipeSelection } from "../components/Selections/BatchRecipeSelection";
import { MotorSelection } from "../components/Selections/MotorSelection";

const SECTIONS = {
  DESCRIPTIVE: 0,
  CONDITION_MONITORING: 1,
};

export const Monitoring = () => {
  const [section, setSection] = useState(SECTIONS.DESCRIPTIVE);
  const translate = useTranslate();

  return (
    <>
      <Tabs
        value={section}
        aria-label="motors monitoring tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}
      >
        <Tab
          value={SECTIONS.DESCRIPTIVE}
          label={translate("monitoring.descriptive")}
        />
        <Tab
          value={SECTIONS.CONDITION_MONITORING}
          label={translate("monitoring.condition_monitoring")}
        />
      </Tabs>
      <Stack gap={1}>
        <BatchRecipeSelection />
        <MotorSelection />
        {section === SECTIONS.DESCRIPTIVE ? (
          <Descriptive />
        ) : section === SECTIONS.CONDITION_MONITORING ? (
          <ConditionMonitoring />
        ) : null}
      </Stack>
    </>
  );
};
