import { Box, AppBar, Toolbar } from "@mui/material";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";

export const IntellectaNavBar = ({ children }: React.PropsWithChildren) => {
  const { showBanner } = useCheckIfMessageBanner();

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          component="nav"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            width: "calc(100% - 64px)",
            mt: showBanner ? "32px" : 0,
            // boxShadow: "none",
            borderBottom: "1px solid #151B2F",
            backgroundImage: "none",
            height: "64px",
          }}
        >
          <Toolbar>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                width: "100%",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              {children}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <div style={{ height: "calc(64px)" }}></div>
    </>
  );
};
