import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { ThingworxError } from "src/types/error";
import { run } from "../types";
import { useMotorsContext } from "../context/useMotorsContext";

type RunsResponse = ThingworxError | RunsResult;

const RunsResult = z.object({
  response: z.literal(true),
  runs: z.array(run),
});

export type RunsResult = z.infer<typeof RunsResult>;

interface Payload {
  machineName: string;
  dateStart: number;
  dateEnd: number;
}

export const useGetRuns = () => {
  const { machine } = useMachineContext();
  const { selectedItems } = useMotorsContext();
  const { appKey } = useFirebaseContext();

  return useQuery<RunsResult>({
    queryKey: ["runs", machine?.machine, JSON.stringify(selectedItems)],
    queryFn: async () => {
      const dateStart = selectedItems[0]
        ? selectedItems[0].dateStart
        : new Date().getMilliseconds();
      const dateEnd = selectedItems[0]
        ? selectedItems[0].dateEnd
        : new Date().getMilliseconds();

      const response = await FetchClient<Payload, RunsResponse>({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
        },
        url: "dig.c.motorUtility_thing/Services/getRuns",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = RunsResult.parse(response);
      return validatedResponse;
    },
  });
};
