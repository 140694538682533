import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { queryKeyBuilder } from "../utils/alarmsQueryBuilder";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { FetchClient } from "@/services/ApiClient";

export type GetAlarmsTableDataResponse =
  | ThingworxError
  | GetAlarmsTableDataResult;

const AlarmsTableData = z.object({
  ErrorDescription: z.string(),
  FamilyDescription: z.string(),
  timestampEnd: z.number(),
  EfficiencyFamilyDescription: z.string(),
  Zone: z.number(),
  Error: z.number(),
  Family: z.number(),
  Timestamp: z.number(),
  durationms: z.number(),
  ZoneDescription: z.string(),
});

export type AlarmsTableData = z.infer<typeof AlarmsTableData>;

const GetAlarmsTableDataResult = z.object({
  array: z.array(AlarmsTableData),
});

export type GetAlarmsTableDataResult = z.infer<typeof GetAlarmsTableDataResult>;

type Payload = {
  dateStart?: number;
  dateEnd?: number;
  timeSelection: TimeSelection;
  machineName: string;
};

export const useGetAlarmsTableData = () => {
  const { appKey } = useFirebaseContext();

  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();

  const dateStart =
    timeSelection === "Custom" ? dates.dateStart.toMillis() : undefined;
  const dateEnd =
    timeSelection === "Custom" ? dates.dateEnd.toMillis() : undefined;

  return useQuery<GetAlarmsTableDataResult>({
    queryKey: [
      ...queryKeyBuilder({
        queryName: "alarms-table-data",
        payload: {
          dateStart,
          dateEnd,
        },
        timeSelection,
      }),
      machine?.machine,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetAlarmsTableDataResponse>({
        appKey,
        url: "dig.c.error_thing/Services/getStream",
        payload: {
          machineName: machine?.machine ?? "",
          timeSelection,
          dateStart,
          dateEnd,
        },
      });

      if ("response" in response && !response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = GetAlarmsTableDataResult.parse(response);

      return validatedResponse;
    },
    refetchInterval: timeSelection === "Custom" ? false : 1000 * 30,
  });
};
