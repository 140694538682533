import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type MachineDetailResponse = ThingworxError | MachineDetailResult;

export const StateCategory = z.enum([
  "Producing",
  "Not Producing",
  "Line",
  "Setup Pre-Production",
  "Intentional No Production",
  "Disconnected",
]);

export type StateCategory = z.infer<typeof StateCategory>;

const CounterDictTime = z.object({
  wasteCounter: z.number(),
  wasteCounterInteraction: z.number(),
  counterPack: z.number(),
  wasteCounterLine: z.number(),
});
export type CounterDictTime = z.infer<typeof CounterDictTime>;

export const PieData = z.object({
  color: z.string(),
  name: z.string(),
  y: z.number(),
  z: z.number(),
});

export type PieData = z.infer<typeof PieData>;

const MachineDetailResult = z.object({
  pieData: z.array(PieData),
  recipe: z.string(),
  availability: z.number(),
  stateString: z.string(),
  recipeChanged: z.boolean(),
  oee: z.number(),
  wasteCounterLine: z.number(),
  alarmDescription: z.string(),
  timer: z.array(z.string()),
  balancingSpeed: z.number(),
  wasteCounterInteraction: z.number(),
  setpointSpeed: z.number(),
  batchIdentifier: z.string(),
  wasteCounterMachine: z.number(),
  totalTime: z.number(),
  recipeClient: z.string(),
  maximumRecipeSpeed: z.number(),
  currentSpeed: z.number(),
  inactiveTime: z.number(),
  quality: z.number(),
  uptime: z.number(),
  stateColor: z.string(),
  goodCounter: z.number(),
  totalProduct: z.number(),
  performance: z.number(),
  response: z.literal(true),
  interactionSpeed: z.number(),
  counterDictTime: CounterDictTime,
  category: StateCategory,
  firstAlarm: z.number(),
  elapsedTime: z.number(),
  // timelapseStart: z.number(),
  // timelapseEnd: z.number(),
});

export type MachineDetailResult = z.infer<typeof MachineDetailResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
};

export const useGetSentinelLiveData = () => {
  const { timeSelection } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  return useQuery<MachineDetailResult>({
    queryKey: ["machinedetail-sentinel-live", timeSelection, machine!.machine],
    queryFn: async () => {
      const response = await FetchClient<Payload, MachineDetailResponse>({
        appKey,
        url: "dig.c.machineDetail_thing/Services/getData",
        payload: {
          machineName: machine!.machine ?? "",
          timeSelection,
        },
      });

      if (!response.response) throw new Error(response.errorString);

      return MachineDetailResult.parse(response);
    },
    refetchInterval: 10000,
  });
};
