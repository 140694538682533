import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type Response = ThingworxError | ManualListResult;

const downloadableDocument = z.object({
  name: z.string(),
  filesPath: z.string(),
});
export type DownloadableDocument = z.infer<typeof downloadableDocument>;

const filePath = z.record(z.array(downloadableDocument));
type FilePath = z.infer<typeof filePath>;

const manualListResult = z.object({
  response: z.literal(true),
  filePath: filePath,
});
export type ManualListResult = z.infer<typeof manualListResult>;

export const useGetManualList = () => {
  const payload = {};

  return useQuery<FilePath>({
    queryKey: ["manual-list"],
    queryFn: async () => {
      const res = await fetch(
        "https://ms.iotdataroom.com/report-manager/manual-list",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const response = (await res.json()) as Response;
      if (!response.response) {
        throw new Error(response.errorString);
      }
      const validatedResponse = manualListResult.parse(response);
      return validatedResponse.filePath;
    },
  });
};
