import { RangePicker } from "@/components/Calendars/RangePicker";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useGetDownloadData } from "./api/useGetDownloadData";
import { RangeOeeInput } from "./components/RangeOeeInput";
import { DownloadTable } from "./components/DownloadTable";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { useGetMinMaxOeeRanges } from "@/features/Download/api/useGetMinMaxOeeRanges";

export const Download = () => {
  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection
          exclude={[
            "Batch",
            "Custom",
            "Month",
            "SixMonths",
            "ThreeMonths",
            "Year",
            "TwoWeeks",
          ]}
        />
        <RangePicker
          onPickDate={() => {}}
          initialDates={[DateTime.now().minus({ months: 1 }), DateTime.now()]}
        />
      </MachineViewNavBar>
     <DownloadContent />
    </>
  );
};

const DownloadContent = () => {
  const { data, isLoading } = useGetDownloadData();
  const { data: ranges } = useGetMinMaxOeeRanges();
  return (
    <Stack gap={2} height="100%">
      {ranges ? (
        <RangeOeeInput min={ranges.lowerBound} max={ranges.upperBound} />
      ) : (
        <SkeletonCard />
      )}
      {isLoading ? (
        <SkeletonCard height={400} />
      ) : data?.processedDataTable.length && data?.columnsDefinition.length ? (
        <DownloadTable
          dataTable={data.processedDataTable}
          columnsDef={data.columnsDefinition}
          maxBad={ranges?.lowerBound}
          maxMedium={ranges?.upperBound}
        />
      ) : (
        <ShowEmptyData title="Download" />
      )}
    </Stack>
  );
}