import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { StatesLogResponse, StatesLogResult, statesLogResult } from "../types";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
};

export const useGetStatesLogData = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { timeSelection } = useTimeSelection();

  return useQuery<StatesLogResult>({
    queryKey: ["states-log-data", machine!.machine],
    queryFn: async () => {
      const response = await FetchClient<Payload, StatesLogResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          timeSelection,
        },
        url: "dig.c.stopImputation_thing/Services/getStatesLogData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = statesLogResult.parse(response);

      return validatedResponse;
    },
  });
};
