import { Box, Stack, Typography } from "@mui/material";
import { useGetSummaryData } from "../../api/Summary/useGetSummaryData";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { InfoCard } from "../../components/Summary/InfoCard";
import { BarChart } from "../../components/Summary/BarChart";
import { readablePhase } from "../../utils/readablePhase";
// import { TagPieChart } from "../../components/Summary/TagPieChart";
import { SummaryPieChart } from "../../components/Summary/SummaryPieChart";
import { BasicTable } from "@/components/BasicTable";
import { TableParser } from "@/utils/TableParser";
import { Evaluation, Phase, Subphase, SummaryTableData } from "../../types";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";

export const Summary = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetSummaryData();

  if (isLoading) return <SkeletonCard height={180} />;
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!data) return <Card>{translate("user_feedback.no_data")}</Card>;
  const {
    currentPhase,
    currentPhaseStatus,
    currentBatch,
    currentRecipe,
    elapsedTime,
    expectedTime,
    barChartData,
    pieData,
    // tagData,
    tableData,
  } = data;

  const { rows, columns } = TableParser.parseData<SummaryTableData>({
    data: tableData,
    columnsMap: {
      batchName: translate("analytics.batch"),
      phase: translate("isolator.phase"),
      phaseDuration: translate("isolator.phase_duration"),
      phaseStatus: translate("isolator.phase_status"),
      phaseTsStart: translate("isolator.phase_start"),
      subphase: translate("isolator.subphase"),
      subphaseStatus: translate("isolator.subphase_status"),
    },
    columnsOrder: [
      "batchName",
      "phase",
      "phaseStatus",
      "phaseTsStart",
      "phaseDuration",
      "subphase",
      "subphaseStatus",
    ],
    omitColumns: [],
    customColumns: [],
    dateColumns: ["phaseTsStart"],
  });

  const phaseColumnDefinition = columns.findIndex(
    (item) => item.field === "phase",
  );

  if (phaseColumnDefinition !== -1) {
    columns[phaseColumnDefinition].renderCell = (params) => {
      const { value } = params;
      return readablePhase(value as Phase);
    };
  }

  const subphaseColumnDefinition = columns.findIndex(
    (item) => item.field === "subphase",
  );

  if (subphaseColumnDefinition !== -1) {
    columns[subphaseColumnDefinition].renderCell = (params) => {
      const { value } = params;
      return readablePhase(value as Subphase);
    };
  }

  const phaseStatusColumnDefinition = columns.findIndex(
    (item) => item.field === "phaseStatus",
  );

  if (phaseStatusColumnDefinition !== -1) {
    columns[phaseStatusColumnDefinition].renderCell = (params) => {
      const { value } = params;
      return (
        <Box
          sx={{
            backgroundColor: EVALUATION_COLORS[value as Evaluation].selected,
            aspectRatio: "1/1",
            width: ".75rem",
            height: ".75rem",
            borderRadius: "10000px",
          }}
        />
      );
    };
  }

  const subphaseStatusColumnDefinition = columns.findIndex(
    (item) => item.field === "subphaseStatus",
  );

  if (subphaseStatusColumnDefinition !== -1) {
    columns[subphaseStatusColumnDefinition].renderCell = (params) => {
      const { value } = params;
      return (
        <Box
          sx={{
            backgroundColor: EVALUATION_COLORS[value as Evaluation].selected,
            aspectRatio: "1/1",
            width: ".75rem",
            height: ".75rem",
            borderRadius: "10000px",
          }}
        />
      );
    };
  }

  return (
    <Stack gap={1} pt="1rem">
      <Typography variant="h6">
        {translate("isolator.current_batch_live_data")}
      </Typography>
      <Box sx={{ display: "flex", gap: 1 }}>
        <InfoCard
          title={translate("isolator.current_phase")}
          content={readablePhase(currentPhase)}
          status={currentPhaseStatus}
        />
        <InfoCard title={translate("analytics.batch")} content={currentBatch} />
        <InfoCard title={translate("machine.recipe")} content={currentRecipe} />
        <InfoCard
          title={translate("machine.elapsed_time")}
          subTitle="hh:mm:ss"
          content={elapsedTime}
        />
        <InfoCard
          title={
            currentPhase === "production"
              ? translate("machine.expected_production_time")
              : translate("machine.expected_time_to_production")
          }
          subTitle="hh:mm:ss"
          content={expectedTime}
        />
      </Box>
      {/* {currentPhase === "production" && tagData && (
        <>
        <Box sx={{ display: "flex", gap: 1 }}>
        <TagPieChart title="Pressure tags" tagData={tagData.pressure} />
        <TagPieChart
        title="Temperature tags"
        tagData={tagData.temperature}
        />
        <TagPieChart title="Humidity tags" tagData={tagData.humidity} />
        </Box>
        </>
      )} */}
      <Card
        sx={{ flex: 3 }}
        initial={{ opacity: 0.5, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0 }}
      >
        <BarChart
          data={barChartData}
          isProduction={currentPhase === "production"}
          batchName={currentBatch}
        />
      </Card>
      <Typography variant="h6">
        {translate("isolator.last_10_batches")}
      </Typography>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Card sx={{ flex: 3, maxWidth: "75%" }}>
          <BasicTable
            columns={columns}
            rows={rows}
            fileName="iso_summary_data"
          />
        </Card>
        <Card
          sx={{ flex: 1 }}
          initial={{ opacity: 0.5, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0 }}
        >
          <SummaryPieChart data={pieData} phase={currentPhase} />
        </Card>
      </Box>
    </Stack>
  );
};
