import { useState } from "react";
import { toast } from "react-hot-toast";
import { z } from "zod";

export const documentResult = z.object({
  file_content: z.object({
    data: z.array(z.number()),
    type: z.literal("Buffer"),
  }),
  response: z.literal(true),
});
export type DocumentResult = z.infer<typeof documentResult>;

export const useDownloadDocument = ({
  filePath,
  fileName,
}: {
  filePath: string;
  fileName: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const payload = {
    filePath,
  };
  const downloadDocument = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(
        "https://ms.iotdataroom.com/report-manager/get-file",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(payload),
        },
      );
      const response = (await res.json()) as DocumentResult;

      if (!response) return;
      const validatedResponse = documentResult.parse(response);
      // Crea un Blob dal buffer del PDF
      const pdfBlob = new Blob(
        [new Uint8Array(validatedResponse.file_content.data).buffer],
        { type: "application/pdf" },
      );

      // Crea un URL oggetto dal Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Crea un elemento anchor per il download
      const a = document.createElement("a");
      a.href = pdfUrl;
      a.download = `${fileName}.pdf`;
      a.style.display = "none";

      // Aggiungi l'elemento anchor al documento e fai clic su di esso per avviare il download
      document.body.appendChild(a);
      a.click();

      // Rimuovi l'elemento anchor dopo il download
      document.body.removeChild(a);

      // Rilascia l'URL oggetto
      URL.revokeObjectURL(pdfUrl);
      toast.success("File downloaded successfully");
    } catch (error) {
      console.error("Something went wrong", error);
      toast.error("Failed to download");
    }
    setIsLoading(false);
  };

  return { downloadDocument, isLoading };
};
