import { BasicTable } from "@/components/BasicTable";
import { Card } from "@/components/Layout/Card";
import { LoaderCard } from "@/components/Layout/LoaderCard";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { TableParser } from "@/utils/TableParser";
import { Stack } from "@mui/material";
import { useState } from "react";
import {
  AnomalyCountOverview,
  useGetAnomalyDetectionsCountOverview,
} from "../api/useGetAnomalyDetectionsCountOverview";
import { AdvancedAnalyticsList } from "./AdvancedAnalyticsList";
import { useTranslate } from "@/i18n/config";

export const AnomalyDetectionOverview = () => {
  const { data = [], isLoading } = useGetAnomalyDetectionsCountOverview();

  const distinctAnalyticNamesAndId = data.reduce(
    (acc, item) => {
      if (!acc.find((i) => i.display_name === item.display_name)) {
        acc.push({
          analytic_base_id: item.analytic_base_id,
          display_name: item.display_name,
          name: item.name,
        });
      }
      return acc;
    },
    [] as { analytic_base_id: number; display_name: string; name: string }[],
  );

  if (isLoading) {
    return <LoaderCard />;
  }

  if (!data.length) {
    return <ShowEmptyData title="Anomaly Detection" />;
  }

  return (
    <AdvancedAnalyticContent
      analytics={distinctAnalyticNamesAndId}
      anomalies={data}
    />
  );
};

function AdvancedAnalyticContent({
  analytics,
  anomalies,
}: {
  analytics: { analytic_base_id: number; display_name: string; name: string }[];
  anomalies: AnomalyCountOverview[];
}) {
  const [selectedAnalytic, setSelectedAnalytic] = useState(
    analytics[0].analytic_base_id,
  );

  return (
    <Stack gap={2}>
      <AdvancedAnalyticsList
        items={analytics.map((item) => ({
          id_value: item.analytic_base_id,
          display_name: item.display_name,
        }))}
        activeId={selectedAnalytic}
        renderTextItem={(item) => `${item.display_name}`}
        onItemClicked={(item) => setSelectedAnalytic(item.id_value)}
      />
      <Card>
        <Table
          anomalies={anomalies.filter(
            (anomaly) => anomaly.analytic_base_id === selectedAnalytic,
          )}
        />
      </Card>
    </Stack>
  );
}

function Table({ anomalies }: { anomalies: AnomalyCountOverview[] }) {
  const translate = useTranslate();

  const { columns, rows } =
    anomalies.length > 0
      ? TableParser.parseData({
          data: anomalies,
          columnsMap: {
            batch_name: translate("analytics.batch"),
            anomalies: translate("diagnostics.lyo.anomalies-novelties"),
            uom: "uom",
            anomaly_percentage:
              translate("diagnostics.lyo.anomalies-novelties") + "%",
            asset_id: "Asset ID",
            batch_from_timestamp: translate("diagnostics.lyo.batch_start"),
            batch_to_timestamp: translate("diagnostics.lyo.batch_end"),
            from_timestamp: translate("diagnostics.lyo.analysis_started_at"),
            to_timestamp: translate("diagnostics.lyo.analysis_ended_at"),
            asset_name: translate("name"),
            analytic_base_id: "Analytic ID",
            recipe: translate("machine.recipe"),
            display_name: translate("analytics"),
            name: translate("analytic_name"),
            total: translate("diagnostics.lyo.datapoints_analyzed"),
          },
          columnsOrder: [
            "display_name",
            "batch_name",
            "asset_name",
            "from_timestamp",
            "to_timestamp",
            "total",
            "anomalies",
            "anomaly_percentage",
            "uom",
            "asset_id",
            "batch_from_timestamp",
            "batch_to_timestamp",
            "recipe",
          ],
          omitColumns: [
            "uom",
            "asset_id",
            "recipe",
            "name",
            "analytic_base_id",
          ],
        })
      : { columns: [], rows: [] };

  return (
    <BasicTable
      columns={columns}
      rows={rows.map((row) => ({
        ...row,
        anomaly_percentage: row.anomaly_percentage + "%",
      }))}
      fileName="anomaly_detection_data"
    />
  );
}
