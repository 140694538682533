import { Fragment, useState } from "react";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  ReactFlow,
  Controls,
  Background,
  BackgroundVariant,
  ReactFlowProvider,
  applyNodeChanges,
  Panel,
} from "reactflow";
import { getNodeWidth } from "../../../utils/formulas";
import { useNodeConfiguration } from "../hooks/useNodeConfiguration";
import { machineNodeTypes } from "../custom-nodes/ModalEditorNode";
import {
  NodeEditorContextProvider,
  useNodeEditorContext,
} from "../context/NodeEditorContextProvider";
import { useOeeLineContextDispatch } from "../../LineOverview/context/useOeeLineContextDispatch";
import { useLineEditorDispatch } from "../context/useLineEditorDispatch";
import { useOeeLineContextState } from "../../LineOverview/context/useOeeLineContextState";
import { useTranslate } from "@/i18n/config";

export const NodeEditorModal = (
  props: React.ComponentProps<typeof Dialog> & { close: () => void },
) => {
  const { close, ...rest } = props;
  return (
    <Dialog {...rest} maxWidth="lg">
      <NodeEditorContextProvider>
        <NodeEditor close={close} />
      </NodeEditorContextProvider>
    </Dialog>
  );
};

const NodeEditor = ({ close }: { close: () => void }) => {
  const translate = useTranslate();
  const [dimension, setDimension] = useState(1);
  const [lineLayer, setLineLayer] = useState<"" | number>("");
  const [isEnabled, setIsEnabled] = useState(true);
  const { name, isPlaceholder, renderConfiguration } = useNodeConfiguration();
  const { handles } = useNodeEditorContext();
  const lineConfigDispatch = useOeeLineContextDispatch();
  const lineEditorDispatch = useLineEditorDispatch();

  /**
   * Removed id with name to avoid duplicate ids,
   * this needs to be checked later
   */
  const node = {
    id: uuidv4(),
    data: {
      name: name,
      isPlaceholder,
      handles,
    },
    position: { x: 0, y: 0 },
    draggable: true,
    type: "machine",
    style: { width: getNodeWidth(dimension) },
  };

  const saveNodeHandler = () => {
    if (lineLayer === "") return toast.error("Line layer cannot be null");
    lineConfigDispatch({
      type: "add machine info",
      machine_info: {
        machine_name: name,
        is_enabled: isEnabled,
        line_layer: lineLayer,
      },
    });
    lineEditorDispatch({ type: "add node", node: node });
    close();
  };

  const handleChangeIsEnabled = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsEnabled(event.target.checked);
  };

  return (
    <Fragment>
      <DialogTitle>Node editor</DialogTitle>

      <DialogContent>
        <Stack gap={2}>
          {renderConfiguration()}
          <Box
            sx={{
              height: 400,
              border: "1px solid rgba(255,255,255,.3)",
              borderRadius: 1,
              boxShadow: "1px 1px 1px 0px black",
            }}
          >
            {name ? (
              <ReactFlowProvider>
                <ReactFlow
                  proOptions={{
                    hideAttribution: true,
                  }}
                  fitView
                  nodeTypes={machineNodeTypes}
                  nodes={[node]}
                  onNodesChange={(_nodes) => applyNodeChanges(_nodes, [node])}
                >
                  <Controls showInteractive={false} />
                  <Background
                    lineWidth={1}
                    color="#0000000f"
                    variant={BackgroundVariant.Lines}
                  />
                  <Panel position="top-left">
                    <TextField
                      type="number"
                      size="small"
                      value={dimension}
                      sx={{ width: 100 }}
                      InputProps={{
                        inputProps: {
                          max: 5,
                          min: 1,
                        },
                      }}
                      onChange={(e) => setDimension(+e.target.value)}
                      label="Width"
                    />
                  </Panel>
                  <Panel position="top-center">
                    <TextField
                      type="number"
                      size="small"
                      value={lineLayer}
                      sx={{ width: 100 }}
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                      onChange={(e) => {
                        if (lineLayer === "") {
                          setLineLayer(0);
                        } else {
                          setLineLayer(+e.target.value);
                        }
                      }}
                      label="Line layer"
                    />
                  </Panel>
                  <Panel position="top-right">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isEnabled}
                          onChange={handleChangeIsEnabled}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Enabled"
                    />
                  </Panel>
                </ReactFlow>
              </ReactFlowProvider>
            ) : null}
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" autoFocus onClick={() => close()}>
          {translate("actions.cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={saveNodeHandler}
          disabled={lineLayer === ""}
        >
          {/* Add */}
          {translate("actions.confirm")}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export const UpdateNodeEditor = ({
  close,
  nodeId,
  nodeName,
  isPlaceholder,
  position,
}: {
  close: () => void;
  nodeId: string;
  nodeName: string;
  isPlaceholder: boolean;
  position: { x: number; y: number };
}) => {
  const translate = useTranslate();
  const dispatchLineConfig = useOeeLineContextDispatch();
  const { machinesInfo } = useOeeLineContextState();
  const machineCurrentConfig = machinesInfo.find(
    (el) => el.machine_name === nodeName,
  );

  const [dimension, setDimension] = useState(1);
  const [lineLayer, setLineLayer] = useState<"" | number>(
    machineCurrentConfig ? machineCurrentConfig.line_layer : "",
  );
  const [isEnabled, setIsEnabled] = useState(
    machineCurrentConfig ? machineCurrentConfig.is_enabled : true,
  );
  const [name, setName] = useState(nodeName);
  const { handles } = useNodeEditorContext();

  const dispatch = useLineEditorDispatch();

  /**
   * Removed id with name to avoid duplicate ids,
   * this needs to be checked later
   */
  const node = {
    // id: `${uuidv4()}-${name}`,
    id: nodeId,
    data: {
      name: name,
      isPlaceholder,
      handles,
    },
    position,
    draggable: true,
    type: "machine",
    style: { width: getNodeWidth(dimension) },
  };

  const updateNodeHandler = () => {
    /**
     * todo: dispatch update node
     */
    dispatch({ type: "edit node", node: node });
    if (lineLayer !== "")
      dispatchLineConfig({
        type: "update machine info",
        machine_info: {
          machine_name: nodeName,
          is_enabled: isEnabled,
          line_layer: lineLayer,
        },
      });
    close();
  };

  const handleChangeIsEnabled = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsEnabled(event.target.checked);
  };

  return (
    <Fragment>
      <DialogTitle>Node editor</DialogTitle>

      <DialogContent>
        <Stack gap={2}>
          {isPlaceholder ? (
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              placeholder="Insert the name of the placeholder node"
              fullWidth
              error={name === ""}
              helperText={name === "" ? "Please insert a name" : ""}
            />
          ) : null}
          <Box
            sx={{
              height: 400,
              border: "1px solid rgba(255,255,255,.3)",
              borderRadius: 1,
              boxShadow: "1px 1px 1px 0px black",
            }}
          >
            <ReactFlowProvider>
              <ReactFlow
                proOptions={{
                  hideAttribution: true,
                }}
                fitView
                nodeTypes={machineNodeTypes}
                nodes={[node]}
                onNodesChange={(_nodes) => applyNodeChanges(_nodes, [node])}
              >
                <Controls showInteractive={false} />
                <Background
                  lineWidth={1}
                  color="#0000000f"
                  variant={BackgroundVariant.Lines}
                />
                <Panel position="top-left">
                  <TextField
                    type="number"
                    size="small"
                    value={dimension}
                    sx={{ width: 100 }}
                    InputProps={{
                      inputProps: {
                        max: 5,
                        min: 1,
                      },
                    }}
                    onChange={(e) => setDimension(+e.target.value)}
                    label="Width"
                  />
                </Panel>
                <Panel position="top-center">
                  <TextField
                    type="number"
                    size="small"
                    value={lineLayer}
                    sx={{ width: 100 }}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onChange={(e) => {
                      if (lineLayer === "") {
                        setLineLayer(0);
                      } else {
                        setLineLayer(+e.target.value);
                      }
                    }}
                    label="Line layer"
                  />
                </Panel>
                <Panel position="top-right">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isEnabled}
                        onChange={handleChangeIsEnabled}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Enabled"
                  />
                </Panel>
              </ReactFlow>
            </ReactFlowProvider>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={() => close()}>
          {translate("actions.cancel")}
        </Button>
        <Button variant="contained" onClick={updateNodeHandler}>
          {translate("actions.update")}
        </Button>
      </DialogActions>
    </Fragment>
  );
};
