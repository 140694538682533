import { useState } from "react";
import { useTranslate } from "@/i18n/config";
import {
  Box,
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { TestCircleLayoutLoader } from "@/features/MachineDetail/components/CircleLayout";
import { useGetImputationStatsData } from "@/features/StopJustification/api/useGetImputationStatsData";
import { useDisclosure } from "@/hooks/useDisclosure";
import { ImputationCategoriesEditModal } from "@/features/StopJustification/components/ImputationCategoriesEditModal";
import { CircularProgressBar } from "@/features/StopJustification/components/CircularProgressBar";
import { parseStatsData } from "@/features/StopJustification/utils/parseStatsData";
import { colors } from "@/styles/colors";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";

export const ImputationStats = () => {
  const translate = useTranslate();

  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  const { data, isLoading, error } = useGetImputationStatsData();
  const [selectedState, setSelectedState] = useState(translate("all"));
  const { open, close, isOpen } = useDisclosure();

  if (isLoading)
    return (
      <Grid container columns={12} alignItems="center" spacing={2}>
        <Grid item xs={2.5} container columns={12} gap={1} alignItems="center">
          <Grid item xs={12}>
            <TestCircleLayoutLoader height={"12vmax"} />
          </Grid>
        </Grid>
        <Grid item xs={1.5} container columns={12} gap={2}>
          <Grid item xs={12}>
            <TestCircleLayoutLoader height={"8vmax"} />
          </Grid>
          <Grid item xs={12}>
            <TestCircleLayoutLoader height={"8vmax"} />
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <SkeletonCard height={400} />
        </Grid>
      </Grid>
    );

  if (error) return <div>{translate("user_feedback.an_error_occurred")}</div>;

  if (!data) return <div>{translate("user_feedback.no_data")}</div>;

  const { categories, countData, percentageData } = parseStatsData(
    data.data,
    selectedState,
    translate("all"),
  );

  const {
    shutdownImputed,
    shutdownOccurrences,
    stopImputed,
    stopOccurrences,
    totalImputed,
    totalOccurrences,
  } = data.count;

  const handleSelectBatch = (
    _: React.MouseEvent<HTMLElement>,
    newBatch: string,
  ) => {
    if (!newBatch) return;
    setSelectedState(newBatch);
  };

  return (
    <>
      {isOpen && (
        <ImputationCategoriesEditModal close={close} isOpen={isOpen} />
      )}
      <Grid container columns={12} alignItems="center">
        <Grid item xs={2.5} container columns={12} gap={1} alignItems="center">
          <Grid item xs={12}>
            <CircularProgressBar
              color={colors.states.production}
              max={100}
              min={0}
              title={translate("stop_imputation.imputed")}
              subTitle={`${totalImputed} / ${totalOccurrences}`}
              value={
                totalOccurrences > 0
                  ? (100 / totalOccurrences) * totalImputed
                  : 0
              }
              dimension="extralarge"
            />
          </Grid>
        </Grid>
        <Grid item xs={1.5} container columns={12} gap={2}>
          <Grid item xs={12}>
            <CircularProgressBar
              color={colors.states.production}
              max={100}
              min={0}
              title={translate("machine.stop")}
              subTitle={`${stopImputed} / ${stopOccurrences}`}
              value={
                stopOccurrences > 0 ? (100 / stopOccurrences) * stopImputed : 0
              }
              dimension="medium"
            />
          </Grid>
          <Grid item xs={12}>
            <CircularProgressBar
              color={colors.states.production}
              max={100}
              min={0}
              title={translate("machine.shutdown")}
              subTitle={`${shutdownImputed} / ${shutdownOccurrences}`}
              value={
                shutdownOccurrences > 0
                  ? (100 / shutdownOccurrences) * shutdownImputed
                  : 0
              }
              dimension="medium"
            />
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Card>
            <Box
              p={1}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ToggleButtonGroup
                value={selectedState}
                exclusive
                onChange={handleSelectBatch}
                aria-label={translate("variable_other")}
                sx={{ backgroundColor: "#1F293F" }}
              >
                {[translate("all"), ...Object.keys(data.data)].map((batch) => (
                  <ToggleButton key={batch} value={batch}>
                    {batch}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              <Button variant="outlined" onClick={open}>
                {translate("stop_imputation.manage_categories")}
              </Button>
            </Box>
            <BarChart.Custom
              title={translate(
                "stop_imputation.imputations_count_and_duration",
              )}
              filename={fileNameFactory({
                name: "imputation_stats",
                machine: machineName,
              })}
              uom="%"
              tooltip={{ enabled: true }}
              showLogarithmicToggle
              categories={categories}
              scrollbarEnabled={countData.length > 6}
              xAxisMax={countData.length > 4 ? 5 : countData.length - 1}
              series={[
                {
                  type: "column",
                  name: translate("count"),
                  data: countData,
                  color: colors.categories.line,
                },
                {
                  type: "column",
                  name: translate("math.percentage"),
                  data: percentageData,
                  color: colors.states.stop,
                },
              ]}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
