import Highcharts from "highcharts/highstock";
import HC_exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import variablepie from "highcharts/modules/variable-pie";
import applyDrilldown from "highcharts/modules/drilldown";
import addSunburstModule from "highcharts/modules/sunburst";
import addHighchartsMore from "highcharts/highcharts-more";
import addHistogramBellCurveModule from "highcharts/modules/histogram-bellcurve";
import addParetoModule from "highcharts/modules/pareto";
import addBoostModule from "highcharts/modules/boost";
import addXRangeModule from "highcharts/modules/xrange";
import addGauge from "highcharts/modules/solid-gauge";
import addHeatmap from "highcharts/modules/heatmap";
import addBullet from "highcharts/modules/bullet";
import addAnnotations from "highcharts/modules/annotations";
import addNoDataToDisplay from "highcharts/modules/no-data-to-display";

import addBorderRadius from "highcharts-border-radius";

import { HighchartsProvider } from "react-jsx-highstock";

HC_exporting(Highcharts);
ExportData(Highcharts);
variablepie(Highcharts);
applyDrilldown(Highcharts);
addSunburstModule(Highcharts);
addHighchartsMore(Highcharts);
addHistogramBellCurveModule(Highcharts);
addParetoModule(Highcharts);
addBoostModule(Highcharts);
addXRangeModule(Highcharts);
addGauge(Highcharts);
addHeatmap(Highcharts);
addBullet(Highcharts);
addBorderRadius(Highcharts);
addAnnotations(Highcharts);
addNoDataToDisplay(Highcharts);

/**
 * There are some options like `marker` that are not overridden if used inside the plotOptions[series] object.
 * Remember to configure them in directly inside the component.
 */
Highcharts.setOptions({
  yAxis: {
    gridLineColor: "#333333",
    tickAmount: 5,
  },
  chart: { style: { fontFamily: "Montserrat" } },
  legend: {
    itemHoverStyle: { color: "lightblue" },
    itemStyle: { color: "white" },
  },
  exporting: {
    csv: {
      itemDelimiter: ",",
    },
    buttons: {
      contextButton: {
        menuItems: [
          "viewFullscreen",
          "separator",
          "downloadPNG",
          "downloadJPEG",
          "downloadPDF",
          "downloadSVG",
          "separator",
          "downloadCSV",
          "downloadXLS",
        ],
      },
    },
    chartOptions: {
      chart: {
        backgroundColor: "#0d1626",
      },
    },
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
    scatter: {
      marker: {
        enabled: true,
      },
    },
  },
});

export function HighchartsCustomProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <HighchartsProvider Highcharts={Highcharts}>{children}</HighchartsProvider>
  );
}
