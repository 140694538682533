import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type RejectCausesResponse = ThingworxError | RejectCausesResult;

const RejectCauseListItem = z.object({
  rejectCauseClient: z.string(),
  rejectCause: z.string(),
});

export type RejectCauseListItem = z.infer<typeof RejectCauseListItem>;

const RejectCausesResult = z.object({
  response: z.literal(true),
  listOfRejectCauses: z.array(RejectCauseListItem),
});

type RejectCausesResult = z.infer<typeof RejectCausesResult>;

type Payload = {
  machineName: string;
};

export const useGetRejectCausesList = () => {
  const { machine } = useMachineContext();

  const { appKey } = useFirebaseContext();

  return useQuery<RejectCauseListItem[]>({
    queryKey: ["getRejectCauses", machine?.machine],
    queryFn: async () => {
      const response = await FetchClient<Payload, RejectCausesResponse>({
        appKey,
        url: "dig.c.rejectCause_thing/Services/getListOfRejectCauses",
        payload: {
          machineName: machine?.machine || "",
        },
      });

      if (!response.response) throw new Error(response.errorString);

      return RejectCausesResult.parse(response).listOfRejectCauses;
    },
    /**
     * a machine never changes its reject causes
     */
    staleTime: Infinity,
    enabled: !!machine?.machine,
  });
};
