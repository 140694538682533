import { useEffect, useState } from "react";
import { Card } from "@/components/Layout/Card";
import {
  DoserDeviationData,
  useGetDoserDeviationData,
} from "../api/useGetDoserDeviationData";
import { AnalyticContainerSkeleton } from "../../LYO/components/AnalyticContainer/AnalyticContainerLayout";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { colors } from "@/styles/colors";
import { LineAndAreaRange } from "@/components/highcharts/linechart/LineAndAreaRange";
import { useTranslate } from "@/i18n/config";

export const DoserStandardDeviation = () => {
  const translate = useTranslate();
  const defaultDataset = {
    netData: {
      meanData: [],
      sigma1Data: [],
      sigma2Data: [],
      sigma3Data: [],
      scatterData: [],
    },
    name: "",
  };
  const { data, isLoading, error } = useGetDoserDeviationData();
  const [doser, setDoser] = useState("");
  const [doserDataset, setDoserDataset] =
    useState<DoserDeviationData>(defaultDataset);

  useEffect(() => {
    data && data.sort((a, b) => a.name.localeCompare(b.name));
    setDoser(data ? (data.length > 0 ? data[0].name || "" : "") : "");
    setDoserDataset(
      data
        ? data.length > 0
          ? data[0] || defaultDataset
          : defaultDataset
        : defaultDataset,
    );
  }, [data, defaultDataset]);

  const handleDoserClick = (_: unknown, selectedDoser: string) => {
    setDoser(selectedDoser);
    const selectedDoserDataset = data?.find(
      (dataset) => dataset.name === selectedDoser,
    );
    setDoserDataset(selectedDoserDataset || defaultDataset);
  };

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <List
        subheader={
          <ListSubheader>{translate("filler.doser_other")}:</ListSubheader>
        }
        sx={{
          mr: 1,
          width: "21vw",
          overflowY: "auto",
          backgroundColor: colors.palette.darkBlue,
          color: "#fff",
          backgroundImage: "none",
          padding: "1rem",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: ".25rem",
        }}
      >
        {data.map((doserItem) => (
          <ListItemButton
            key={doserItem.name}
            selected={doser === doserItem.name}
            onClick={() => handleDoserClick(null, doserItem.name)}
          >
            <ListItemText primary={doserItem.name} />
          </ListItemButton>
        ))}
      </List>
      <Box
        sx={{
          width: "79vw",
        }}
      >
        <Card>
          <LineAndAreaRange
            key={doser}
            title={translate("filler.doser_mean_sigma_net_weights")}
            customCredits="Data calculated every 120 weighings"
            yAxis={[
              {
                uom: "g",
                options: {
                  plotLines: [
                    {
                      value: doserDataset.netData.benchmark?.minErr,
                      color: "red",
                      dashStyle: "LongDash",
                      label: { text: "T2" },
                    },
                    {
                      value: doserDataset.netData.benchmark?.maxErr,
                      color: "red",
                      dashStyle: "LongDash",
                      label: { text: "T2" },
                    },
                    {
                      value: doserDataset.netData.benchmark?.minWarn,
                      color: "orange",
                      dashStyle: "LongDash",
                      label: { text: "T1" },
                    },
                    {
                      value: doserDataset.netData.benchmark?.maxWarn,
                      color: "orange",
                      dashStyle: "LongDash",
                      label: { text: "T1" },
                    },
                  ],
                },
                series: [
                  {
                    type: "line",
                    data: doserDataset.netData.meanData,
                    color: "#18e4f2",
                    name: "Mean",
                  },
                  {
                    type: "arearange",
                    data: doserDataset.netData.sigma1Data,
                    color: "#18e4f2",
                    opacity: 0.4,
                    name: "Sigma 1",
                  },
                  {
                    type: "arearange",
                    data: doserDataset.netData.sigma2Data,
                    color: "#18e4f2",
                    opacity: 0.3,
                    name: "Sigma 2",
                  },
                  {
                    type: "arearange",
                    data: doserDataset.netData.sigma3Data,
                    color: "#18e4f2",
                    opacity: 0.2,
                    name: "Sigma 3",
                  },
                  {
                    type: "scatter",
                    data: doserDataset.netData.scatterData,
                    color: "orange",
                    name: translate("reject_other"),
                    marker: {
                      enabled: true,
                      symbol: "cirle",
                      radius: 2,
                    },
                  },
                ],
                // options: { crosshair: true },
              },
            ]}
            // xAxisOptions={{ crosshair: true }}
            legend={{
              enabled: true,
              options: { itemHoverStyle: { color: "lightblue" } },
            }}
            xAxisType="datetime"
            tooltip={{
              enabled: true,
              options: {
                formatter() {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const info = this as unknown as any;
                  if (info.points) {
                    const date = new Date(info.points[0].x!).toUTCString();
                    const mean = info.points[0].y!.toFixed(2);
                    const low1 = info.points[1]
                      ? info.points[1].point.low.toFixed(2)
                      : "";
                    const high1 = info.points[1]
                      ? info.points[1].point.high.toFixed(2)
                      : "";
                    const low2 = info.points[2]
                      ? info.points[2].point.low.toFixed(2)
                      : "";
                    const high2 = info.points[2]
                      ? info.points[2].point.high.toFixed(2)
                      : "";
                    const low3 = info.points[3]
                      ? info.points[3].point.low.toFixed(2)
                      : "";
                    const high3 = info.points[3]
                      ? info.points[3].point.high.toFixed(2)
                      : "";
                    const color = info.points[0].color;
                    return `
                      ${date}<br>
                      <span style='color: ${color}'>● </span>${translate(
                        "math.mean",
                      )}: <b>${mean}</b><br>
                      <span style='color: ${color}'>● </span>Sigma 1: <b>${low1}</b> - <b>${high1}</b><br>
                      <span style='color: ${color}'>● </span>Sigma 2: <b>${low2}</b> - <b>${high2}</b><br>
                      <span style='color: ${color}'>● </span>Sigma 3: <b>${low3}</b> - <b>${high3}</b>
                    `;
                  } else {
                    const date = new Date(info.x).toUTCString();
                    const modality = info.point.modality;
                    return `
                      ${date}<br>
                      <span style='color: ${info.color}'>● </span>${translate(
                        "reject_other",
                      )}<br>
                      ${translate("weight")}: <b>${info.y} g</b><br>
                      ${translate("filler.modality")}: <b>${modality}</b>
                    `;
                  }
                },
              },
            }}
          />
        </Card>
      </Box>
    </Box>
  );
};
