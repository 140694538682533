import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type Res = ThingworxError | Result;

const Result = z.object({
  response: z.literal(true),
  machines: z.array(z.string()),
});

type Result = z.infer<typeof Result>;

type Payload = {
  account: string;
};

export const useGetMachines = () => {
  const { user, appKey } = useFirebaseContext();
  const email = user?.email as string;

  return useQuery<string[]>({
    queryKey: ["user-machines"],
    queryFn: async () => {
      const response = await FetchClient<Payload, Res>({
        url: "dig.c.usersAndMachinesManagement_thing/services/getMachines",
        appKey,
        payload: {
          account: email,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return Result.parse(response).machines;
    },
    staleTime: Infinity,
  });
};
