import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { useCustomTimeRange } from "../store/useCustomTimeRange";
import { z } from "zod";

type RejectSunburstResponse = ThingworxError | RejectSunburstResult;

const RejectSunburstData = z.object({
  sunburstData: z.record(z.record(z.number())),
  timeSelectionName: z.string(),
});

export type RejectSunburstData = z.infer<typeof RejectSunburstData>;

const RejectSunburstResult = z.object({
  response: z.literal(true),
  data: RejectSunburstData,
});

type RejectSunburstResult = z.infer<typeof RejectSunburstResult>;

type Payload = {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  timeSelectionName: string;
};

export const useGetRejectSunburstData = () => {
  const { machine } = useMachineContext();

  const { dateStart, dateEnd, timeSelectionName } = useCustomTimeRange();

  const { appKey } = useFirebaseContext();

  return useQuery<RejectSunburstData>({
    queryKey: ["sunburst-rejects", machine?.machine, dateStart, dateEnd],
    queryFn: async () => {
      const response = await FetchClient<Payload, RejectSunburstResponse>({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
          timeSelectionName,
        },
        url: "dig.c.fillerAdvancedAnalytics_thing/Services/getRejectSunburstData",
      });

      if (!response.response) throw new Error(response.errorString);

      return RejectSunburstResult.parse(response).data;
    },
  });
};
