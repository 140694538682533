import { create } from "zustand";
import { IntellectaVersion } from "../types";

export const intellectaVersionStore = create<{
  version: IntellectaVersion | null;
  setVersion: (version: IntellectaVersion | null) => void;
  availableVersions: IntellectaVersion[];
  setAvailableVersions: (versions: IntellectaVersion[]) => void;
}>((set) => ({
  version: null,
  setVersion: (version) => set({ version }),
  availableVersions: [],
  setAvailableVersions: (availableVersions) =>
    set({
      availableVersions,
      // version: availableVersions.length > 0 ? availableVersions[0] : null,
    }),
}));
