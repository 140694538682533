import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { StyledEngineProvider, CssBaseline } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as Sentry from "@sentry/react";
import "./i18n/config";
import "leaflet/dist/leaflet.css"; // <- Leaflet styles
import { App } from "./App";
import { ThemeContextProvider } from "../src/context/theme-context";
import { FirebaseAuthProvider } from "../src/context/firebase-context";
import { ErrorBoundary } from "./ErrorBoundaries/ErrorBoundary";
import { SERVER_ENUMERATOR, SERVER_URL } from "./config";
import { DatepickerLanguageProvider } from "./providers/datepicker-language-provider";

const environment =
  SERVER_ENUMERATOR === "0"
    ? "development"
    : SERVER_ENUMERATOR === "1"
    ? "staging"
    : "production";

Sentry.init({
  dsn: "https://de66ad54c9634a6ab9d3eb236b037a40@o4505305407422464.ingest.sentry.io/4505305424134144",
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [
        "localhost",
        "https://sentinel-demo.web.app/",
        "https://sentinel-development.web.app/",
      ],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [window.location.origin, SERVER_URL],
      // NOTE: This will disable built-in masking. Only use this if your site has no sensitive
      // data, or if you've already set up other options for masking or blocking relevant data,
      // such as 'ignore', 'block', 'mask' and 'maskFn'.
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: environment,
  // Performance Monitoring
  enabled: process.env.NODE_ENV === "production",
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.3 : 1.0,

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
      onError: (error) => {
        console.error(error);
        Sentry.captureException(error);
        toast.error("An error occurred");
      },
    },
  },
});

createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeContextProvider>
        <CssBaseline />
        <ErrorBoundary>
          <DatepickerLanguageProvider>
            <Router>
              <FirebaseAuthProvider>
                <QueryClientProvider client={queryClient}>
                  {process.env.NODE_ENV === "development" && (
                    <ReactQueryDevtools />
                  )}
                  <App />
                </QueryClientProvider>
              </FirebaseAuthProvider>
            </Router>
          </DatepickerLanguageProvider>
        </ErrorBoundary>
      </ThemeContextProvider>
    </StyledEngineProvider>
    <Toaster position="bottom-right" />
  </StrictMode>,
);
