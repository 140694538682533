import { DateTime, Duration } from "luxon";

export class TimeHelpers {
  static parseTimestampToString({
    timestamp,
    fmt = "DD HH:mm:ss",
  }: {
    timestamp: number;
    fmt?: string;
  }) {
    return DateTime.fromMillis(timestamp).toFormat(fmt);
  }

  static parseDurationToString({
    duration,
    fmt = "hh:mm:ss",
  }: {
    duration: number;
    fmt?: string;
  }) {
    return Duration.fromMillis(duration).toFormat(fmt);
  }
}
