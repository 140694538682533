import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { z } from "zod";
import { ConditionConfig } from "../../types";

export type GetAlertListResponse = ThingworxError | GetAlertListResult;

interface Payload {
  account: string;
  platformLvl: number;
}

const ticketStatus = z.union([z.literal(0), z.literal(1), z.literal(2)]);

const AlertSchema = z.object({
  account: z.string(),
  data: z.array(ConditionConfig),
  id: z.number(),
  machine: z.string(),
  platformLvl: z.number(),
  priority: z.number(),
  ruleDescription: z.string(),
  ruleName: z.string(),
  stateOfTicket: ticketStatus,
  timestamp: z.number(),
});

export type AlertSchema = z.infer<typeof AlertSchema>;

export const getAlertListResult = z.object({
  response: z.literal(true),
  data: z.array(AlertSchema),
});

export type GetAlertListResult = z.infer<typeof getAlertListResult>;

export const getAlerts = ({
  appKey,
  account,
  platformLvl,
}: {
  appKey: string;
  account: string;
  platformLvl: number;
}) => {
  return FetchClient<Payload, GetAlertListResponse>({
    appKey,
    payload: {
      account,
      platformLvl,
    },
    url: "dig.c.event_thing/Services/getListOfAlerts",
  });
};
