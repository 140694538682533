import { ShowEmptyData } from "@/components/ShowEmptyData";
import { BasicTable } from "@/components/BasicTable";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { TableParser } from "@/utils/TableParser";
import {
  useGetMaterialStream,
  MaterialSummaryDetail,
} from "../api/useGetMaterialStream";
import { Card } from "@/components/Layout/Card";

export const MaterialsList = () => {
  const { data: materials, isLoading } = useGetMaterialStream();

  if (isLoading) return <AnalyticContainerSkeleton />;

  if (!materials) return null;

  if (materials.length === 0) {
    return <ShowEmptyData title="Materials" />;
  }

  const { columns, rows } = TableParser.parseData<MaterialSummaryDetail>({
    data: materials,
    columnsMap: {
      ErrorDescription: "Error Description",
      FamilyDescription: "Family Description",
      Timestamp: "Timestamp",
      ZoneDescription: "Zone Description",
    },
    columnsOrder: [
      "FamilyDescription",
      "ZoneDescription",
      "ErrorDescription",
      "Timestamp",
    ],
  });

  return (
    <Card>
      <BasicTable columns={columns} rows={rows} fileName="materials_data" />
    </Card>
  );
};
