import { Box, Typography, styled } from "@mui/material";
import { Line } from "@/features/OverallLineEfficiency/api/useGetUserProductionLines";
import { Card } from "@/components/Layout/Card";

const states: {
  name: string;
  color: string;
}[] = [
  {
    name: "Producing",
    color: "#00FF00",
  },
  {
    name: "Not Producing",
    color: "#ff7a0d",
  },
  {
    name: "Scheduled Downtime",
    color: "#8a8a8a",
  },
  {
    name: "Idle",
    color: "#eeeeee",
  },
];

const convertHexToHoverRGBA = (hexString: string) => {
  // Step 1: Strip "#" character
  const hex = hexString.substring(1);

  // Step 2: Split into RGB components
  const r = hex.substring(0, 2);
  const g = hex.substring(2, 4);
  const b = hex.substring(4, 6);

  // Step 3: Convert to decimal
  const red = parseInt(r, 16);
  const green = parseInt(g, 16);
  const blue = parseInt(b, 16);

  // Step 5: Create rgba string
  const rgbaString = `rgba(${red + 10}, ${green + 10}, ${blue + 10}, 1)`;

  return rgbaString;
};

const LineItemCard = styled(Card)(() => ({
  height: "fit-content",
  flex: 1,
  maxWidth: "100%",
  minWidth: "300px",
  padding: 0,
  borderRadius: "4px",
  transition: "all 0.2s ease-in-out",
  display: "flex",
  flexDirection: "column",
  minHeight: "fit-content",
  cursor: "pointer",
}));

export const LineItem = ({
  active,
  line,
  onClick,
  notConfiguredComponent,
}: {
  active: boolean;
  line: Line;
  onClick: (line: Line) => void;
  // add a prop that takes a component that accepts a line as a prop
  // and renders it
  notConfiguredComponent: React.ReactNode;
}) => {
  const stateColor =
    states.find((s) => s.name === line.state)?.color || "#fffff10";

  const hoverColor = convertHexToHoverRGBA(stateColor);

  if (!line.is_configured) {
    return (
      <NotConfiguredLine
        onClick={() => onClick(line)}
        line={line}
        formButton={notConfiguredComponent}
        active={active}
      />
    );
  }

  const color =
    line.state === "Producing" || line.state === "Idle" ? "black" : "white";

  return (
    <LineItemCard
      sx={{
        borderBottom: `3px solid ${active ? "#fff" : stateColor}`,
        backgroundColor: stateColor,
        color: color,
        "&:hover": {
          backgroundColor: hoverColor,
        },
      }}
      onClick={() => onClick(line)}
      role="button"
    >
      <Box
        sx={{
          padding: ".5rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography fontWeight="bold" fontSize={".75rem"}>
          {line.factory_name}
        </Typography>
        <Typography fontWeight="bold" fontSize={".75rem"}>
          {line.line_name}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: ".5rem",
          transition: "all 0.2s ease-in-out",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          height: "76px",
          alignItems: "center",
        }}
      >
        <Typography fontWeight="bold">
          OEE {line.efficiency.toFixed(2)}
          <span dangerouslySetInnerHTML={{ __html: "&percnt;" }} />
        </Typography>
      </Box>
    </LineItemCard>
  );
};

const NotConfiguredLine = ({
  line,
  formButton,
  onClick,
  active = false,
}: {
  line: Line;
  formButton: React.ReactNode;
  onClick: () => void;
  active: boolean;
}) => {
  return (
    <>
      <LineItemCard
        sx={{
          backgroundColor: "#ffffff15",
          cursor: "pointer",
          borderBottom: `3px solid ${active ? "#fff" : "#ffffff15"}`,
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            padding: ".5rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight="bold" fontSize={".75rem"}>
            {line.factory_name}
          </Typography>
          <Typography fontWeight="bold" fontSize={".75rem"}>
            {line.line_name}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: ".5rem",
            transition: "all 0.2s ease-in-out",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>Missing configuration</Typography>
          {formButton}
        </Box>
      </LineItemCard>
    </>
  );
};
