import { useQuery } from "@tanstack/react-query";
import { useMachineContext } from "@/context/machine-context";
import { useFirebaseContext } from "@/context/firebase-context";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { StateCategory } from "@/features/MachineDetail/api/CyclicLive/useGetSentinelLiveData";
import { z } from "zod";

type BatchNavigatorResponse = ThingworxError | BatchNavigatorResult;
const ProductionHour = z.object({
  valueBM: z.number(),
  lossName: z.string(),
  valueWaste: z.number(),
  valueWasteLine: z.number(),
  value: z.number(),
  valueWasteInteraction: z.number(),
});

export type ProductionHour = z.infer<typeof ProductionHour>;

const StateInfo = z.object({
  color: z.string(),
  state: z.string(),
  category: StateCategory,
});

export type StateInfo = z.infer<typeof StateInfo>;

const BatchNavigatorResult = z.object({
  currentRecipe: z.string(),
  forceStopBatch: z.boolean(),
  currentRecipeClient: z.string(),
  batchCountGood: z.number(),
  batchEnd: z.boolean(),
  wasteBatchCount: z.number(),
  targetBatchProductHour: z.number(),
  batchTargetCount: z.number(),
  timeEndBatchTarget: z.string(),
  batchTargetTime: z.number(),
  batchCount: z.number(),
  batchCountToComplete: z.number(),
  machineName: z.string(),
  speed: z.number(),
  batchTime: z.number(),
  batchTimeToComplete: z.number(),
  timeStart: z.string(),
  state: z.number(),
  isMachineParametric: z.boolean(),
  batchExpectedCount: z.number(),
  timeStartNew: z.string(),
  test: z.number(),
  timeEndBatch: z.string(),
  batchCurrentDelay: z.number(),
  manualBatchActive: z.boolean(),
  currentBatchName: z.string(),
  productionHour: z.array(ProductionHour),
  stateColor: z.string(),
  response: z.literal(true),
  stateInfo: StateInfo,
  status: z.string(),
});

export type BatchNavigatorResult = z.infer<typeof BatchNavigatorResult>;

export const useGetBatchNavigatorData = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<BatchNavigatorResult>({
    queryKey: ["batch-navigator", machine!.machine],
    queryFn: async () => {
      const response = await FetchClient<
        {
          machineName: string;
          platformTimezone: string;
          timezoneOffset: number;
        },
        BatchNavigatorResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine || "",
          platformTimezone: "user",
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        url: "dig.c.batch_thing/Services/getData_v3",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }
      return response;
    },
    refetchInterval: 1000 * 10,
  });
};
