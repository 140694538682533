import { SettingsPassword } from "../components/SettingsPassword";
import { SettingSectionLayout } from "../components/SettingSectionLayout";
import { Language } from "../components/Language";
import { Stack, Divider } from "@mui/material";
import { useTranslate } from "@/i18n/config";

export const Settings = () => {
  const translate = useTranslate();
  return (
    <Stack divider={<Divider />} gap={2}>
      <SettingSectionLayout
        title={translate("password")}
        description={translate("update_password")}
        render={<SettingsPassword />}
      />
      <SettingSectionLayout
        title={translate("language")}
        description={translate("choose_language")}
        render={<Language />}
      />
    </Stack>
  );
};
