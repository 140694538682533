import { Chip, Typography } from "@mui/material";
import { Card } from "./Layout/Card";

export const ItemCard = <T,>({
  title,
  subtitle,
  secondarySubtitle,
  optionalLabel,
  item,
  onClick,
}: {
  title: string;
  subtitle: string;
  secondarySubtitle?: string;
  optionalLabel?: string;
  item: T;
  onClick: (item: T) => void;
}) => {
  return (
    <Card
      sx={{
        cursor: "pointer",
      }}
      variants={{
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
      }}
      whileHover={{
        scale: 1.02,
        transition: { duration: 0.2 },
      }}
      onClick={() => onClick(item)}
    >
      <Chip label={title} />

      <div style={{ marginTop: 20 }} />
      <Typography fontWeight="">{subtitle}</Typography>
      {secondarySubtitle && (
        <Typography fontWeight="">{secondarySubtitle}</Typography>
      )}
      {optionalLabel ? (
        <Typography textAlign="right" fontWeight="bold">
          {optionalLabel}
        </Typography>
      ) : null}
    </Card>
  );
};
