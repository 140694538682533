import Box from "@mui/material/Box";
import { Sidebar } from "./layout/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { AppShell } from "@/components/Layout/AppShell";
import { InformationBanner } from "@/components/Layout/InformationBanner";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";

export const MachineView = () => {
  const { showBanner } = useCheckIfMessageBanner();

  return (
    <Box>
      {showBanner && <InformationBanner />}
      <Sidebar />
      <AppShell
        component="main"
        sx={{
          flexGrow: 1,
          px: 4,
          py: 2,
          mt: showBanner ? "32px" : 0,
          float: "right",
          zIndex: -1,
          position: "relative",
        }}
      >
        <Outlet />
      </AppShell>
    </Box>
  );
};
