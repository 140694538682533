import { useDisclosure } from "@/hooks/useDisclosure";
import { Edit } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { AutomaticConfigForm } from "./AutomaticConfigForm";
import { Line } from "../../api/useGetUserProductionLines";
import styles from "./EditLineConfigurationButton.module.css";
import { Modal } from "@/components/Modal";
import { useTranslate } from "@/i18n/config";
import { Fragment } from "react";

export const EditAutomaticLineConfigurationButton = ({
  line,
  configureFrom = "linePage",
}: {
  line: Line;
  configureFrom?: "linePage" | "plantView";
}) => {
  const translate = useTranslate();
  const { open, close, isOpen } = useDisclosure();

  return (
    <Fragment>
      {configureFrom === "linePage" ? (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          className={styles.pulse}
          onClick={(e) => {
            e.stopPropagation();
            open();
          }}
        >
          <Edit />
        </IconButton>
      ) : (
        <Button variant="outlined" onClick={open}>
          Configure calculations
        </Button>
      )}
      <Modal
        open={isOpen}
        onClick={(e) => e.stopPropagation()}
        titleContent={
          <Typography>
            {translate("overall_line_efficiency.configure_line")}
          </Typography>
        }
        onClose={close}
        bodyContent={
          <AutomaticConfigForm line={line} onSubmit={() => close()} />
        }
        fullWidth
      />
    </Fragment>
  );
};
