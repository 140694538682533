import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type PhysicalListResponse = ThingworxError | PhysicalListResult;

const PhysicalListResult = z.object({
  Number: z.number(),
  response: z.literal(true),
  Result: z.array(z.string()),
});

type PhysicalListResult = z.infer<typeof PhysicalListResult>;

type Payload = {
  machineName: string;
};

/**
 * @returns a list of all the physical assets available for the given machine
 */
export const useGetDataListExternal = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<string[]>({
    queryKey: ["machine-physical-list", machine!.machine],
    queryFn: async () => {
      const response = await FetchClient<Payload, PhysicalListResponse>({
        appKey,
        url: "dig.c.physicalMeasure_thing/Services/getDataListExternal",
        payload: {
          machineName: machine?.machine || "",
        },
      });

      if (!response.response) throw new Error(response.errorString);

      return PhysicalListResult.parse(response).Result;
    },
    staleTime: Infinity,
  });
};
