import { Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { BasicTable } from "@/components/BasicTable";
import { TableParser } from "@/utils/TableParser";
import { AlarmData, TransformedAlarmData } from "../api/useGetAlarmsColumnData";
import { DataTransformer } from "@/utils/DataTransformer";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { Card } from "@/components/Layout/Card";

export const AlarmsSummaryTable = ({ data }: { data: AlarmData[] }) => {
  const translate = useTranslate();

  if (!data.length)
    return <ShowEmptyData title={translate("alarms.alarms_summary")} />;

  const { rows, columns } = TableParser.parseData<TransformedAlarmData>({
    data: data.map((item) =>
      DataTransformer.dataTransformer<AlarmData, TransformedAlarmData>({
        input: {
          ...item,
          MTTR: item.MTTR * 1000,
          MTBF: item.MTBF * 1000,
          Duration: item.Duration * 1000,
          CountPercentage: Number(item.CountPercentage.toFixed(2)),
          DurationPercentage: Number(item.DurationPercentage.toFixed(2)),
        },
        transform: {
          MTTR: "MTTRduration",
          MTBF: "MTBFduration",
          DurationPercentage: "timeAmountPercentage",
        },
      }),
    ),
    durationFormat: "hh:mm:ss.SSS",
    columnsMap: {
      Error: translate("code"),
      ErrorDescription: translate("description"),
      Count: translate("occurrences"),
      CountPercentage: `${translate("occurrences")} %`,
      MTBFduration: translate("mtbf"),
      MTTRduration: translate("mttr"),
      Duration: translate("duration"),
      timeAmountPercentage: `${translate("duration")} %`,
      CumulativeCount: translate("cumulative_occurrences"),
      CumulativeDuration: translate("cumulative_duration"),
      Family: translate("family"),
    },
    columnsOrder: [
      "Error",
      "ErrorDescription",
      "Count",
      "CountPercentage",
      "MTBFduration",
      "MTTRduration",
      "Duration",
      "timeAmountPercentage",
    ],
    omitColumns: ["CumulativeCount", "CumulativeDuration", "Family"],
  });
  return (
    <Card>
      <Typography variant="h5">{translate("alarms.alarms_summary")}</Typography>
      <BasicTable
        columns={columns}
        rows={rows}
        fileName="alarms_summary_data"
      />
    </Card>
  );
};
