import { useRef } from "react";
import { z } from "zod";
import { Chart as HighchartsChartType } from "highcharts";
import {
  ChartOptions,
  PlotXrangeOptions,
  TooltipFormatterCallbackFunction,
  XrangePointOptionsObject,
  XAxisOptions,
  YAxisOptions,
} from "highcharts/highstock";
import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Title,
  XRangeSeries,
} from "react-jsx-highcharts";
import { HighchartsCustomProvider } from "../highcharts";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Box, Button } from "@mui/material";
import { ONE_SECOND } from "@/utils/durationsInMilliseconds";

interface XRangeProps {
  title: string;
  data: XrangePointOptionsObject[];
  categories?: string[];
  borderRadius?: number;
  tooltipFormatter?: TooltipFormatterCallbackFunction;
  seriesOptions?: PlotXrangeOptions;
  xAxisOptions?: XAxisOptions;
  yAxisOptions?: YAxisOptions;
  chartOptions?: ChartOptions;
  labelsWidth?: number;
  customHeight?: number;
  allowExporting?: boolean;
  tooltipOutside?: boolean;
  enableStepZoomOut?: boolean;
}

export function XRangeChart(props: XRangeProps) {
  const {
    title,
    data,
    categories,
    borderRadius = 3,
    tooltipFormatter,
    seriesOptions,
    xAxisOptions,
    yAxisOptions,
    chartOptions,
    labelsWidth,
    customHeight,
    allowExporting,
    tooltipOutside = true,
    enableStepZoomOut = false,
  } = props;

  const zoomHistory = useRef<{ min: number; max: number }[]>([]);
  const chartRef = useRef<HighchartsChartType | null>(null);

  // Default tooltip formatter if none is provided
  const defaultTooltipFormatter: TooltipFormatterCallbackFunction =
    function () {
      const pointData = z
        .object({
          x: z.number(),
          x2: z.number(),
          color: z.string(),
          yCategory: z.string(),
        })
        .safeParse(this.point);

      if (!pointData.success) return "";

      const { x, x2, color, yCategory } = pointData.data;

      const dateStart = TimeHelpers.parseTimestampToString({
        timestamp: x,
      });
      const dateEnd = TimeHelpers.parseTimestampToString({
        timestamp: x2,
      });
      return `Start: ${dateStart}<br>
    End: ${dateEnd}<br>
    <span style="color:${color}">●</span> <b>${yCategory}</b>`;
    };

  // Use provided tooltip formatter or default
  const tooltipFormatterFunction = tooltipFormatter || defaultTooltipFormatter;

  // Handle Step Zoom Out
  function handleStepZoomOut() {
    const chart = chartRef.current;
    if (chart) {
      if (zoomHistory.current.length > 1) {
        // Remove the last zoom level
        zoomHistory.current.pop();
        const lastZoom = zoomHistory.current[zoomHistory.current.length - 1];
        chart.xAxis[0].setExtremes(lastZoom.min, lastZoom.max);
      } else {
        // Reset zoom if no previous zoom levels
        chart.xAxis[0].setExtremes(undefined, undefined);
        zoomHistory.current = [];
      }
    }
  }

  return (
    <HighchartsCustomProvider>
      {/* Conditionally render the Step Zoom Out button */}
      {enableStepZoomOut && zoomHistory.current.length > 0 && (
        <Box display="flex" justifyContent="end">
          <Button onClick={handleStepZoomOut} variant="contained">
            Step Zoom Out
          </Button>
        </Box>
      )}

      <HighchartsChart
        accessibility={{ enabled: false }}
        plotOptions={{}}
        time={{ useUTC: false }}
        exporting={{ enabled: allowExporting }}
        callback={(chart) => {
          chartRef.current = chart;
        }}
      >
        <Chart
          backgroundColor="transparent"
          type="xrange"
          height={customHeight}
          zooming={{
            type: "x",
            resetButton: {
              position: {
                align: "right",
                verticalAlign: "top",
                x: -30,
                y: 0,
              },
              relativeTo: "plotBox",
            },
          }}
          events={{
            selection: function (event) {
              if (event.resetSelection) {
                zoomHistory.current = [];
              }
              const xAxis = event.xAxis?.[0];
              if (xAxis) {
                const newZoomRange = { min: xAxis.min, max: xAxis.max };
                zoomHistory.current.push(newZoomRange);
              }
              return true; // Allow default zoom behavior
            },
          }}
          {...chartOptions}
        />

        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>
        <Tooltip
          useHTML
          outside={tooltipOutside}
          formatter={tooltipFormatterFunction}
        />

        <XAxis
          labels={{ style: { color: "white" } }}
          type="datetime"
          title={{ text: null }}
          minRange={ONE_SECOND}
          {...xAxisOptions}
        />

        <YAxis
          gridLineColor="#333333"
          labels={{
            style: {
              color: "white",
              width: labelsWidth,
            },
          }}
          title={{ text: null }}
          showLastLabel
          categories={categories}
          max={categories ? categories.length - 1 : null}
          reversed
          {...yAxisOptions}
        >
          <XRangeSeries
            borderColor="transparent"
            data={data}
            turboThreshold={20000}
            borderRadius={borderRadius}
            {...seriesOptions}
          />
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
}
