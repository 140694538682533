import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { ItemCard } from "@/components/ItemCard";
import { Card } from "@/components/Layout/Card";
import { NavButton } from "@/components/NavButton";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Button, Grid, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useGetHistoricConfigurableData } from "../api/HistoricData/useGetHistoricConfigurableData";
import { useGetHistoricTimeLapses } from "../api/HistoricData/useGetHistoricTimeLapses";
import { HistoricViewStatesContainer } from "../components/Historic/HistoricViewStatesContainer";
import { KpiInfoLegend } from "../components/KpiInfoLegend";
import { ConfigurableLoaderLayout } from "../components/Layout/LoaderLayout";
import { TopRow } from "../components/TopRow";
import { convertKpiToArray, customBarChartColors } from "../utils/utilities";
import { useMachineContext } from "@/context/machine-context";
import { fileNameFactory } from "@/utils/fileNameFactory";

export const ConfigurableOeeViewHistoric = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<{
    dateStart: number;
    id: number;
    timeLapse: string;
  } | null>(null);
  const translate = useTranslate();
  const { data: historicTimelapses } = useGetHistoricTimeLapses();

  useEffect(() => {
    setSelectedPeriod(null);
  }, [historicTimelapses]);
  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection
          exclude={["Custom", "Month", "ThreeMonths", "SixMonths", "Year"]}
          forceCalendar
        />
        {selectedPeriod ? (
          <Button onClick={() => setSelectedPeriod(null)}>
            {translate("go_back_to_select")}
          </Button>
        ) : null}
        <NavButton to="/configurable-oee" text={translate("view_live")} />
      </MachineViewNavBar>
      {selectedPeriod ? (
        <HistoricDataContent id={selectedPeriod.id} />
      ) : (
        <Stack spacing={2}>
          <Typography variant="h6" fontWeight="bold">
            {translate("please_select_a_period")}
          </Typography>
          <AnimatePresence presenceAffectsLayout mode="wait">
            <motion.div
              key={JSON.stringify(historicTimelapses?.availableTS)}
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                },
              }}
              initial="hidden"
              animate="show"
            >
              <Grid container spacing={2}>
                {historicTimelapses?.availableTS.map((item) => (
                  <Grid item xs={6} md={3} key={item.id}>
                    <ItemCard
                      item={item}
                      onClick={(i) =>
                        setSelectedPeriod({
                          dateStart: i.dateStart,
                          timeLapse: i.timelapse,
                          id: i.id,
                        })
                      }
                      subtitle={TimeHelpers.parseTimestampToString({
                        timestamp: item.dateStart,
                        fmt: "y LLL dd HH:mm:ss ",
                      })}
                      title={TimeHelpers.parseTimestampToString({
                        timestamp: item.dateStart,
                        fmt: "y LLL dd ",
                      })}
                      optionalLabel={item.timelapse}
                    />
                  </Grid>
                ))}
              </Grid>
            </motion.div>
          </AnimatePresence>
        </Stack>
      )}
    </>
  );
};

const HistoricDataContent = ({ id }: { id: number }) => {
  const { data, isLoading } = useGetHistoricConfigurableData({ id });
  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  const translate = useTranslate();
  return (
    <>
      {isLoading ? <ConfigurableLoaderLayout /> : null}
      {data ? (
        <Stack gap={2}>
          <TopRow
            standardAvailability={data.standardAvailability}
            standardOee={data.standardOee}
            customAvailability={data.customAvailability}
            customOee={data.customOee}
            pieData={data.pieData}
            standardPerformance={data.standardPerformance}
            customPerformance={data.customPerformance}
          />
          <HistoricViewStatesContainer historicStatesList={data.statesInfo} />
          <Grid2 container columns={12} spacing={2} alignItems="stretch">
            <Grid2 xs={12} md={9.8}>
              <Card>
                <BarChart.Custom
                  title=""
                  uom=""
                  tooltip={{
                    enabled: true,
                  }}
                  filename={fileNameFactory({
                    machine: machineName,
                    name: "configurable_oee",
                  })}
                  legend={{ enabled: true }}
                  categories={[
                    translate("kpi.oee"),
                    translate("kpi.availability"),
                    translate("kpi.performance"),
                    translate("machine.inactive_time"),
                    translate("machine.downtime"),
                  ]}
                  series={[
                    {
                      name: translate("configurable_oee.standard"),
                      type: "column",
                      color: "#bbbbbb",
                      data: convertKpiToArray(data.comparisonKpi.standardKpi),
                    },
                    {
                      name: translate("configurable_oee.custom"),
                      type: "column",
                      data: convertKpiToArray(data.comparisonKpi.customKpi).map(
                        (value, index) => {
                          return {
                            name: value[0],
                            y:
                              typeof value[1] === "string"
                                ? parseFloat(value[1])
                                : value[1],
                            color: customBarChartColors[index],
                          };
                        },
                      ),
                      pointPadding: 0.1,
                      pointPlacement: -0.1,
                    },
                  ]}
                />
              </Card>
            </Grid2>
            <Grid2 xs={12} md={2.2}>
              <KpiInfoLegend comparisonKpi={data.comparisonKpi} />
            </Grid2>
          </Grid2>
        </Stack>
      ) : null}
    </>
  );
};
