import { Card } from "@/components/Layout/Card";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { useGetSpeedSelector } from "../../api/speed/useGetSpeedSelector";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";

export const SpeedSelector = () => {
  const { data, error, isLoading } = useGetSpeedSelector();
  const { machine } = useMachineContext();
  const machineName = machine?.machine;
  const translate = useTranslate();

  if (isLoading) {
    return <SkeletonCard height={300} />;
  }

  if (!data) {
    return null;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  return (
    <>
      <Card>
        <LineChart.Custom
          filename={fileNameFactory({
            name: "speed",
            machine: machineName,
          })}
          title={translate("speed")}
          legend={{ enabled: true }}
          xAxisType="datetime"
          xAxisOptions={{
            scrollbar: { enabled: false },
          }}
          yAxis={data?.data.map((result, i) => ({
            min: 0,
            max: 200,
            uom: "",
            options: {
              opposite: i % 2 === 0,
              type: "linear",
              scrollbar: { enabled: false },
            },
            series: [
              {
                name: "cs",
                type: "line",
                color: "#960113",
                data: result.CS,
              },
              {
                name: "bs",
                type: "line",
                color: "#51A5DE",
                data: result.BS,
              },
              {
                name: "ms",
                type: "line",
                color: "#417505",
                data: result.MS,
              },
              {
                name: "mis",
                type: "line",
                color: "#934299",
                data: result.MIS,
              },
              {
                name: "is",
                type: "line",
                color: "#FFA800",
                data: result.IS,
              },

              {
                name: "mrs",
                type: "line",
                color: "#c97900",
                data: result.MRS,
              },
            ],
          }))}
        />
      </Card>
    </>
  );
};
