import { createContext, useState, useContext, useEffect } from "react";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Button, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import {
  TimeSelectionsListData,
  useGetTimeSelectionList,
} from "./api/useGetTimeSelectionsList";
import { ItemCard } from "@/components/ItemCard";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { AnimatePresence, motion } from "framer-motion";
import { DateTime } from "luxon";
import { useCustomTimeRange } from "./store/useCustomTimeRange";
import { CategoryBasedView } from "./components/CategoryBasedView";
import { TimeBasedView } from "./components/TimeBasedView";
import { useTranslate } from "@/i18n/config";
import { useTimeSelection } from "@/store/useTimeSelection";

interface Period {
  start: DateTime;
  end: DateTime;
}

const FillerAnalyticsContext = createContext<{
  selectedPeriod: Period | null;
  setSelectedPeriod: React.Dispatch<React.SetStateAction<Period | null>>;
}>({ selectedPeriod: null, setSelectedPeriod: () => {} });

const FillerAnalyticsContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null);

  return (
    <FillerAnalyticsContext.Provider
      value={{ selectedPeriod, setSelectedPeriod }}
    >
      {children}
    </FillerAnalyticsContext.Provider>
  );
};

export const useFillerAnalyticsContext = () =>
  useContext(FillerAnalyticsContext);

const SECTIONS = {
  default: 0,
  timeBased: 1,
};

const FillerAnalytics = () => {
  const [section, setSection] = useState(SECTIONS.default);
  const { selectedPeriod, setSelectedPeriod } = useFillerAnalyticsContext();
  const { data: list } = useGetTimeSelectionList();
  const translate = useTranslate();

  useEffect(() => {
    setSelectedPeriod(null);

    //? forzatura per filler
    useTimeSelection.setState((prevState) => ({
      ...prevState,
      timeSelection: "Batch",
      dates: {
        dateStart: DateTime.fromMillis(1680307200000), // 1 aprile 2023
        dateEnd: DateTime.fromMillis(1682812800000), // 30 aprile 2023
      },
    }));
  }, [list]);

  const updateDates = (timeSelectionInfo: TimeSelectionsListData) => {
    const dateStart = DateTime.fromSeconds(timeSelectionInfo.dateStart / 1000);
    const dateEnd = DateTime.fromSeconds(timeSelectionInfo.dateEnd / 1000);
    useCustomTimeRange.setState(() => ({
      dateStart,
      dateEnd,
    }));
    setSelectedPeriod({ start: dateStart, end: dateEnd });
  };

  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection
          forceCalendar
          exclude={["Custom", "Month", "ThreeMonths", "SixMonths", "Year"]}
        />
        {selectedPeriod ? (
          <Button onClick={() => setSelectedPeriod(null)}>
            {translate("go_back_to_select")}
          </Button>
        ) : null}
      </MachineViewNavBar>
      {selectedPeriod ? (
        <>
          <Tabs
            value={section}
            aria-label="weighings detail tabs"
            onChange={(_: React.SyntheticEvent, newValue: number) => {
              setSection(newValue);
            }}
          >
            <Tab value={SECTIONS.default} label={translate("default")} />
            <Tab value={SECTIONS.timeBased} label={translate("time_based")} />
          </Tabs>
          {section === SECTIONS.default ? (
            <CategoryBasedView />
          ) : section === SECTIONS.timeBased ? (
            <TimeBasedView />
          ) : null}
        </>
      ) : null}
      {!selectedPeriod ? (
        <Stack spacing={2}>
          <Typography variant="h6" fontWeight="bold">
            {translate("please_select_a_period")}
          </Typography>
          <AnimatePresence presenceAffectsLayout mode="wait">
            <motion.div
              key={JSON.stringify(list)}
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                },
              }}
              initial="hidden"
              animate="show"
            >
              <Grid container spacing={2}>
                {list?.map((item) => (
                  <Grid item xs={6} md={3} key={item.id}>
                    <ItemCard
                      item={item}
                      onClick={(i) => updateDates(i)}
                      // onClick={(i) => setSelectedPeriod(i.id.toString())}
                      subtitle={`From: ${TimeHelpers.parseTimestampToString({
                        timestamp: item.dateStart,
                        fmt: "y LLL dd HH:mm:ss ",
                      })}`}
                      secondarySubtitle={`To: ${TimeHelpers.parseTimestampToString(
                        {
                          timestamp: item.dateEnd,
                          fmt: "y LLL dd HH:mm:ss ",
                        },
                      )}`}
                      title={TimeHelpers.parseTimestampToString({
                        timestamp: item.dateStart,
                        fmt: "y LLL dd ",
                      })}
                      optionalLabel={item.timelapse}
                    />
                  </Grid>
                ))}
              </Grid>
            </motion.div>
          </AnimatePresence>
          {(list?.length == 0 || !list) && (
            <Typography>
              {translate(
                "user_feedback.no_options_available_user_different_time_selection",
              )}
            </Typography>
          )}
        </Stack>
      ) : null}
    </>
  );
};

export const FillerAnalyticsScreen = () => {
  return (
    <FillerAnalyticsContextProvider>
      <FillerAnalytics />
    </FillerAnalyticsContextProvider>
  );
};
