import { ShowEmptyData } from "@/components/ShowEmptyData";
import { BasicTable } from "@/components/BasicTable";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { TableParser } from "@/utils/TableParser";
import {
  useGetHomingStreamSelector,
  HomingData,
} from "../api/useGetHomingStreamSelector";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";

export const HomingList = () => {
  const translate = useTranslate();
  const { data: homingList, isLoading } = useGetHomingStreamSelector();
  if (isLoading) return <AnalyticContainerSkeleton />;

  if (!homingList) return null;

  if (homingList.length === 0) {
    return <ShowEmptyData title="Homing" />;
  }

  const { columns, rows } = TableParser.parseData<HomingData>({
    data: homingList,
    columnsMap: {
      durationms: translate("duration"),
      Timestamp: "Timestamp",
      timestampEnd: translate("end"),
    },
    columnsOrder: ["Timestamp", "timestampEnd", "durationms"],
  });

  return (
    <Card>
      <BasicTable columns={columns} rows={rows} fileName="homing_data" />
    </Card>
  );
};
