import { Stack, Typography } from "@mui/material";
import { intellectaVersionStore } from "../../store/intellecta-version";
import { PowerWord } from "../../types";
import { PowerwordButtonStandard } from "./PowerwordButtonStandard";
import { PowerwordButtonAlarm } from "./PowerwordButtonAlarm";
import { useTranslate } from "@/i18n/config";

export const PowerwordButtonList = ({
  powerwords,
}: {
  powerwords: PowerWord[];
}) => {
  const translate = useTranslate();
  const { version } = intellectaVersionStore();

  const filteredPowerwords = version
    ? powerwords.filter((powerWord) => powerWord.version.includes(version))
    : [];

  return (
    <Stack gap={2}>
      {filteredPowerwords.length > 0 ? (
        filteredPowerwords.map((powerWord) => {
          return powerWord.type === "standard" ? (
            <PowerwordButtonStandard
              key={powerWord.label}
              powerWord={powerWord}
            />
          ) : (
            <PowerwordButtonAlarm key={powerWord.label} powerWord={powerWord} />
          );
        })
      ) : (
        <Typography>{translate("intellecta.no_power_words")}</Typography>
      )}
    </Stack>
  );
};
