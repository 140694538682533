import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type AvailableNoveltyAndAnomalyAnalyticsResponse =
  | ThingworxError
  | AvailableNoveltyAndAnomalyAnalyticsResult;

const availableNoveltyAnomalySchema = z.object({
  noveltyAnalytics: z.array(z.string()),
  anomalyAnalytics: z.array(z.string()),
});

export type AvailableNoveltyAnomalySchema = z.infer<
  typeof availableNoveltyAnomalySchema
>;

const AvailableNoveltyAndAnomalyAnalyticsResult = z.object({
  response: z.literal(true),
  availableAnalytics: availableNoveltyAnomalySchema,
});

export type AvailableNoveltyAndAnomalyAnalyticsResult = z.infer<
  typeof AvailableNoveltyAndAnomalyAnalyticsResult
>;

interface Payload {
  machineName: string;
}

export const useGetAvailableNoveltyAndAnomalyAnalytics = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<AvailableNoveltyAnomalySchema>({
    queryKey: ["available-novelty-anomaly", machine?.machine],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        AvailableNoveltyAndAnomalyAnalyticsResponse
      >({
        appKey,
        payload: {
          machineName: machine?.machine || "",
        },
        url: "dig.c.motorNoveltyAndAnomalyDetection_thing/Services/getAvailableNoveltyAndAnomalyAnalytics",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse =
        AvailableNoveltyAndAnomalyAnalyticsResult.parse(response);
      return validatedResponse.availableAnalytics;
    },
  });
};
