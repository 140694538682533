import { Card } from "@/components/Layout/Card";
import { Heatmap } from "@/components/highcharts/heatmap/Heatmap";
import { useTimeSelection } from "@/store/useTimeSelection";
import { TimeHelpers } from "@/utils/TimeHelpers";

const baseHeatmapData = [
  [0, 0, 0.4642395381602252],
  [1, 0, 0.4687843552804723],
  [2, 0, 0.4813581581581487],
  [3, 0, 0.47128782101927047],
  [4, 0, 0.47209081679892384],
  [5, 0, 0.4653945157888123],
  [6, 0, 0.46724674487970297],
  [7, 0, 0.4412329202031491],
  [8, 0, 0.46002809221329183],
  [9, 0, 0.4435087780500451],
  [10, 0, 0.5297067305951294],
  [11, 0, 0.528953662157774],
  [12, 0, 0.5162980321864592],
  [13, 0, 0.45756565866672994],
  [14, 0, 0.4670979393519209],
  [0, 1, 0.45044263896733583],
  [1, 1, 0.4678748190698829],
  [2, 1, 0.47672035870113005],
  [3, 1, 0.4879691213479562],
  [4, 1, 0.47311485092469996],
  [5, 1, 0.46642790365576986],
  [6, 1, 0.46796619781494275],
  [7, 1, 0.46838584986571535],
  [8, 1, 0.4307503106597371],
  [9, 1, 0.4508601065568235],
  [10, 1, 0.45834132548699713],
  [11, 1, 0.5210819540865954],
  [12, 1, 0.5294460423137253],
  [13, 1, 0.653915627563868],
  [14, 1, 0.4496356720349729],
  [0, 2, 0.4623096801613783],
  [1, 2, 0.4774699088378741],
  [2, 2, 0.46392612699837626],
  [3, 2, 0.4834089336659983],
  [4, 2, 0.4699289145484354],
  [5, 2, 0.4541989471876978],
  [6, 2, 0.46331880919015467],
  [7, 2, 0.4278567685293083],
  [8, 2, 0.444638951601181],
  [9, 2, 0.4437650557447859],
  [10, 2, 0.4519284922458602],
  [11, 2, 0.6809603484330407],
  [12, 2, 0.5367199919698025],
  [13, 2, 0.4884412117810822],
  [14, 2, 0.5321355834693757],
  [0, 3, 0.51178695474638],
  [1, 3, 0.47039020380961494],
  [2, 3, 0.4418238109252113],
  [3, 3, 0.46996916144323614],
  [4, 3, 0.4684324054967994],
  [5, 3, 0.46644584066915085],
  [6, 3, 0.514045817508471],
  [7, 3, 0.5340973047898494],
  [8, 3, 0.44120318663006997],
  [9, 3, 0.43933947452628563],
  [10, 3, 0.5776491884238691],
  [11, 3, 0.5454412918504248],
  [12, 3, 0.6054411544197923],
  [13, 3, 0.5179824082524156],
  [14, 3, 0.4757868234455029],
  [0, 4, 0.4819331034776503],
  [1, 4, 0.4692432869654338],
  [2, 4, 0.46648908338881334],
  [3, 4, 0.44478255968291586],
  [4, 4, 0.46597185632666865],
  [5, 4, 0.5245990536962579],
  [6, 4, 0.5480279477608999],
  [7, 4, 0.5472976082017654],
  [8, 4, 0.530812721600007],
  [9, 4, 0.5581601839461681],
  [10, 4, 0.6932810824634277],
  [11, 4, 0.5900974784133345],
  [12, 4, 0.535174324514923],
  [13, 4, 0.6003842452979494],
  [14, 4, 0.5292929341628243],
  [0, 5, 0.4677288873199449],
  [1, 5, 0.473527450088728],
  [2, 5, 0.47356681891047536],
  [3, 5, 0.44394080267362906],
  [4, 5, 0.449955349148499],
  [5, 5, 0.4651346889290848],
  [6, 5, 0.553986722128538],
  [7, 5, 0.5478436032451794],
  [8, 5, 0.6696926879298126],
  [9, 5, 0.6526564261656972],
  [10, 5, 0.7277944118831029],
  [11, 5, 0.6720155573999224],
  [12, 5, 0.7635811877788745],
  [13, 5, 0.5835937751026788],
  [14, 5, 0.5656178160353468],
  [0, 6, 0.5748739907528392],
  [1, 6, 0.4667339247847462],
  [2, 6, 0.4439199351857163],
  [3, 6, 0.5092977795884801],
  [4, 6, 0.511926084956079],
  [5, 6, 0.5125528360107948],
  [6, 6, 0.4648383718800341],
  [7, 6, 0.6641304904467897],
  [8, 6, 0.8538296571949591],
  [9, 6, 0.7237932438954914],
  [10, 6, 0.6670860581132536],
  [11, 6, 0.6075321920945653],
  [12, 6, 0.7044060813889312],
  [13, 6, 0.6128025584165638],
  [14, 6, 0.652501683994134],
  [0, 7, 0.5243080868030223],
  [1, 7, 0.5125344797862683],
  [2, 7, 0.49010804659117835],
  [3, 7, 0.5135951909946229],
  [4, 7, 0.5150701737438537],
  [5, 7, 0.5082378124270912],
  [6, 7, 0.5012095907722612],
  [7, 7, 0.8932843604663819],
  [8, 7, 0.7060484294368292],
  [9, 7, 0.8489866517068106],
  [10, 7, 0.7549292818528924],
  [11, 7, 0.8315568591309164],
  [12, 7, 0.7781327557351331],
  [13, 7, 0.8622711244536024],
  [14, 7, 0.6154269523905773],
  [0, 8, 0.5571035313669676],
  [1, 8, 0.5616493399885246],
  [2, 8, 0.5102325212889605],
  [3, 8, 0.49453034613822594],
  [4, 8, 0.48030862711515104],
  [5, 8, 0.5861080984867283],
  [6, 8, 0.6930732128653632],
  [7, 8, 1],
  [8, 8, 0.9789452184339107],
  [9, 8, 0.7319691834155893],
  [10, 8, 0.8857148408737556],
  [11, 8, 0.9208900502285425],
  [12, 8, 0.8296754283353976],
  [13, 8, 0.757067984849109],
  [14, 8, 0.5271213066265886],
  [0, 9, 0.5530113341190107],
  [1, 9, 0.5363937663346151],
  [2, 9, 0.5141896997879505],
  [3, 9, 0.49851831542145114],
  [4, 9, 0.6120036990477739],
  [5, 9, 0.6131071179087558],
  [6, 9, 0.6167506850811261],
  [7, 9, 0.6765321457060853],
  [8, 9, 0.7529107293614771],
  [9, 9, 0.9539954968176974],
  [10, 9, 0.7601407617033697],
  [11, 9, 0.9128480276961239],
  [12, 9, 0.7400872750333753],
  [13, 9, 0.5078387049713977],
  [14, 9, 0.5329261891692456],
  [0, 10, 0.5191956403153982],
  [1, 10, 0.5102630492452683],
  [2, 10, 0.5201528148174066],
  [3, 10, 0.5393464738864462],
  [4, 10, 0.5858787871123651],
  [5, 10, 0.6170879986430429],
  [6, 10, 0.5905683500280021],
  [7, 10, 0.6069514827635009],
  [8, 10, 0.8923267934738444],
  [9, 10, 0.9982964864927666],
  [10, 10, 0.8508881251655198],
  [11, 10, 0.7158670627435368],
  [12, 10, 0.9227149437290892],
  [13, 10, 0.6556187344717381],
  [14, 10, 0.588493467532544],
  [0, 11, 0.5560694349146463],
  [1, 11, 0.6353758418858277],
  [2, 11, 0.5428009590933858],
  [3, 11, 0.9078763878439023],
  [4, 11, 0.5692728897806398],
  [5, 11, 0.5699466602554041],
  [6, 11, 0.6309967481152066],
  [7, 11, 0.6320476295302143],
  [8, 11, 0.6192835953474768],
  [9, 11, 0.8237453559982674],
  [10, 11, 0.9995392635035559],
  [11, 11, 0.6444374084613375],
  [12, 11, 0.6570020956714896],
  [13, 11, 0.6374838934637553],
  [14, 11, 0.6288014027575732],
  [0, 12, 0.595116025533253],
  [1, 12, 0.9662298571730508],
  [2, 12, 0.6480911205573424],
  [3, 12, 0.5863638539052853],
  [4, 12, 0.537965115654336],
  [5, 12, 0.5123983162732924],
  [6, 12, 0.6117285084666902],
  [7, 12, 0.6291191642829452],
  [8, 12, 0.6187526644066134],
  [9, 12, 0.5904447977191845],
  [10, 12, 0.5951328927195353],
  [11, 12, 0.5802144606817614],
  [12, 12, 0.5341959350147059],
  [13, 12, 0.6305843053479284],
  [14, 12, 0.5972137942680597],
  [0, 13, 0.585111840997453],
  [1, 13, 0.5999963154330853],
  [2, 13, 0.6421439138069553],
  [3, 13, 0.5055964887082697],
  [4, 13, 0.5326146387280948],
  [5, 13, 0.5256547980986828],
  [6, 13, 0.5104985255712312],
  [7, 13, 0.6069351031937736],
  [8, 13, 0.5825307875028891],
  [9, 13, 0.5335896021903495],
  [10, 13, 0.5800422248970482],
  [11, 13, 0.5877405504114258],
  [12, 13, 0.5393700623236197],
  [13, 13, 0.5265860503512464],
  [14, 13, 0.5700991272489351],
  [0, 14, 0.5799153565389302],
  [1, 14, 0.5692899190826136],
  [2, 14, 0.9362938643804128],
  [3, 14, 0.565118896568754],
  [4, 14, 0.5185342911296341],
  [5, 14, 0.5222609106365047],
  [6, 14, 0.46130507474803706],
  [7, 14, 0.682172722745364],
  [8, 14, 0.9298805273531787],
  [9, 14, 0.5739538038936534],
  [10, 14, 0.5371644227742737],
  [11, 14, 0.5429434597153425],
  [12, 14, 0.5230818584130382],
  [13, 14, 0.7579398960436993],
  [14, 14, 0.5217113451666887],
  [0, 15, 0.4484845745659392],
  [1, 15, 0.4528734452927313],
  [2, 15, 0.5821971256198805],
  [3, 15, 0.5425961414471415],
  [4, 15, 0.5123912565363357],
  [5, 15, 0.45864696577664404],
  [6, 15, 0.45940080688284785],
  [7, 15, 0.4401260644630868],
  [8, 15, 0.5771227336411963],
  [9, 15, 0.5506517077358777],
  [10, 15, 0.5272435296378043],
  [11, 15, 0.5110478514087075],
  [12, 15, 0.5264011934668101],
  [13, 15, 0.5256532885929257],
  [14, 15, 0.44877630966467763],
  [0, 16, 0.4722023949547098],
  [1, 16, 0.4571276373627787],
  [2, 16, 0.5377441558043776],
  [3, 16, 0.5502583624587633],
  [4, 16, 0.5124019623987182],
  [5, 16, 0.4512902330201233],
  [6, 16, 0.4599579980869719],
  [7, 16, 0.460983603089207],
  [8, 16, 0.46097430418789614],
  [9, 16, 0.5448626461454843],
  [10, 16, 0.5152672950358389],
  [11, 16, 0.49913747249876844],
  [12, 16, 0.49593701197254314],
  [13, 16, 0.4499699850788533],
  [14, 16, 0.47794070281803086],
  [0, 17, 0.4658590084777038],
  [1, 17, 0.4597914952894942],
  [2, 17, 0.44377178894183195],
  [3, 17, 0.5026484715761137],
  [4, 17, 0.4312179329013606],
  [5, 17, 0.4440367483038995],
  [6, 17, 0.46484867715488687],
  [7, 17, 0.457450640408568],
  [8, 17, 0.4562433775286675],
  [9, 17, 0.5043987704318064],
  [10, 17, 0.5053544342130553],
  [11, 17, 0.5070917309650375],
  [12, 17, 0.5106368113362343],
  [13, 17, 0.439963708462764],
  [14, 17, 0.44589533717975627],
  [0, 18, 0.4461664610889574],
  [1, 18, 0.47146125092909275],
  [2, 18, 0.4661228539415898],
  [3, 18, 0.4615206999641802],
  [4, 18, 0.44780174822301866],
  [5, 18, 0.4494028660951833],
  [6, 18, 0.44342261520808923],
  [7, 18, 0.45908727911356595],
  [8, 18, 0.474037695077862],
  [9, 18, 0.458658510178848],
  [10, 18, 0.6603956655370862],
  [11, 18, 0.5032492433029649],
  [12, 18, 0.4457831762152843],
  [13, 18, 0.4559716450994701],
  [14, 18, 0.4567922244463797],
  [0, 19, 0.4737090370350764],
  [1, 19, 0.4657807586207509],
  [2, 19, 0.4708664582268124],
  [3, 19, 0.4707261720934103],
  [4, 19, 0.46423390031421985],
  [5, 19, 0.4488895624629895],
  [6, 19, 0.43545326109525123],
  [7, 19, 0.47480465883323586],
  [8, 19, 0.47373388129565797],
  [9, 19, 0.47127562812599877],
  [10, 19, 0.4623563641705923],
  [11, 19, 0.5158760328997499],
  [12, 19, 0.4712926051853333],
  [13, 19, 0.550017059615514],
  [14, 19, 0.4387824603083679],
];

export const SelfOrganizingMap = () => {
  const { dates } = useTimeSelection();

  const formattedSubtitle = `trained on ${TimeHelpers.parseTimestampToString({
    timestamp: +dates.dateStart.toJSDate(),
    fmt: "DD",
  })} - 
  ${TimeHelpers.parseTimestampToString({
    timestamp: +dates.dateEnd.toJSDate(),
    fmt: "DD",
  })}`;

  return (
    <Card>
      <Heatmap.Demo
        data={baseHeatmapData}
        title="Self Organizing Map"
        subtitle={formattedSubtitle}
      />
    </Card>
  );
};
