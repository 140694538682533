import { RuleSchema } from "../api/useGetEventsData/getRules";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { ChangeEvent } from "react";
import { useChangeEventLoop } from "../api/useChangeEventLoop";
import { useChangeIsActive } from "../api/useChangeIsActive";
import { useFirebaseContext } from "@/context/firebase-context";
import { EditRuleModal } from "./EditRuleModal";
import { useDisclosure } from "@/hooks/useDisclosure";
import { EventValues } from "../types";
import { useDeleteRule } from "../api/useDeleteRule";
import { Modal } from "@/components/Modal";
import { useTranslate } from "@/i18n/config";

const getValuesText = (values: EventValues): string => {
  if (Object.keys(values).length === 1) {
    return `${values.value_1}`;
  } else {
    return `between ${values.value_1} and ${values.value_2}`;
  }
};

export const Rule = ({ info }: { info: RuleSchema }) => {
  const { platformLvl } = useFirebaseContext();
  const { mutate: changeEventLoop, isLoading: isLoadingEventLoop } =
    useChangeEventLoop();
  const { mutate: changeIsActive, isLoading: isLoadingIsActive } =
    useChangeIsActive();
  const {
    ruleName,
    ruleDescription,
    machine,
    company,
    timestamp,
    account,
    data,
    isEnabled,
    eventLooping,
  } = info;
  const {
    open: openEditModal,
    close: closeEditModal,
    isOpen: isOpenEditModal,
  } = useDisclosure();
  const {
    open: openDeleteModal,
    close: closeDeleteModal,
    isOpen: isOpenDeleteModal,
  } = useDisclosure();
  const { mutate: deleteRule } = useDeleteRule();
  const translate = useTranslate();

  let rule = "";
  data.forEach((limit) => {
    const {
      isActiveDuration,
      duration,
      property,
      name,
      propertyNameClient,
      alertType,
      values,
      rate,
    } = limit;

    const formatted = new Date(duration * 1000).toISOString().slice(11, 19);
    if (property === "firstAlarm" || name === "firstAlarm") {
      const durationText =
        formatted == "00:00:00" ? "" : `with duration of ${formatted}`;

      rule += `${propertyNameClient} ${alertType} ${getValuesText(
        values,
      )} ${durationText}`;
    } else {
      if (isActiveDuration) {
        rule += `${propertyNameClient} ${alertType} ${getValuesText(
          values,
        )} and lasted for at least ${formatted} ${rate} times`;
      } else {
        rule += `${propertyNameClient} ${alertType} ${getValuesText(values)}`;
      }
    }
  });

  const handleDelete = () => {
    deleteRule({ ruleName });
  };

  const handleEnable = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    changeIsActive({ isEnabled: value, platformLvl, ruleName });
  };

  const handleEventLoop = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    changeEventLoop({ eventLoop: value, platformLvl, ruleName });
  };

  return (
    <>
      {isOpenEditModal && (
        <EditRuleModal
          isOpen={isOpenEditModal}
          close={closeEditModal}
          ruleData={info}
        />
      )}
      {isOpenDeleteModal && (
        <Modal
          titleContent={translate("events.want_to_delete_rule")}
          bodyContent={translate("events.confirm_delete_rule", {
            rule: ruleDescription,
          })}
          open={isOpenDeleteModal}
          actions={
            <>
              <Button variant="outlined" onClick={closeDeleteModal}>
                {translate("actions.cancel")}
              </Button>
              <Button variant="outlined" onClick={handleDelete}>
                {translate("actions.confirm")}
              </Button>
            </>
          }
        />
      )}
      <Card sx={{ display: "flex", gap: 2 }}>
        <Typography sx={{ flex: 3 }}>{ruleName}</Typography>
        <Typography sx={{ flex: 3 }}>{ruleDescription}</Typography>
        <Typography sx={{ flex: 2 }}>{machine}</Typography>
        <Typography sx={{ flex: 2 }}>{company}</Typography>
        <Typography sx={{ flex: 4 }}>{rule}</Typography>
        <Box sx={{ flex: 0.5 }}>
          <Checkbox
            checked={isEnabled}
            onChange={handleEnable}
            disabled={isLoadingIsActive}
          />
        </Box>
        <Box sx={{ flex: 0.5 }}>
          <Checkbox
            checked={eventLooping}
            onChange={handleEventLoop}
            disabled={isLoadingEventLoop}
            sx={{ flex: 0.5 }}
          />
        </Box>
        <Typography sx={{ flex: 3 }}>
          {new Date(timestamp).toLocaleString()}
        </Typography>
        <Typography sx={{ flex: 4 }}>{account}</Typography>
        <EditOutlined
          sx={{ cursor: "pointer" }}
          onClick={openEditModal}
          style={{ flex: 0.5 }}
        />
        <DeleteOutline
          sx={{ cursor: "pointer" }}
          onClick={openDeleteModal}
          style={{ flex: 0.5 }}
        />
      </Card>
    </>
  );
};
