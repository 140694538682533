import { useTranslate } from "@/i18n/config";
import { useTimeSelection } from "@/store/useTimeSelection";
import {
  DateRange,
  DateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { useEffect } from "react";

/**
 * valutare se rimuovere del tutto la callback onPickDate e centralizzare il tutto tramite store
 * @returns
 * @private usare solamente il componente SelectTimeSelection
 */
export const RangePicker = ({
  onPickDate,
  initialDates,
}: {
  onPickDate: ({ dates }: { dates: DateRange<DateTime> }) => void;
  initialDates?: DateRange<DateTime>;
}) => {
  const translate = useTranslate();
  const { dates } = useTimeSelection();

  useEffect(() => {
    if (initialDates) {
      const [start, end] = initialDates;

      if (!start || !end) return;

      useTimeSelection.setState((prevState) => ({
        ...prevState,
        dates: {
          dateStart: start,
          dateEnd: end.endOf("day"),
        },
      }));
    }
  }, []);

  return (
    <DateRangePicker
      reduceAnimations
      label={translate("pick_dates")}
      value={[dates.dateStart, dates.dateEnd]}
      disableFuture
      slotProps={{
        textField: {
          size: "small",
          sx: {
            width: "fit-content",
          },
        },
      }}
      onChange={(dates) => {
        if (!dates[0] || !dates[1]) return;

        const [start, end] = dates;
        /**
         * bang !! operator is fine since we're checking for every value, but the compiler is
         * not able to infer the type
         */

        useTimeSelection.setState((prevState) => ({
          ...prevState,
          dates: {
            dateStart: start,
            dateEnd: end.endOf("day"),
          },
        }));

        onPickDate({ dates });
      }}
      slots={{
        field: SingleInputDateRangeField,
      }}
    />
  );
};
