import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { ContextInfo, Frame } from "../types";
import { useMotorsContext } from "../context/useMotorsContext";

type NoveltyAndAnomalyDataResponse =
  | ThingworxError
  | NoveltyAndAnomalyDataResult;

const noveltyAnomalyDataSchema = z.array(
  z.object({
    analyticName: z.string(),
    rawData: z.array(z.array(z.number())),
    outliers: z.array(z.array(z.number())),
  }),
);

const noveltyAnomalyAnalytic = z.union([
  z.literal("novelty"),
  z.literal("anomaly"),
]);

export type NoveltyAnomalyAnalytic = z.infer<typeof noveltyAnomalyAnalytic>;

export type NoveltyAnomalyDataSchema = z.infer<typeof noveltyAnomalyDataSchema>;

const noveltyAnomalyResSchema = z.object({
  analysisName: z.string(),
  analysisType: noveltyAnomalyAnalytic,
  motorName: z.string(),
  frameName: z.string(),
  data: noveltyAnomalyDataSchema,
  contextInfo: ContextInfo,
});

export type NoveltyAnomalyResSchema = z.infer<typeof noveltyAnomalyResSchema>;

const NoveltyAndAnomalyDataResult = z.object({
  response: z.literal(true),
  analytics: z.array(noveltyAnomalyResSchema),
});

export type NoveltyAndAnomalyDataResult = z.infer<
  typeof NoveltyAndAnomalyDataResult
>;

interface Payload {
  machineName: string;
  framesList: Frame[];
  motorsList: number[];
  noveltyAnalytics: string[];
  anomalyAnalytics: string[];
}

export const useGetNoveltyAndAnomalyData = ({
  noveltyAnalytics,
  anomalyAnalytics,
}: {
  noveltyAnalytics: string[];
  anomalyAnalytics: string[];
}) => {
  const { machine } = useMachineContext();
  const { frames, motors } = useMotorsContext();
  const { appKey } = useFirebaseContext();

  return useQuery<NoveltyAnomalyResSchema[]>({
    queryKey: [
      "novelty-anomaly-data",
      machine?.machine,
      JSON.stringify(frames),
      JSON.stringify(motors),
      noveltyAnalytics,
      anomalyAnalytics,
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        NoveltyAndAnomalyDataResponse
      >({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          framesList: frames,
          motorsList: motors,
          noveltyAnalytics,
          anomalyAnalytics,
        },
        url: "dig.c.motorNoveltyAndAnomalyDetection_thing/Services/getNoveltyAndAnomalyData",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = NoveltyAndAnomalyDataResult.parse(response);
      return validatedResponse.analytics;
    },
  });
};
