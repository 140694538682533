import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { QueryBuilder } from "@/utils/query-builder";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type PhysicalSelectResponse = ThingworxError | PhysicalSelectResult;

const PhysicalSelectResult = z.object({
  setpointChanges: z.number(),
  setpointSeries: z.array(z.array(z.number())),
  dateStart: z.number(),
  response: z.literal(true),
  name: z.string(),
  valueSeries: z.array(z.array(z.number())),
  dateEnd: z.number(),
});

type PhysicalSelectResult = z.infer<typeof PhysicalSelectResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateEnd?: DateTime;
  dateStart?: DateTime;
  physicalName: string;
};

export const useGetDataSelect = ({
  physical_name,
}: {
  physical_name: string;
}) => {
  const { timeSelection, dates } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: [
      "machine-physical-select",
      timeSelection,
      machine!.machine,
      physical_name,
    ],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload = {
    machineName: machine!.machine,
    timeSelection,
    physicalName: physical_name,
  };

  return useQuery<PhysicalSelectResult>({
    queryKey: query,

    queryFn: async () => {
      const response = await FetchClient<Payload, PhysicalSelectResponse>({
        appKey,
        url: "dig.c.physicalMeasure_thing/Services/getDataSelect",
        payload:
          timeSelection === "Custom" ? { ...payload, ...dates } : payload,
      });

      if (!response.response) throw new Error(response.errorString);

      return PhysicalSelectResult.parse(response);
    },

    refetchInterval: timeSelection === "Custom" ? false : 1000 * 30,
  });
};
