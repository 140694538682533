import { ScatterPlot } from "@/components/highcharts/scatter/Scatter";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { BasicTable } from "@/components/BasicTable";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { TableParser } from "@/utils/TableParser";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Box, Stack, Typography } from "@mui/material";
import { renderToString } from "react-dom/server";
import { AnalyticContainerSkeleton } from "../Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetJamsDetail, JamsDetail } from "./api/useGetJamsDetail";
import { useGetJamsOverview, JamOverview } from "./api/useGetJamsOverview";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { z } from "zod";

export const Jams = () => {
  const { data: jamsOverviews, isLoading } = useGetJamsOverview();

  const { data: jamsDetails, isLoading: loadingJamsDetails } =
    useGetJamsDetail();
  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection />
      </MachineViewNavBar>

      <Stack gap={2}>
        {loadingJamsDetails ? (
          <>
            <AnalyticContainerSkeleton />
            <AnalyticContainerSkeleton />
          </>
        ) : null}
        {jamsDetails ? (
          <>
            <Card>
              <ScatterPlot.Custom
                title="Jams"
                tooltip={{
                  enabled: true,
                  options: {
                    formatter() {
                      const customParsed = z
                        .object({
                          custom: z.object({
                            description: z.string(),
                          }),
                        })
                        .safeParse(this.point);
                      if (!customParsed.success) {
                        return;
                      }

                      const custom = customParsed.data.custom;
                      return renderToString(
                        <Box>
                          <Typography variant="body2" fontWeight="bold">
                            {custom.description}
                          </Typography>
                          {this.x && typeof this.x === "number" ? (
                            <Typography variant="body2" color="text.secondary">
                              Timestamp:{" "}
                              {TimeHelpers.parseTimestampToString({
                                timestamp: this.x,
                              })}
                            </Typography>
                          ) : null}
                        </Box>,
                      );
                    },
                  },
                }}
                yAxis={[
                  {
                    options: {
                      categories: jamsDetails.categories,
                    },

                    uom: "",
                    series: [
                      {
                        type: "scatter",
                        color: "red",
                        turboThreshold: 0,
                        data: jamsDetails.Result.map((jam) => ({
                          x: jam.Timestamp,
                          custom: {
                            description: jam.ErrorDescription,
                          },
                          y: jamsDetails.categories.findIndex(
                            (j) => j === jam.ZoneDescription,
                          ),
                        })),
                      },
                    ],
                  },
                ]}
              />
            </Card>
            <JamsDetailTable jamsDetails={jamsDetails.Result} />
          </>
        ) : null}
        {isLoading ? <AnalyticContainerSkeleton /> : null}
        {jamsOverviews ? (
          <JamsSummaryTable jamsOverviews={jamsOverviews} />
        ) : null}
      </Stack>
    </>
  );
};

function JamsDetailTable({ jamsDetails }: { jamsDetails: JamsDetail[] }) {
  const translate = useTranslate();
  if (jamsDetails.length === 0) {
    return <ShowEmptyData title="Jams Detail" />;
  }
  const { columns, rows } = TableParser.parseData<JamsDetail>({
    data: jamsDetails,
    columnsMap: {
      Error: translate("error"),
      ErrorDescription: translate("error_description"),
      Timestamp: "Timestamp",
      Zone: translate("zone"),
      ZoneDescription: translate("zone_description"),
    },
    columnsOrder: [
      "Timestamp",
      "Zone",
      "ZoneDescription",
      "Error",
      "ErrorDescription",
    ],
  });

  return (
    <Card>
      {translate("detailed_table")}
      <BasicTable columns={columns} rows={rows} fileName="jams_detail_data" />
    </Card>
  );
}

function JamsSummaryTable({ jamsOverviews }: { jamsOverviews: JamOverview[] }) {
  if (jamsOverviews.length === 0) {
    return <ShowEmptyData title="Jams Overview" />;
  }

  const { columns, rows } = TableParser.parseData<JamOverview>({
    data: jamsOverviews,
    columnsMap: {
      Count: "Count",
      Duration: "Duration",
      ZoneDescription: "Zone",
    },
    columnsOrder: ["ZoneDescription", "Count", "Duration"],
  });
  return (
    <Card>
      Summary Table
      <BasicTable columns={columns} rows={rows} fileName="jams_overview_data" />
    </Card>
  );
}
