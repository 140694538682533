import { LineData } from "@/features/Diagnostic/pages/LYO/types";
import { HighchartsCustomProvider } from "../highcharts";
import { Duration } from "luxon";
import { renderToString } from "react-dom/server";

import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  SplineSeries,
  Tooltip,
  LineSeries,
  Legend,
  Title,
  HighchartsChartProps,
} from "react-jsx-highcharts";
import { ChartZoomingOptions } from "highcharts/highstock";

interface LinechartProps {
  title: string;
  data: LineData[];
}

export class LineChart {
  static Custom({
    title,
    yAxis = [],
    xAxes,
    tooltip = { enabled: true },
    legend = { enabled: false },
    lineType = "line",
    xAxisType = "datetime",
    xAxisOptions,
    exportingEnabled = true,
    zoomType = "xy",
    plotOptions,
    highchartsChartProps,
    height = 300,
    filename,
  }: {
    title: string;
    yAxis: {
      uom: string;
      options?: Highcharts.YAxisOptions;
      series?:
        | Highcharts.SeriesLineOptions[]
        | Highcharts.SeriesSplineOptions[];
      yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
      thresholds?: {
        value: number;
        color: string;
        text: string;
      }[];
    }[];
    legend?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
    tooltip?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
    lineType?: "line" | "spline";
    xAxisType?: Highcharts.AxisTypeValue;
    xAxisOptions?: Highcharts.XAxisOptions;
    xAxes?: Highcharts.XAxisOptions[];
    exportingEnabled?: boolean;
    zoomType?: ChartZoomingOptions["type"];
    plotOptions?: Highcharts.PlotOptions;
    highchartsChartProps?: HighchartsChartProps;
    height?: number;
    filename?: string;
  }) {
    const Line = lineType === "line" ? LineSeries : SplineSeries;
    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          exporting={{
            enabled: exportingEnabled,
            filename,
          }}
          chart={{
            height: height,
          }}
          accessibility={{ enabled: false }}
          plotOptions={{ ...plotOptions }}
          time={{ useUTC: false }}
          {...highchartsChartProps}
        >
          <Chart
            backgroundColor={"transparent"}
            type=""
            zooming={{ type: zoomType }}
            style={{
              color: "white",
            }}
            height={height}
          />
          <Title style={{ color: "white" }} align="left">
            {title}
          </Title>

          {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}

          {legend.enabled && (
            <Legend
              itemStyle={{
                color: "white",
              }}
              {...legend.options}
            ></Legend>
          )}
          {xAxes ? (
            xAxes.map((xAxis, i) => (
              <XAxis
                key={"xAxis" + i}
                labels={{ style: { color: "white" } }}
                {...xAxis}
              ></XAxis>
            ))
          ) : (
            <XAxis
              labels={{ style: { color: "white" } }}
              scrollbar={{
                enabled: false,
                liveRedraw: true,
                barBackgroundColor: "#0D1626",
                barBorderRadius: 7,
                barBorderWidth: 0,
                buttonBackgroundColor: "#0D1626",
                buttonBorderWidth: 1,
                buttonBorderRadius: 7,
                buttonArrowColor: "#0D1626",
                buttonBorderColor: "#3f4961",
                rifleColor: "#0D1626",
                trackBackgroundColor: "#3f4961",
                trackBorderWidth: 1,
                trackBorderRadius: 8,
                trackBorderColor: "none",
              }}
              type={xAxisType}
              {...xAxisOptions}
            ></XAxis>
          )}

          {yAxis?.map(
            (
              { uom, series = [], thresholds = [], yAxisTitleOptions, options },
              i,
            ) => (
              <YAxis
                key={i}
                labels={{ style: { color: "white" }, format: "{value} " + uom }}
                showEmpty
                title={yAxisTitleOptions}
                type={"linear"}
                plotLines={thresholds?.map((t) => ({
                  value: t.value,
                  color: t.color,
                  width: 2,
                  zIndex: 5,
                  acrossPanes: true,
                  dashStyle: "Dash",
                  label: {
                    text: t.text,
                    align: "right",
                    x: -10,
                    style: {
                      color: t.color,
                    },
                  },
                }))}
                {...options}
                alignTicks={false}
              >
                {series.map((serie, i) => (
                  <Line key={i} {...serie} borderColor="none" />
                ))}
              </YAxis>
            ),
          )}
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }

  static DurationLine({ title, data }: LinechartProps) {
    const [valueData, goldenData] = data;
    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          // plotOptions={plotOptions}
          plotOptions={{
            spline: {
              marker: {
                enabled: false,
              },
            },
          }}
          accessibility={{ enabled: false }}
          exporting={{
            enabled: true,
            buttons: {
              contextButton: {
                menuItems: ["downloadCSV", "downloadXLS"],
              },
            },
          }}
        >
          <Chart
            backgroundColor={"transparent"}
            type="spline"
            zooming={{ type: "xy" }}
            style={{
              color: "white",
            }}
            alignTicks={false}
          />

          <Title style={{ color: "white" }} align="left">
            {title}
          </Title>
          <Tooltip
            shared
            split={false}
            valueSuffix={" " + data[0].uom}
            useHTML
            valueDecimals={2}
            formatter={function () {
              if (!this.x) return;
              const d = Duration.fromMillis(parseInt(this.x.toString(), 10));
              const date = d.toFormat("hh:mm:ss");

              return renderToString(
                <div
                  style={{
                    width: "200px",
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Duration: {date}</div>

                  {this.points
                    ? this.points.map((point, i) => (
                        <div key={i}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: "&bull;",
                            }}
                            style={{
                              color: point.color?.toString(),
                            }}
                          />
                          <b> {point.series.name}</b>:{" "}
                          <b>
                            {point.y?.toFixed(2)} {data[0].uom}
                          </b>
                          <br />
                        </div>
                      ))
                    : null}
                </div>,
              );
            }}
          />
          <XAxis
            tickColor="white"
            labels={{
              style: { color: "white" },
              formatter() {
                const d = Duration.fromMillis(
                  parseInt(this.value.toString(), 10),
                );
                return d.toFormat("hh:mm:ss");
              },
            }}
          >
            <XAxis.Title style={{ color: "white" }}>Duration</XAxis.Title>
          </XAxis>
          <YAxis labels={{ style: { color: "white" } }}>
            {/* {data.map(({ name, data }, i) => ( */}
            {/* {data.reverse().map(({ name, data: values }) => {
              return (
                // @ts-ignore
                <SplineSeries
                  key={name}
                  marker={{ enabled: false }}
                  color={name === "Value" ? "#fa6400" : "#ffe455"}
                  data={values}
                  name={name}
                />
              );
            })} */}
            <SplineSeries
              marker={{ enabled: false }}
              color={"#ffe455"}
              data={goldenData.data}
              name={goldenData.name}
            />
            <SplineSeries
              marker={{ enabled: false }}
              color={"#fa6400"}
              // color={"#56ff3d"}
              data={valueData.data}
              name={valueData.name}
            />
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }

  static AssetComparisonDurationLine({
    title,
    uom,
    series = [],
  }: {
    title: string;
    uom?: string;
    series?: Highcharts.SeriesSplineOptions[];
  }) {
    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          // plotOptions={plotOptions}
          plotOptions={{
            spline: {
              marker: {
                enabled: false,
              },
            },
          }}
          accessibility={{ enabled: false }}
          exporting={{
            enabled: true,
            buttons: {
              contextButton: {
                menuItems: ["downloadCSV", "downloadXLS"],
              },
            },
          }}
        >
          <Chart
            backgroundColor={"transparent"}
            type="spline"
            zooming={{ type: "xy" }}
            style={{
              color: "white",
            }}
            alignTicks={false}
          />

          <Title style={{ color: "white" }} align="left">
            {title}
          </Title>
          <Tooltip
            shared
            split={false}
            valueSuffix={" " + uom}
            useHTML
            valueDecimals={2}
            formatter={function () {
              if (!this.x) return;
              const d = Duration.fromMillis(parseInt(this.x.toString(), 10));
              const date = d.toFormat("hh:mm:ss");

              return renderToString(
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Duration: {date}</div>

                  {this.points
                    ? this.points.map((point, i) => (
                        <div key={i}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: "&bull;",
                            }}
                            style={{
                              color: point.color?.toString(),
                            }}
                          />
                          <b> {point.series.name}</b>:{" "}
                          <b>
                            {point.y?.toFixed(2)} {uom}
                          </b>
                          <br />
                        </div>
                      ))
                    : null}
                </div>,
              );
            }}
          />
          <Legend
            itemStyle={{
              color: "white",
            }}
            itemHoverStyle={{
              color: "#ccc",
            }}
            layout="vertical"
            align="right"
            verticalAlign="middle"
          />

          <XAxis
            tickColor="white"
            labels={{
              style: { color: "white" },
              formatter() {
                const d = Duration.fromMillis(
                  parseInt(this.value.toString(), 10),
                );
                return d.toFormat("hh:mm:ss");
              },
            }}
          >
            <XAxis.Title style={{ color: "white" }}>Duration</XAxis.Title>
          </XAxis>
          <YAxis labels={{ style: { color: "white" } }}>
            {series.map((s) => (
              <SplineSeries
                marker={{ enabled: false }}
                data={s.data}
                name={s.name}
                {...s}
              />
            ))}
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }

  static SpeedLine({ title, data }: LinechartProps) {
    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          plotOptions={{
            line: {
              marker: {
                enabled: false,
              },
              step: "right",
            },
          }}
          accessibility={{ enabled: false }}
          exporting={{
            enabled: false,
            buttons: {
              contextButton: {
                menuItems: ["downloadCSV", "downloadXLS"],
              },
            },
          }}
        >
          <Chart
            backgroundColor={"transparent"}
            type="line"
            zooming={{ type: "x" }}
            style={{
              color: "white",
            }}
            alignTicks={false}
          />

          <Title
            style={{
              color: "white",
              fontSize: "15px",
              fontWeight: "normal",
              fontStyle: "none",
              textTransform: "none",
            }}
            align="left"
            verticalAlign="top"
            margin={20}
            floating={false}
          >
            {title}
          </Title>
          <Legend
            align="right"
            verticalAlign="top"
            itemStyle={{
              color: "white",
            }}
            itemHoverStyle={{
              color: "white",
            }}
          />
          <Tooltip shared />
          <XAxis tickColor="white" type="datetime"></XAxis>
          <YAxis labels={{ style: { color: "white" } }} min={0}>
            {data.map(({ name, data, color }) => (
              <LineSeries
                key={name}
                marker={{ enabled: false }}
                data={data}
                name={name}
                color={color}
              />
            ))}
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }
}
