/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { fileNameFactory } from "@/utils/fileNameFactory";

export const ProductsHistogram = ({
  data,
}: {
  data: {
    category: string;
    wastes: number;
    products: number;
  }[];
}) => {
  const categories = data.map((item) => item.category);

  const products = data.map((item) =>
    Math.round((item.products * 100) / (item.products + item.wastes)),
  );
  const wastes = data.map((item) =>
    Math.round((item.wastes * 100) / (item.products + item.wastes)),
  );

  return (
    <BarChart.Custom
      title="Products/Wastes percentages by machine"
      uom="%"
      filename={fileNameFactory({
        name: "products_and_wastes",
      })}
      yAxisOptions={{
        min: 0,
        max: 100,
      }}
      plotOptions={{
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
            color: "white",
            borderColor: "transparent",
            formatter: function () {
              return `${this.y?.toFixed(0)}% ${this.series.name}`;
            },
            style: {
              textOutline: "none",
            },
          },
        },
      }}
      categories={categories}
      legend={{
        enabled: true,
      }}
      tooltip={{
        enabled: true,
      }}
      series={[
        {
          type: "column",
          name: "Wastes",
          data: wastes,
          color: "#E74C3C",
          // @ts-ignore
          borderRadiusTopLeft: 4,
          // @ts-ignore
          borderRadiusTopRight: 4,
          maxPointWidth: 100,
        },
        {
          maxPointWidth: 100,
          type: "column",
          name: "Products",
          data: products,
          color: "#2ECC71",
        },
      ]}
    />
  );
};
