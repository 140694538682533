import { SaveOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { toast } from "react-hot-toast";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { ChatMessageAction } from "./ChatMessageAction";
import { AddPromptModal } from "../Prompt/AddPromptModal";
import { useGetUserConfiguration } from "../../api/useGetUserConfiguration";
import { intellectaVersionStore } from "../../store/intellecta-version";

const MAX_PROMPTS = 5;

export const ChatMessageActionListUser = ({ message }: { message: string }) => {
  const translate = useTranslate();
  const { data } = useGetUserConfiguration();
  const { version } = intellectaVersionStore();
  const {
    open: openAddPromptModal,
    isOpen: isOpenAddPromptModal,
    close: closeAddPromptModal,
  } = useDisclosure();

  const promptsInCurrentVersion = data?.prompts.filter(
    (prompt) => prompt.version === version,
  );

  const handleClick = () => {
    if (
      promptsInCurrentVersion &&
      promptsInCurrentVersion.length > MAX_PROMPTS - 1
    ) {
      toast.error(`Max ${MAX_PROMPTS} prompts`);
    } else {
      openAddPromptModal();
    }
  };

  return (
    <>
      <Stack direction="row" gap={1}>
        <ChatMessageAction
          onClick={handleClick}
          popoverText={translate("intellecta.add_prompt")}
          icon={<SaveOutlined fontSize="small" />}
        ></ChatMessageAction>
      </Stack>
      {isOpenAddPromptModal && (
        <AddPromptModal
          isOpen={isOpenAddPromptModal}
          close={closeAddPromptModal}
          prompt={message}
        />
      )}
    </>
  );
};
