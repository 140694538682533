import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { QueryBuilder } from "@/utils/query-builder";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type SpeedResponse = ThingworxError | SpeedResult;

const Speed = z.object({
  color: z.string(),
  data: z.array(z.array(z.number())),
  name: z.string(),
  description: z.string(),
});

export type Speed = z.infer<typeof Speed>;

const SpeedResult = z.object({
  response: z.literal(true),
  data: z.array(Speed),
});

export type SpeedResult = z.infer<typeof SpeedResult>;

type Payload = {
  timeSelection: TimeSelection;
  machineName: string;
  dateStart?: DateTime;
  dateEnd?: DateTime;
};

export const useGetSpeed = () => {
  const { timeSelection, dates } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: [
      "machinedetail-sentinel-live-speed",
      timeSelection,
      machine!.machine,
    ],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload = {
    machineName: machine!.machine,
    timeSelection,
  };

  return useQuery<SpeedResult>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, SpeedResponse>({
        appKey,
        payload:
          timeSelection === "Custom"
            ? {
                ...payload,
                dateStart: dates?.dateStart,
                dateEnd: dates?.dateEnd,
              }
            : payload,
        url: "dig.c.machineDetail_thing/Services/getSpeedService",
      });

      if (!response.response) throw new Error(response.errorString);

      return SpeedResult.parse(response);
    },
    refetchInterval: timeSelection === "Custom" ? false : 1000 * 20,
  });
};
