import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type AvailableConditionMonitoringDataResponse =
  | ThingworxError
  | AvailableConditionMonitoringDataResult;

const AvailableConditionMonitoringDataResult = z.object({
  response: z.literal(true),
  availableAnalytics: z.array(z.string()),
});

export type AvailableConditionMonitoringDataResult = z.infer<
  typeof AvailableConditionMonitoringDataResult
>;

interface Payload {
  machineName: string;
}

export const useGetAvailableConditionMonitoringData = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<string[]>({
    queryKey: ["available-condition-monitoring-data", machine?.machine],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        AvailableConditionMonitoringDataResponse
      >({
        appKey,
        payload: {
          machineName: machine?.machine || "",
        },
        url: "dig.c.motorConditionMonitoring_thing/Services/getAvailableConditionMonitoringData",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse =
        AvailableConditionMonitoringDataResult.parse(response);
      return validatedResponse.availableAnalytics;
    },
  });
};
