import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { useCustomTimeRange } from "../store/useCustomTimeRange";
import { z } from "zod";

type StatesRejectsResponse = ThingworxError | StatesRejectsResult;
const StatesRejectsData = z.object({
  categories: z.array(z.string()),
  wasteCounterData: z.array(z.array(z.number())),
  totalCounterData: z.array(z.array(z.number())),
  stateLogData: z.array(
    z.object({
      x: z.number(),
      x2: z.number(),
      y: z.number(),
      color: z.string().optional(),
    }),
  ),
});

type StatesRejectsData = z.infer<typeof StatesRejectsData>;

const StatesRejectsResult = z.object({
  response: z.literal(true),
  data: StatesRejectsData,
});

type StatesRejectsResult = z.infer<typeof StatesRejectsResult>;

type Payload = {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
};

export const useGetStatesAndRejects = () => {
  const { machine } = useMachineContext();

  const { dateStart, dateEnd } = useCustomTimeRange();

  const { appKey } = useFirebaseContext();

  return useQuery<StatesRejectsData>({
    queryKey: ["states-counters", machine?.machine, dateStart, dateEnd],
    queryFn: async () => {
      const response = await FetchClient<Payload, StatesRejectsResponse>({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
        },
        url: "dig.c.fillerAdvancedAnalytics_thing/Services/getStatesCountersData",
      });

      if (!response.response) throw new Error(response.errorString);

      return StatesRejectsResult.parse(response).data;
    },
  });
};
