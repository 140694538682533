import { PropsWithChildren } from "react";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { LocalizationProvider, enUS } from "@mui/x-date-pickers-pro";

export const DatepickerLanguageProvider = ({ children }: PropsWithChildren) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale="en-US"
      localeText={
        enUS.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      {children}
    </LocalizationProvider>
  );
};
