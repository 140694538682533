import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Navigate, Route, Routes } from "react-router-dom";

import { LandingPage } from "@/features/LandingPage";
import { Overview } from "../pages/Overview/Overview";
import { MachineView } from "../pages/MachineView/MachineView";
import { MachineDetail } from "@/features/MachineDetail/MachineDetail";
import { MachineContextProvider } from "@/context/machine-context";
import { SentinelLiveView } from "@/features/MachineDetail/pages/Cyclic/views/SentinelLiveView";
import { SentinelHistoricView } from "@/features/MachineDetail/pages/Cyclic/views/SentinelHistoricView";
import { ProcessLiveView } from "@/features/MachineDetail/pages/Process/views/ProcessLiveView";
import { ProcessHistoricView } from "@/features/MachineDetail/pages/Process/views/ProcessHistoricView";
import { ProductionOverview } from "@/features/Diagnostic/pages/LYO/pages/Summary/ProductionOverview";
import { PhaseOverview } from "@/features/Diagnostic/pages/LYO/pages/Summary/PhaseOverview";
import { DiagnosticView } from "@/features/Diagnostic/pages/LYO/pages/Batch/DiagnosticView";
import { AdvancedView } from "@/features/Diagnostic/pages/LYO/pages/Batch/AdvancedView";
import { SummaryPage } from "@/features/Diagnostic/pages/LYO/pages/Summary/SummaryPage";
import { BatchPage } from "@/features/Diagnostic/pages/LYO/pages/Batch/BatchPage";
import { LyophylizerPage } from "@/features/Diagnostic/pages/LYO/pages/LyophylizerPage";
import { Alarms } from "@/features/Alarms";
import { RejectCauses } from "@/features/RejectCauses";
import { Jams } from "@/features/Jams";
import { AccessRequest } from "@/features/AccessRequest";
import { PhysicalMeasures } from "@/features/PhysicalMeasures";
import { Adjustments } from "@/features/Adjustments";
import { FillerAnalyticsScreen } from "@/features/Diagnostic/pages/FILLER";
import { ChangeOver } from "@/features/ChangeOver";
import { Materials } from "@/features/Materials";
import { UserSettings } from "@/features/UserSettings";
import { MachineInfo } from "@/features/MachineInfo";
import { AcoposTrak } from "@/features/Acopostrak";
import { AdvancedOverview } from "@/features/Diagnostic/pages/LYO/pages/Summary/AdvancedOverview";
import { MachinePerformance } from "@/features/MachinePerformance/MachinePerformance";
import { MachinePerformanceCyclic } from "@/features/MachinePerformance/pages/cyclic/MachinePerformanceCyclic";
import { MachinerPerformanceProcess } from "@/features/MachinePerformance/pages/process/MachinerPerformanceProcess";
import { BatchNavigator } from "@/features/BatchNavigator";
import { OverallLineEfficiencyScreen } from "@/features/OverallLineEfficiency/Pages/LineOverview";
import { LineDetailScreen } from "@/features/OverallLineEfficiency/Pages/LineDetail/LineDetail";
import { PredictiveAnalytics } from "@/features/PredictiveAnalytics";
import { EfficiencyStatesScreen } from "@/features/EfficiencyStates";
import { StateLog } from "@/features/StateLog";
import { CustomCharts } from "@/features/CustomCharts";
import { MachineSettings } from "@/features/MachineSettings/MachineSettings";
import { A4GateMonitoring } from "@/features/A4GateMonitoring";
import { Motors } from "@/features/Motors";
import { EfficiencyNavigatorHistoricView } from "@/features/EfficiencyNavigator/pages/HistoricView/EfficiencyNavigatorHistoricView";
import { EfficiencyNavigatorLive } from "@/features/EfficiencyNavigator/pages/LiveView/EfficiencyNavigatorLive";
import { Download } from "@/features/Download";
import { HistoricPage } from "@/features/OverallLineEfficiency/Pages/HistoricLineDetail/HistoricLineDetail";
import { AcceptanceTestHistoricView } from "@/features/AcceptanceTest/pages/HistoricView/AcceptanceTestHistoricView";
import { AcceptanceTestLiveScreen } from "@/features/AcceptanceTest/pages/LiveView/AcceptanceTestLive";
import { Events } from "@/features/Events";
import { Reports } from "@/features/Reports";
import { StopJustificationLiveView } from "@/features/StopJustification/pages/StopJustificationLiveView";
import { StopJustificationHistoricView } from "@/features/StopJustification/pages/StopJustificationHistoricView";
import { EventsLog } from "@/features/EventsLog";
import {
  ProtectedRoute,
  UserProtectedRoute,
} from "@/components/ProtectedRoute";
import { ConfigurableOeeViewHistoric } from "@/features/ConfigurableOee/pages/ConfigurableOeeViewHistoric";
import { ConfigurableOeeViewLive } from "@/features/ConfigurableOee/pages/ConfigurableOeeViewLive";
import { IsolatorAnalyticsScreen } from "@/features/Diagnostic/pages/ISO";
import { Intellecta } from "@/features/Intellecta";
import { useGetSentryExcludedUsers } from "@/hooks/useGetSentryExcludedUsers";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const SentinelRoutes = () => {
  const { data } = useGetSentryExcludedUsers();

  useEffect(() => {
    if (data) {
      const decodedUsers = data.users.map((user) => atob(user));
      // Aggiorna Sentry con la logica di esclusione
      Sentry.configureScope((scope) => {
        scope.addEventProcessor((event) => {
          if (
            event.user &&
            event.user.email &&
            decodedUsers.includes(event.user.email)
          ) {
            return null; // Blocca l'invio dei dati
          }
          return event;
        });
      });
    }
  }, [data]);

  return (
    <MachineContextProvider>
      <SentryRoutes>
        <Route path="/*" element={<MachineView />}>
          <Route
            path="home"
            element={<Navigate to={"/overview/home"} replace />}
          />
          <Route
            path="overall-line-efficiency"
            element={
              <Navigate to={"/overview/overall-line-efficiency"} replace />
            }
          />
          <Route path="" element={<Navigate to="/machine-detail" replace />} />
          <Route path="machine-detail" element={<MachineDetail />} />
          <Route
            path="machine-detail/sentinel/live"
            element={<SentinelLiveView />}
          />
          <Route
            path="machine-detail/sentinel/historic"
            element={<SentinelHistoricView />}
          />
          <Route
            path="machine-detail/process/live"
            element={<ProcessLiveView />}
          />
          <Route
            path="machine-detail/process/historic"
            element={<ProcessHistoricView />}
          />
          <Route
            path="state-log"
            element={
              <ProtectedRoute page="stateLog">
                <StateLog />
              </ProtectedRoute>
            }
          />
          <Route
            path="efficiency-navigator"
            element={
              <ProtectedRoute page="efficiencyNavigator">
                <EfficiencyNavigatorLive />
              </ProtectedRoute>
            }
          />
          <Route
            path="efficiency-navigator/historic"
            element={
              <ProtectedRoute page="efficiencyNavigator">
                <EfficiencyNavigatorHistoricView />
              </ProtectedRoute>
            }
          />
          <Route
            path="batch-navigator"
            element={
              <ProtectedRoute page="batchNavigator">
                <BatchNavigator />
              </ProtectedRoute>
            }
          />
          <Route
            path="acceptance-test"
            element={
              <ProtectedRoute page="acceptanceTest">
                <AcceptanceTestLiveScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="acceptance-test/historic"
            element={
              <ProtectedRoute page="acceptanceTest">
                <AcceptanceTestHistoricView />
              </ProtectedRoute>
            }
          />
          <Route
            path="configurable-oee"
            element={
              <ProtectedRoute page="configurable-oee">
                <ConfigurableOeeViewLive />
              </ProtectedRoute>
            }
          />
          <Route
            path="configurable-oee/historic"
            element={
              <ProtectedRoute page="configurable-oee">
                <ConfigurableOeeViewHistoric />
              </ProtectedRoute>
            }
          />
          <Route
            path="download"
            element={
              <ProtectedRoute page="download">
                <Download />
              </ProtectedRoute>
            }
          />
          <Route
            path="machine-performance"
            element={
              <ProtectedRoute page="machinePerformance">
                <MachinePerformance />
              </ProtectedRoute>
            }
          />
          <Route
            path="machine-performance/process"
            element={
              <ProtectedRoute page="machinePerformance">
                <MachinerPerformanceProcess />
              </ProtectedRoute>
            }
          />
          <Route
            path="machine-performance/cyclic"
            element={
              <ProtectedRoute page="machinePerformance">
                <MachinePerformanceCyclic />
              </ProtectedRoute>
            }
          />
          <Route path="machine-settings" element={<MachineSettings />} />
          <Route
            path="analytics/filler"
            element={
              <ProtectedRoute page="analytics-filler">
                <FillerAnalyticsScreen />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="analytics/isolator"
            element={
              <ProtectedRoute page="analytics-isolator">
                <IsolatorAnalyticsScreen />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="analytics/lyophilizer/*"
            element={
              <ProtectedRoute page="analytics-lyo">
                <LyophylizerPage />
              </ProtectedRoute>
            }
          >
            <Route path="summary/*" element={<SummaryPage />}>
              <Route
                path="production-overview"
                element={<ProductionOverview />}
              />
              <Route path="phase-overview" element={<PhaseOverview />} />
              <Route
                path="predictive-analytics-overview"
                element={<AdvancedOverview />}
              />
            </Route>

            <Route path="batch/*" element={<BatchPage />}>
              <Route path="diagnostic" element={<DiagnosticView />} />
              <Route path="predictive-analytics" element={<AdvancedView />} />
            </Route>
          </Route>
          <Route
            path="motors"
            element={
              <ProtectedRoute page="motors">
                <Motors />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="efficiency-states"
            element={
              <ProtectedRoute page="efficiencyStates">
                <EfficiencyStatesScreen />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="machine-info"
            element={
              <ProtectedRoute page="machineInfo">
                <MachineInfo />
              </ProtectedRoute>
            }
          />
          <Route
            path="events-log"
            element={
              <ProtectedRoute page="eventsLog">
                <EventsLog />
              </ProtectedRoute>
            }
          />
          <Route
            path="stop-justification"
            element={
              <ProtectedRoute page="stopJustification">
                <StopJustificationLiveView />
              </ProtectedRoute>
            }
          />
          <Route
            path="stop-justification/historic"
            element={
              <ProtectedRoute page="stopJustification">
                <StopJustificationHistoricView />
              </ProtectedRoute>
            }
          />
          <Route
            path="jams"
            element={
              <ProtectedRoute page="jams">
                <Jams />
              </ProtectedRoute>
            }
          />
          <Route
            path="physical-measures"
            element={
              <ProtectedRoute page="physicalMeasures">
                <PhysicalMeasures />
              </ProtectedRoute>
            }
          />
          <Route
            path="access-request"
            element={
              <ProtectedRoute page="accessRequest">
                <AccessRequest />
              </ProtectedRoute>
            }
          />
          <Route
            path="adjustment"
            element={
              <ProtectedRoute page="adjustment">
                <Adjustments />
              </ProtectedRoute>
            }
          />
          <Route
            path="alarms"
            element={
              <ProtectedRoute page="alarms">
                <Alarms />
              </ProtectedRoute>
            }
          />
          <Route
            path="reject-causes"
            element={
              <ProtectedRoute page="rejectCauses">
                <RejectCauses />
              </ProtectedRoute>
            }
          />
          <Route
            path="materials"
            element={
              <ProtectedRoute page="materials">
                <Materials />
              </ProtectedRoute>
            }
          />
          <Route
            path="change-over"
            element={
              <ProtectedRoute page="changeOver">
                <ChangeOver />
              </ProtectedRoute>
            }
          />
          <Route path="user/settings" element={<UserSettings />} />
          <Route
            path="smart-track"
            element={
              <ProtectedRoute page="smartTrack">
                <AcoposTrak />
              </ProtectedRoute>
            }
          />
          <Route
            path="predictive-analytics"
            element={
              <ProtectedRoute page="predictiveAnalytics">
                <PredictiveAnalytics />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="user/settings" element={<UserSettings />} />

        <Route path="/overview" element={<Overview />}>
          <Route index element={<Navigate to={"home"} replace />} />
          <Route path="home" element={<LandingPage />} />

          <Route
            path="overall-line-efficiency"
            element={
              <UserProtectedRoute page="overall-line-efficiency">
                <OverallLineEfficiencyScreen />
              </UserProtectedRoute>
            }
          />
          <Route
            path="overall-line-efficiency/line-detail"
            element={
              <UserProtectedRoute page="overall-line-efficiency">
                <LineDetailScreen />
              </UserProtectedRoute>
            }
          />
          <Route
            path="overall-line-efficiency/line-detail/historic"
            element={
              <UserProtectedRoute page="overall-line-efficiency">
                <HistoricPage />
              </UserProtectedRoute>
            }
          />
          <Route
            path="events"
            element={
              <UserProtectedRoute page="events">
                <Events />
              </UserProtectedRoute>
            }
          />
          <Route
            path="custom-charts"
            element={
              <UserProtectedRoute page="customCharts">
                <CustomCharts />
              </UserProtectedRoute>
            }
          />
          <Route
            path="a4gate-monitoring"
            element={
              <UserProtectedRoute page="a4GateMonitoring">
                <A4GateMonitoring />
              </UserProtectedRoute>
            }
          />
          <Route
            path="reports"
            element={
              <UserProtectedRoute page="reports">
                <Reports />
              </UserProtectedRoute>
            }
          />
          <Route path="intellecta" element={<Intellecta />} />
        </Route>
      </SentryRoutes>
    </MachineContextProvider>
  );
};
