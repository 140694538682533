import { Card } from "@/components/Layout/Card";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { DatiTabella as tableData } from "../api/useGetAcceptanceTestData";
import { TableRows } from "./LiveTable";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    flex: 0.6,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "timestampStop",
    headerName: "Stop",
    type: "dateTime",
    flex: 1.5,
    headerAlign: "left",
    align: "left",
    valueFormatter: ({ value }) => {
      return (
        value &&
        TimeHelpers.parseTimestampToString({ timestamp: +new Date(value) })
      );
    },
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "timestampRestart",
    headerName: "Restart",
    type: "dateTime",
    flex: 1.5,
    headerAlign: "left",
    align: "left",
    valueFormatter: ({ value }) => {
      return (
        value &&
        TimeHelpers.parseTimestampToString({ timestamp: +new Date(value) })
      );
    },
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "stateString",
    headerName: "Machine Status",
    type: "string",
    flex: 1.2,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "errorDescription",
    headerName: "Alarm",
    type: "string",
    flex: 1.5,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "comment",
    headerName: "Comment",
    flex: 4,
    headerAlign: "left",
    align: "left",
    type: "string",
  },
  {
    field: "duration",
    headerName: "Downtime",
    type: "number",
    flex: 1.2,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "who",
    headerName: "Who?",
    type: "number",
    flex: 1.2,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "deadTime",
    headerName: "Dead Time",
    type: "boolean",
    flex: 1.2,
    headerAlign: "left",
    align: "left",
  },
];
/**
 * @param description renderizza una tabella senza le azioni della colonna comment e who
 */

export const HistoricTable = ({ tableData }: { tableData: tableData[] }) => {
  const rows: GridRowsProp =
    tableData.length > 0
      ? tableData.map<TableRows>((row, index) => ({
          ...row,
          id: tableData.length - index,
          timestampStop: row.Stop - 7200000,
          timestampRestart: row.Restart - 7200000,
          stateString: row.StateString,
          errorDescription: row.ErrorDescription,
          comment: row.Comment,
          duration: TimeHelpers.parseDurationToString({
            duration: row.Duration * 1000,
          }),
          who: row.Who === 0 ? "related" : "unrelated",
        }))
      : [];

  return (
    <Card>
      <Box height={400} width={"100%"}>
        <DataGridPremium
          slots={{ toolbar: GridToolbar }}
          columns={columns}
          columnVisibilityModel={{
            deadTime: false,
          }}
          rows={rows}
        />
      </Box>
    </Card>
  );
};
