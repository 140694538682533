import { useState } from "react";
import { Drawer, IconButton, Box, Toolbar, Typography } from "@mui/material";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { Loader } from "@/components/Layout/Loader";
import { useGetUserConfiguration } from "./api/useGetUserConfiguration";
import { IntellectaNavBar } from "./components/ui/IntellectaNavBar";
import { ChatPanel } from "./components/ChatPanel";
import { LeftDrawer } from "./components/LeftDrawer";
import { RightDrawer } from "./components/RightDrawer";
import { InfoDialog } from "./components/InfoDialog";
import { VersionMenu } from "./components/LeftDrawer/VersionMenu";
import { NoVersionsMessage } from "./components/ChatPanel/NoVersionsMessage";

const LEFT_DRAWER_WIDTH = 270;
const RIGHT_DRAWER_WIDTH = 330;

export const Intellecta = () => {
  const [userInput, setUserInput] = useState("");
  const [isShownAlert, setIsShownAlert] = useState(true);
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(true);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(true);
  const { isLoading, data } = useGetUserConfiguration();

  if (isLoading || !data) {
    return <Loader isLoading />;
  }

  const toggleLeftDrawer = () => {
    setIsLeftDrawerOpen(!isLeftDrawerOpen);
  };

  const toggleRightDrawer = () => {
    setIsRightDrawerOpen(!isRightDrawerOpen);
  };

  return (
    <>
      <InfoDialog isOpen={isShownAlert} close={() => setIsShownAlert(false)} />
      <IntellectaNavBar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleLeftDrawer}
          edge="start"
          sx={{ mr: 2 }}
        >
          {isLeftDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
        <Typography variant="h6" noWrap>
          <VersionMenu />
        </Typography>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleRightDrawer}
          edge="end"
          sx={{ ml: "auto" }}
        >
          {isRightDrawerOpen ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </IntellectaNavBar>
      <Box sx={{ height: "calc(100vh - 64px)" }}>
        <Drawer
          variant="persistent"
          open={isLeftDrawerOpen}
          sx={{
            width: LEFT_DRAWER_WIDTH,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: LEFT_DRAWER_WIDTH,
              boxSizing: "border-box",
              marginLeft: "64px",
            },
          }}
        >
          <Toolbar />
          <LeftDrawer
            config={data}
            onPromptClick={(prompt) => setUserInput(prompt)}
          />
        </Drawer>

        <Box
          sx={{
            display: "flex",
            height: "100%",
            transition: (theme) =>
              theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            marginLeft: isLeftDrawerOpen ? `${LEFT_DRAWER_WIDTH}px` : 0,
            marginRight: isRightDrawerOpen ? `${RIGHT_DRAWER_WIDTH}px` : 0,
          }}
        >
          {data.versions.length > 0 ? (
            <ChatPanel userInput={userInput} setUserInput={setUserInput} />
          ) : (
            <NoVersionsMessage />
          )}
        </Box>

        <Drawer
          variant="persistent"
          anchor="right"
          open={isRightDrawerOpen}
          sx={{
            width: RIGHT_DRAWER_WIDTH,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: RIGHT_DRAWER_WIDTH,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <RightDrawer
            config={data}
            onUsePrompt={(prompt) => setUserInput(prompt)}
          />
        </Drawer>
      </Box>
    </>
  );
};
