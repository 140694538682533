import { useState } from "react";
// import { useSearchParams } from "react-router-dom";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { ArrowForward, CheckRounded } from "@mui/icons-material";
import { IntellectaVersion } from "../../types";
import { intellectaVersionStore } from "../../store/intellecta-version";
import { useTranslate } from "@/i18n/config";
import { chatStore } from "../../store/chat-store";

export const VersionMenu = () => {
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [, setSearchParams] = useSearchParams();
  const { version, setVersion, availableVersions } = intellectaVersionStore();
  const { clearChat } = chatStore();

  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const options: {
    value: IntellectaVersion;
    label: string;
  }[] = availableVersions.map((availableVersion) => {
    return {
      value: availableVersion,
      label: translate(`intellecta.version_${availableVersion}`),
    };
  });

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLElement>,
    version: IntellectaVersion,
  ) => {
    setVersion(version);
    setAnchorEl(null);
    clearChat();
    // setSearchParams({});
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedOptions = options.find((option) => option.value === version);

  if (!selectedOptions) {
    return null;
  }

  return (
    <Box>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ bgcolor: "background.paper" }}
      >
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="Intellecta version"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{
            border: 1,
            borderColor: "divider",
            borderRadius: 2,
          }}
        >
          <ListItemText
            sx={{ mr: 1 }}
            primary={selectedOptions.label}
            // primaryTypographyProps={{ variant: "h6" }}
          />
          <ArrowForward />
        </ListItemButton>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            disabled={option.value === version}
            // selected={option.value === version}
            onClick={(event) => handleMenuItemClick(event, option.value)}
            sx={{
              width: 234,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {option.label}
            {option.value === version && <CheckRounded />}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
// import { useState } from "react";
// import { useSearchParams } from "react-router-dom";
// import {
//   Box,
//   List,
//   ListItemButton,
//   ListItemText,
//   Menu,
//   MenuItem,
// } from "@mui/material";
// import { ArrowDownward } from "@mui/icons-material";
// import { IntellectaVersion } from "../../types";
// import { intellectaVersionStore } from "../../store/intellecta-version";
// import { useTranslate } from "@/i18n/config";

// export const VersionMenu = () => {
//   const translate = useTranslate();
//   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

//   const [, setSearchParams] = useSearchParams();

//   const { version, setVersion, availableVersions } = intellectaVersionStore();

//   const open = Boolean(anchorEl);
//   const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const options: {
//     value: IntellectaVersion;
//     label: string;
//   }[] = availableVersions.map((availableVersion) => {
//     return {
//       value: availableVersion,
//       label: translate(`intellecta.version_${availableVersion}`),
//     };
//   });

//   const handleMenuItemClick = (
//     _: React.MouseEvent<HTMLElement>,
//     version: IntellectaVersion,
//   ) => {
//     setVersion(version);
//     setAnchorEl(null);
//     setSearchParams({});
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const selectedOptions = options.find((option) => option.value === version);

//   if (!selectedOptions) {
//     return null;
//   }

//   return (
//     <Box>
//       <List
//         component="nav"
//         aria-label="Device settings"
//         sx={{ bgcolor: "background.paper" }}
//       >
//         <ListItemButton
//           id="lock-button"
//           aria-haspopup="listbox"
//           aria-controls="lock-menu"
//           aria-label="Intellecta version"
//           aria-expanded={open ? "true" : undefined}
//           onClick={handleClickListItem}
//           sx={{
//             border: 1,
//             borderColor: "divider",
//             borderRadius: 2,
//           }}
//         >
//           <ListItemText
//             primary="Intellecta"
//             primaryTypographyProps={{ variant: "h6" }}
//             secondary={selectedOptions.label}
//           />

//           <ArrowDownward />
//         </ListItemButton>
//       </List>
//       <Menu
//         id="lock-menu"
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         MenuListProps={{
//           "aria-labelledby": "lock-button",
//           role: "listbox",
//         }}
//       >
//         {options.map((option) => (
//           <MenuItem
//             key={option.value}
//             disabled={option.value === version}
//             selected={option.value === version}
//             onClick={(event) => handleMenuItemClick(event, option.value)}
//             sx={{ width: 234 }}
//           >
//             {option.label}
//           </MenuItem>
//         ))}
//       </Menu>
//     </Box>
//   );
// };
