import { Card } from "@/components/Layout/Card";
import {
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Box,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { useState } from "react";
import {
  AcceptanceTestDataResult,
  DatiTabella,
} from "../api/useGetAcceptanceTestData";
import { useSetWho } from "../api/useSetWho";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderEditCellParams,
  DataGridPremium,
  GridRenderCellParams,
  GridToolbar,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { useSetComment } from "../api/useSetComment";
import { useAcceptanceTestContext } from "../context/acceptanceTest-context";
import { DateTime } from "luxon";

export interface TableRows {
  id: number;
  timestampStop: number;
  timestampRestart: number;
  stateString: string;
  errorDescription: string;
  comment: string;
  duration: string;
  who: string;
  deadTime?: boolean;
  dataId?: number;
  isDisabled?: boolean;
}
// *  funzione che trasforma e ritorna "rows"
function transformedRows({
  tableData,
  satConfiguration,
}: {
  tableData: DatiTabella[];
  satConfiguration: "Blocked" | "Free";
}): TableRows[] {
  const rows =
    tableData.length > 0
      ? tableData.map<TableRows>((row, index) => ({
          id: tableData.length - index,
          timestampStop: row.Stop - 7200000,
          timestampRestart: row.Restart - 7200000,
          stateString: row.StateString,
          errorDescription: row.ErrorDescription,
          comment: row.Comment,
          duration: TimeHelpers.parseDurationToString({
            duration: row.Duration * 1000,
          }),
          who: row.Who === 0 ? "related" : "unrelated",
          deadTime: row.deadTime,
          dataId: row.Id,
          isDisabled: disabledHandler({
            stateString: row.StateString,
            stopTime: row.Stop,
            satConfiguration,
          }),
        }))
      : [];
  return rows;
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    flex: 0.5,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "timestampStop",
    headerName: "Stop",
    type: "dateTime",
    flex: 1.3,
    headerAlign: "left",
    align: "left",
    valueFormatter: ({ value }) => {
      return (
        value &&
        TimeHelpers.parseTimestampToString({ timestamp: +new Date(value) })
      );
    },
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "timestampRestart",
    headerName: "Restart",
    type: "dateTime",
    flex: 1.3,
    headerAlign: "left",
    align: "left",
    valueFormatter: ({ value }) => {
      return (
        value &&
        TimeHelpers.parseTimestampToString({ timestamp: +new Date(value) })
      );
    },
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "stateString",
    headerName: "Machine Status",
    type: "string",
    flex: 1.2,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "errorDescription",
    headerName: "Alarm",
    type: "string",
    flex: 1.2,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "comment",
    headerName: "Comment",
    flex: 3,
    headerAlign: "left",
    align: "left",
    type: "string",
    editable: true,
    renderCell: (params: GridRenderEditCellParams) => (
      <CustomSaveCommentComponent {...params} />
    ),
    renderEditCell: (params: GridRenderEditCellParams) => (
      <CustomEditComponent params={{ ...params }} />
    ),
  },
  {
    field: "duration",
    headerName: "Downtime",
    type: "number",
    flex: 1.2,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "who",
    headerName: "Who?",
    type: "number",
    flex: 1.2,
    headerAlign: "left",
    align: "left",
    renderCell: (params: GridRenderCellParams) => (
      <CustomWhoComponent {...params} />
    ),
  },
  {
    field: "deadTime",
    headerName: "Dead Time",
    type: "boolean",
    flex: 1.2,
    headerAlign: "left",
    align: "left",
  },
];

export const LiveTable = ({
  tableData,
}: {
  tableData: AcceptanceTestDataResult["DatiTabella"];
}) => {
  const { satConfiguration } = useAcceptanceTestContext();
  const rows = transformedRows({ tableData, satConfiguration });
  return (
    <Card>
      <Box height={400} width={"100%"}>
        <DataGridPremium
          slots={{ toolbar: GridToolbar }}
          columns={columns}
          columnVisibilityModel={{
            deadTime: false,
          }}
          rows={rows}
        />
      </Box>
    </Card>
  );
};

function CustomSaveCommentComponent(props: GridRenderEditCellParams) {
  const { id, field, value, row } = props;
  const apiRef = useGridApiContext();
  return (
    <Stack direction="row" justifyContent="space-between" width="100%" key={id}>
      <Typography>{value}</Typography>
      <GridActionsCellItem
        disabled={row.isDisabled ? true : false}
        key={id}
        label="edit"
        icon={<EditIcon />}
        onClick={() => {
          apiRef.current.startCellEditMode({ id, field });
        }}
      />
    </Stack>
  );
}

const CustomEditComponent = ({
  params,
}: {
  params: GridRenderEditCellParams;
}) => {
  const { mutate: setComment } = useSetComment();
  const { id, value: valueProp, field, row } = params;
  const [value, setValue] = useState(valueProp);
  const apiRef = useGridApiContext();

  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <TextField
        type="text"
        fullWidth
        value={value}
        InputProps={{ inputProps: { min: 0, sx: { padding: 0.5 } } }}
        onChange={(e) => {
          const newValue = e.target.value;
          apiRef.current.setEditCellValue({
            id,
            field,
            value: newValue,
            debounceMs: 200,
          });
          setValue(newValue);
        }}
      />
      <Stack direction="row" gap={1}>
        <GridActionsCellItem
          label="save"
          icon={<SaveIcon />}
          onClick={() => {
            setComment({ Comment: value, Id: row.dataId });
            apiRef.current.stopCellEditMode({ id, field });
          }}
        />
        <GridActionsCellItem
          label="cancel"
          icon={<CancelIcon />}
          onClick={() => apiRef.current.stopCellEditMode({ id, field })}
        />
      </Stack>
    </Stack>
  );
};

function CustomWhoComponent(props: GridRenderCellParams) {
  const { mutate: setWho } = useSetWho();

  const { value, row } = props;
  const [whoValue, setWhoValue] = useState<string>(value);
  const { isDisabled } = row;
  const handleChange = (event: SelectChangeEvent) => {
    const changedValue = event.target.value;
    setWhoValue(event.target.value);
    // * set the selection
    setWho({
      id: row.dataId.toString(),
      who: changedValue === "related" ? "0" : "1",
    });
  };
  return (
    <FormControl
      sx={{ m: 0, minWidth: 130 }}
      size="small"
      disabled={isDisabled ? true : false}
    >
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={whoValue}
        renderValue={(value: string) => value}
        onChange={handleChange}
      >
        <MenuItem value={"related"}>related</MenuItem>
        <MenuItem value={"unrelated"}>unrelated</MenuItem>
      </Select>
    </FormControl>
  );
}

const disabledHandler = ({
  stateString,
  stopTime,
  satConfiguration,
}: {
  stateString: string | null;
  stopTime: number;
  satConfiguration: "Blocked" | "Free";
}) => {
  if (
    stateString === "Missing Downstream" ||
    stateString === "Missing Upstream"
  )
    true;
  // TODO: manca la condizione di utc = machine o user
  return satConfiguration === "Blocked" &&
    DateTime.now().toMillis() - (stopTime - 7200000) > 1000 * 60 * 15
    ? true
    : false;
};
