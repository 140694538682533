import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import {
  Autocomplete,
  Button,
  Skeleton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { LineItem } from "./components/LineItem";
import { PlantDesign } from "../../components/PlantDesign";
import { OeeLineOverviewContextProvider } from "./context/oee-line-context";
import { Card } from "@/components/Layout/Card";
import {
  EditOutlined,
  QueryStatsSharp,
  SearchOutlined,
} from "@mui/icons-material";
import { NavButton } from "@/components/NavButton";
import { OverviewNavBar } from "@/pages/Overview/layout/OverviewNavBar/OverviewNavBar";
import { UserRole } from "@/context/firebase-context";
import { useIsAllowed } from "@/hooks/useIsAllowed";
import { Edit } from "../Edit";
import {
  Line,
  useGetUserProductionLines,
} from "../../api/useGetUserProductionLines";
import { useState } from "react";
import { getLineAmount } from "../../utils/getLineAmount";
import { TogglePerformanceView } from "../../components/TogglePerformanceView";
import { useOeeLineContextState } from "./context/useOeeLineContextState";
import { useOeeLineContextDispatch } from "./context/useOeeLineContextDispatch";
import { AutomaticConfigureLineButton } from "../../components/EditAutomaticLineConfigurationButton/AutomaticConfigureLineButton";
import { ManualConfigureLineButton } from "../../components/EditManualLineConfigurationButton/ManualConfigureLineButton";
import { EditAutomaticLineConfigurationButton } from "../../components/EditAutomaticLineConfigurationButton/EditAutomaticLineConfigurationButton";
import { EditManualLineConfigurationButton } from "../../components/EditManualLineConfigurationButton/EditManualLineConfigurationButton";
import { LineStateCategories } from "./components/LineStateCategories";

interface LineOption {
  line_id: number;
  line_name: string;
}

const OverallLineEfficiency = () => {
  const state = useOeeLineContextState();
  const dispatch = useOeeLineContextDispatch();
  const ctx = localStorage.getItem("ole-filter-line");
  const [filterByState, setFilterByState] = useState<string | null>(null);
  const [filterBySearch, setFilterBySearch] = useState<{
    line_id: number;
    line_name: string;
  } | null>(ctx ? JSON.parse(ctx) : null);
  const { data, isLoading } = useGetUserProductionLines();

  const lines = data?.lines ?? [];

  const linesOptions = lines
    .filter((line) => filterByState === null || line.state === filterByState)
    .map((line) => ({ line_id: line.line_id, line_name: line.line_name }));

  const linesElements = lines
    .filter((line) => filterByState === null || line.state === filterByState)
    .filter(
      (line) =>
        filterBySearch === null || line.line_id === filterBySearch.line_id,
    )
    .map((line) => (
      <LineItem
        key={line.line_id}
        line={line}
        active={state.selectedLine?.line_id === line.line_id}
        onClick={(line) => dispatch({ type: "select line", line })}
        //? qui si apre modale configurazione, io invece voglio aprire line editor
        notConfiguredComponent={
          state.view_mode === "automatic" ? (
            <AutomaticConfigureLineButton line={line} />
          ) : (
            <ManualConfigureLineButton line={line} />
          )
        }
      />
    ));

  const producingAmount = getLineAmount(lines, "Producing");
  const notProducingAmount = getLineAmount(lines, "Not Producing");
  const scheduledDowntimeAmount = getLineAmount(lines, "Scheduled Downtime");
  const idleAmount = getLineAmount(lines, "Idle");
  const notConfiguredAmount = getLineAmount(lines, "Not Configured");
  const totalAmount = data?.lines.length ?? 0;

  return (
    <>
      <OverviewNavBar>
        <ToggleButtonGroup
          color="primary"
          value={state.view_mode}
          exclusive
          size="small"
          onChange={(_, value) =>
            value !== null &&
            dispatch({
              type: "set view mode",
              view_mode: value,
            })
          }
          aria-label="line view mode"
        >
          <ToggleButton size="small" value="automatic">
            Automatic
          </ToggleButton>
          <ToggleButton size="small" value="manual">
            Manual
          </ToggleButton>
        </ToggleButtonGroup>
        {state.view_mode === "automatic" ? (
          <SelectTimeSelection
            exclude={[
              "Custom",
              "TwoWeeks",
              "Month",
              "ThreeMonths",
              "SixMonths",
              "Year",
              "Batch",
            ]}
          />
        ) : null}
      </OverviewNavBar>
      <OverviewContentSection
        linesOptions={linesOptions}
        lineItems={linesElements}
        onSelectLine={(selectedLine) => setFilterBySearch(selectedLine)}
        isLoading={isLoading}
        selection={filterBySearch}
        stateFilters={
          <LineStateCategories
            total={totalAmount}
            idleAmount={idleAmount}
            producingAmount={producingAmount}
            notProducingAmount={notProducingAmount}
            downtimeAmount={scheduledDowntimeAmount}
            notConfiguredAmount={notConfiguredAmount}
            activeState={filterByState}
            onStateSelected={(state) =>
              setFilterByState((prevState) => {
                return prevState === state ? null : state;
              })
            }
          />
        }
      />
    </>
  );
};

const OverviewContentSection = ({
  linesOptions,
  stateFilters,
  onSelectLine,
  lineItems,
  isLoading = false,
  selection,
}: {
  linesOptions: LineOption[];
  stateFilters: React.ReactNode;
  onSelectLine: (selectedLine: LineOption | null) => void;
  lineItems?: React.ReactNode;
  isLoading?: boolean;
  selection: LineOption | null;
}) => {
  const state = useOeeLineContextState();

  return (
    <Stack flexDirection="column" gap={2}>
      {stateFilters}
      <Grid2 container spacing={2} flex={1}>
        {/* sidebar */}
        <Grid2 xs={12} xl={3}>
          <Card
            sx={{
              padding: 1,
            }}
          >
            <Autocomplete
              sx={{
                padding: 1,
              }}
              autoComplete
              disablePortal
              id="search-line"
              isOptionEqualToValue={(option, value) =>
                option.line_id === value?.line_id
              }
              getOptionLabel={(option) => option.line_name}
              options={linesOptions}
              value={selection} // Valore controllato
              onChange={(_, value, reason) => {
                if (reason === "selectOption") {
                  onSelectLine(value);
                  localStorage.setItem(
                    "ole-filter-line",
                    JSON.stringify(value),
                  );
                }
                if (reason === "clear") {
                  onSelectLine(null);
                  localStorage.removeItem("ole-filter-line");
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.line_id}>
                    {option.line_name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <SearchOutlined />,
                  }}
                  label="Search Line"
                />
              )}
            />
            <Stack
              gap={2}
              direction={{
                xs: "row",
                xl: "column",
              }}
              overflow="auto"
              maxHeight="calc(100vh - 250px)"
              p={1}
            >
              {isLoading ? <LineLoader /> : lineItems}
            </Stack>
          </Card>
        </Grid2>

        <Grid2 flex={1}>
          {state.selectedLine ? (
            <PlantViewerController
              line={state.selectedLine}
              key={state.selectedLine.line_id}
            />
          ) : null}
        </Grid2>
      </Grid2>
    </Stack>
  );
};

const LineLoader = () => {
  const skeletons = Array.from({ length: 10 }).map((_, i) => (
    <Skeleton
      key={i}
      variant="rectangular"
      height={180}
      sx={{
        borderRadius: 1,
      }}
    />
  ));

  return <>{skeletons}</>;
};

const PlantViewerController = ({ line }: { line: Line }) => {
  const { editMode, view_mode } = useOeeLineContextState();
  const dispatch = useOeeLineContextDispatch();

  const canEdit = useIsAllowed([
    UserRole.USER_ADMIN,
    UserRole.SUPER_USER,
    UserRole.SUPER_USER_SENTINEL,
  ]);

  if (editMode) {
    return (
      <Card>
        <Edit
          initialConfig={{
            nodes: line.line_configuration?.nodes ?? [],
            edges: line.line_configuration?.edges ?? [],
          }}
        />
      </Card>
    );
  }

  if (!line.line_configuration && canEdit) {
    return (
      <Card>
        <Stack textAlign="center" gap={4}>
          <Typography variant="h6">
            This production line has not been configured yet.
          </Typography>
          <Typography variant="body1">
            To customize and set up the line according to your requirements,
            please click the 'Configure layout' button below.
          </Typography>
          <Button
            variant="outlined"
            size="large"
            sx={{
              width: "fit-content",
              alignSelf: "center",
            }}
            onClick={() => dispatch({ type: "enable edit mode" })}
            endIcon={<EditOutlined />}
          >
            Configure layout
          </Button>
        </Stack>
      </Card>
    );
  }

  if (!line.line_configuration && !canEdit) {
    return (
      <div>
        No data is found, please contact one of the administrator to create a
        new configuration
      </div>
    );
  }

  if (!line.line_configuration) {
    return <div>No data is found</div>;
  }

  return (
    <Card>
      <Stack gap={2}>
        <Stack
          gap={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack gap={2} direction="row">
            {line.is_configured ? (
              <NavButton
                to="line-detail"
                text="Open line detail"
                startIcon={<QueryStatsSharp />}
                state={{ line: line, view_mode }}
                replace
              />
            ) : view_mode === "automatic" ? (
              <EditAutomaticLineConfigurationButton
                line={line}
                configureFrom="plantView"
              />
            ) : (
              <EditManualLineConfigurationButton
                line={line}
                configureFrom="plantView"
              />
            )}
            {canEdit ? (
              <Button
                variant="outlined"
                startIcon={<EditOutlined />}
                onClick={() => dispatch({ type: "enable edit mode" })}
              >
                Edit
              </Button>
            ) : null}
          </Stack>

          <TogglePerformanceView />
        </Stack>
        <PlantDesign config={line.line_configuration} lineId={line.line_id} />
      </Stack>
    </Card>
  );
};

export const OverallLineEfficiencyScreen = () => {
  return (
    <OeeLineOverviewContextProvider>
      <OverallLineEfficiency />
    </OeeLineOverviewContextProvider>
  );
};
