import { AlertSchema } from "../api/useGetEventsData/getAlerts";
import { Button, Typography } from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { ArrowCircleUpOutlined, DeleteOutline } from "@mui/icons-material";
import { EventValues } from "../types";
import { useUpdateAlert } from "../api/useUpdateAlert";
import { useDeleteAlert } from "../api/useDeleteAlert";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Modal } from "@/components/Modal";
import { useTranslate } from "@/i18n/config";

const getValuesText = (values: EventValues): string => {
  if (Object.keys(values).length === 1) {
    return `${values.value_1}`;
  } else {
    return `between ${values.value_1} and ${values.value_2}`;
  }
};

export const Event = ({
  info,
  isUpgradable,
}: {
  info: AlertSchema;
  isUpgradable: boolean;
}) => {
  const {
    machine,
    ruleDescription,
    timestamp,
    account,
    priority,
    data,
    stateOfTicket,
    id,
  } = info;
  const { mutate: upgradeAlert } = useUpdateAlert();
  const { mutate: deleteAlert } = useDeleteAlert();
  const {
    open: openDeleteModal,
    close: closeDeleteModal,
    isOpen: isOpenDeleteModal,
  } = useDisclosure();
  const translate = useTranslate();

  let eventType = "";
  data.forEach((limit) => {
    const {
      isActiveDuration,
      duration,
      property,
      name,
      propertyNameClient,
      alertType,
      values,
      rate,
    } = limit;

    const formatted = new Date(duration * 1000).toISOString().slice(11, 19);
    if (property === "firstAlarm" || name === "firstAlarm") {
      const durationText =
        formatted == "00:00:00" ? "" : `with duration of ${formatted}`;

      eventType += `${propertyNameClient} ${alertType} ${getValuesText(
        values,
      )} ${durationText}`;
    } else {
      if (isActiveDuration) {
        eventType += `${propertyNameClient} ${alertType} ${getValuesText(
          values,
        )} and lasted for at least ${formatted} ${rate} times`;
      } else {
        eventType += `${propertyNameClient} ${alertType} ${getValuesText(
          values,
        )}`;
      }
    }
  });

  const handleUpgrade = () => {
    upgradeAlert({ id, stateOfTicket });
  };

  const handleDelete = () => {
    deleteAlert({ id });
  };

  return (
    <>
      {isOpenDeleteModal && (
        <Modal
          titleContent={translate("events.want_to_delete_event")}
          bodyContent={translate("events.confirm_delete_event", {
            event: ruleDescription,
          })}
          open={isOpenDeleteModal}
          actions={
            <>
              <Button variant="outlined" onClick={closeDeleteModal}>
                {translate("actions.cancel")}
              </Button>
              <Button variant="outlined" onClick={handleDelete}>
                {translate("actions.confirm")}
              </Button>
            </>
          }
        />
      )}
      <Card sx={{ display: "flex", gap: 2 }}>
        {isUpgradable && (
          <ArrowCircleUpOutlined
            sx={{ cursor: "pointer", flex: 0.5 }}
            onClick={handleUpgrade}
          />
        )}
        <Typography sx={{ flex: 2 }}>{machine}</Typography>
        <Typography sx={{ flex: 3 }}>{ruleDescription}</Typography>
        <Typography sx={{ flex: 3 }}>
          {new Date(timestamp).toLocaleString()}
        </Typography>
        <Typography sx={{ flex: 5 }}>{eventType}</Typography>
        <Typography sx={{ flex: 3 }}>{account}</Typography>
        <Typography sx={{ flex: 1 }}>{priority}</Typography>
        <DeleteOutline
          sx={{ cursor: "pointer", flex: 0.5 }}
          onClick={openDeleteModal}
        />
      </Card>
    </>
  );
};
