import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useFirebaseContext } from "@/context/firebase-context";
import { config, Config } from "../types";
import { intellectaClient } from "./intellectaClient";

export const useGetUserConfiguration = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useQuery({
    queryKey: ["intellecta-config", email],
    queryFn: async () => {
      const response = await intellectaClient<{ user: string }, Config>({
        url: "/user/config",
        payload: { user: email },
      });

      return config.parse(response);
    },
    staleTime: 1000 * 60 * 60,
    onError: () => {
      toast.error("An error occurred");
    },
  });
};
