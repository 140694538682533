import { DisconnectedCategory } from "@/components/CustomIcons/disconnectedCategory";
import { LineCategory } from "@/components/CustomIcons/lineCategory";
import { MaintenanceCategory } from "@/components/CustomIcons/maintenanceCategory";
import { NotProducingCategory } from "@/components/CustomIcons/notProducingCategory";
import { ProductionCategory } from "@/components/CustomIcons/productionCategory";
import { SetupPreProdCategory } from "@/components/CustomIcons/setupPreProdCategory";
import { StateCategory } from "../api/CyclicLive/useGetSentinelLiveData";

export const CategoryIcon = ({
  category,
  width = 48,
  height = 48,
  fill,
}: {
  category: StateCategory;
  width?: number;
  height?: number;
  fill?: string;
}) => {
  switch (category) {
    case "Disconnected":
      return <DisconnectedCategory width={width} height={height} fill={fill} />;
    case "Line":
      return <LineCategory width={width} height={height} fill={fill} />;
    case "Intentional No Production":
      return <MaintenanceCategory width={width} height={height} fill={fill} />;
      break;
    case "Not Producing":
      return <NotProducingCategory width={width} height={height} fill={fill} />;
    case "Producing":
      return <ProductionCategory width={width} height={height} fill={fill} />;
    case "Setup Pre-Production":
      return <SetupPreProdCategory width={width} height={height} fill={fill} />;
    default:
      throw new Error("Invalid category");
  }
};
