import { Stack, Typography, useTheme } from "@mui/material";
import { useFirebaseContext } from "../src/context/firebase-context";
import { Loader } from "./components/Layout/Loader";
import { useCheckMobileScreen } from "./hooks/useCheckMobileScreen";
import { Login } from "./pages/Login/Login";
import { SentinelRoutes } from "./routes/SentinelRoutes";
import "./styles/global.style.css";
import { useCheckIfMaintenance } from "./hooks/useCheckIfMaintenance";
import { SentinelLogo } from "./components/Icons/SentinelLogo";

export const App = () => {
  const { user, showLoader, appKey } = useFirebaseContext();
  const { isMaintenance } = useCheckIfMaintenance();
  const isMobile = useCheckMobileScreen();
  const { palette } = useTheme();

  if (isMaintenance) {
    return (
      <Stack
        height="100vh"
        width="100vw"
        p={12}
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <SentinelLogo dimension={120} color={palette.primary.main} />
        <Typography variant="h5" align="center">
          Sentinel is currently under maintenance.
        </Typography>
      </Stack>
    );
  }

  if (isMobile) {
    return (
      <Stack
        height="100vh"
        width="100vw"
        p={12}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h5" align="center">
          Sentinel does not support mobile devices with small screens. We
          recommend that you use a device with a larger screen to access the
          application.
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      {showLoader ? (
        <Loader isLoading={showLoader} />
      ) : (
        (() => {
          return (
            <>
              {user && appKey === "" && <Loader isLoading={showLoader} />}
              {user && appKey !== "" && <SentinelRoutes />}
              {user === null && <Login />}
            </>
          );
        })()
      )}
    </>
  );
};
