import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { useRaisedShadow } from "@/hooks/use-raised-shadow";
import { DragHandle } from "@mui/icons-material";
import {
  Box,
  Tab,
  Tabs,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  Switch,
} from "@mui/material";
import {
  motion,
  Reorder,
  useDragControls,
  useMotionValue,
} from "framer-motion";
import { useState } from "react";
import {
  useGetAssetComparisonSubphases,
  Asset,
  AssetComparisonSubphases,
} from "../api/useGetAssetComparisonSubphases";
import { useLyoContext } from "../context/lyo-context";
import { LightContainer } from "./Layouts/LightContainer";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { useGetAssetDistanceCoefficient } from "../api/useGetAssetDistanceCoefficient";
import { getColor } from "@/components/highcharts/gant/Gant";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { z } from "zod";

const DATE_FORMAT_STRING = "DD HH:mm";

export const DistanceCoefficient = ({
  phase_id,
}: {
  phase_name: string;
  phase_id: number;
}) => {
  const translate = useTranslate();
  const [{ batches }] = useLyoContext();

  const { data } = useGetAssetComparisonSubphases({
    batches: batches,
    phase_id,
  });

  return (
    <>
      {!data || data.length === 0 ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {translate("user_feedback.no_data_to_display")}
        </Box>
      ) : (
        <SubphaseNavigation subphases={data} phase_id={phase_id} />
      )}
    </>
  );
};

function SubphaseNavigation({
  subphases,
  phase_id,
}: {
  subphases: AssetComparisonSubphases[];
  phase_id: number;
}) {
  const [selectedSubphase, setSelectedSubphase] =
    useState<AssetComparisonSubphases>(subphases[0]);

  const [value, setValue] = useState<false | number>(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSelectedSubphase(subphases[newValue]);
  };
  return (
    <>
      <Box
        sx={{
          paddingBottom: 2,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {subphases.map((subphase) => (
            <Tab label={subphase.subphase_name} key={subphase.subphase_name} />
          ))}
        </Tabs>
      </Box>
      {selectedSubphase && (
        <AssetComparisonSubphaseNavigation
          key={selectedSubphase.subphase_name}
          assets={selectedSubphase.assets.filter(
            (asset) => asset.asset_chart_type === "linechart",
          )}
          phase_id={phase_id}
          subphase_name={selectedSubphase.subphase_name}
        />
      )}
    </>
  );
}

function AssetComparisonSubphaseNavigation({
  assets,
  phase_id,
  subphase_name,
}: {
  assets: AssetComparisonSubphases["assets"];
  phase_id: number;
  subphase_name: string;
}) {
  const translate = useTranslate();
  const [selectedAssets, setSelectedAssets] = useState<number[]>([]);

  const [isDragActive, setIsDragActive] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAssets((prev) => {
      if (prev.includes(Number(event.target.value))) {
        return prev.filter((asset) => asset !== Number(event.target.value));
      }
      return [...prev, Number(event.target.value)];
    });
  };

  return (
    <Stack
      direction={{
        md: "row",
        xs: "column",
      }}
      spacing={2}
      width="100%"
    >
      <FormControl
        sx={{
          width: {
            md: 300,
            xs: "100%",
          },
          minWidth: 300,
          height: "fit-content",
          padding: 2,
        }}
        component={LightContainer}
        variant="outlined"
        fullWidth
      >
        <FormGroup>
          {assets.map((asset) => (
            <FormControlLabel
              key={asset.asset_id}
              control={
                <Checkbox
                  value={asset.asset_id}
                  checked={selectedAssets.includes(asset.asset_id)}
                  onChange={handleChange}
                  name={asset.asset_name}
                />
              }
              label={asset.asset_name}
            />
          ))}
        </FormGroup>

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isDragActive}
                onChange={(e) => setIsDragActive(e.target.checked)}
              />
            }
            label={translate("labels.edit_chart_order")}
          />
        </FormGroup>
      </FormControl>

      <Stack flex={1} spacing={2}>
        <Reorder.Group
          axis="y"
          values={selectedAssets}
          onReorder={setSelectedAssets}
          as="div"
          style={{
            gap: 16,
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {selectedAssets.map((asset_id) => (
            <AssetComparisonChart
              key={asset_id}
              phase_id={phase_id}
              subphase_name={subphase_name}
              isDragActive={isDragActive}
              asset={assets.find((asset) => asset.asset_id === asset_id)!}
            />
          ))}
        </Reorder.Group>
      </Stack>
    </Stack>
  );
}

function AssetComparisonChart({
  asset,
  isDragActive,
  subphase_name,
}: {
  asset: Asset;
  isDragActive: boolean;
  phase_id: number;
  subphase_name: string;
}) {
  const translate = useTranslate();
  const controls = useDragControls();
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);

  const navigate = useNavigate();
  const [{ filteredBatches, batches }, dispatch] = useLyoContext();

  const { data } = useGetAssetDistanceCoefficient({
    asset_id: asset.asset_id,
    batches: filteredBatches,
    subphase_name: subphase_name,
  });

  if (!data) {
    return <Box>{translate("user_feedback.loading")}</Box>;
  }

  return (
    <Reorder.Item
      as="div"
      dragListener={false}
      id={asset.asset_id.toString()}
      dragControls={controls}
      value={asset.asset_id}
      style={{ boxShadow }}
    >
      <LightContainer
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flex: 1,
          width: "100%",
        }}
      >
        <Box flex={1}>
          {isDragActive && (
            <Typography
              variant="h6"
              fontSize="1rem"
              sx={{
                transform: "unset",
              }}
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              {asset.asset_name}
            </Typography>
          )}
          <Box hidden={isDragActive}>
            <BarChart.Custom
              filename={fileNameFactory({
                name: asset.asset_name,
              })}
              zooming={{ type: "xy" }}
              showLogarithmicToggle={true}
              title={asset.asset_name}
              uom={""}
              tooltip={{
                enabled: true,
              }}
              legend={{
                enabled: true,
                options: {
                  layout: "vertical",
                  align: "right",
                  verticalAlign: "middle",
                },
              }}
              series={data.map((d) => {
                const batchName =
                  d.batch_name +
                  " - " +
                  TimeHelpers.parseTimestampToString({
                    timestamp: Number(d.subphase_start_time),
                    fmt: DATE_FORMAT_STRING,
                  });
                return {
                  name: batchName,
                  type: "column",
                  cursor: "pointer",
                  color: getColor(d.asset_status),
                  centerInCategory: true,
                  data: [
                    {
                      y: d.distance_coefficient,
                      custom: {
                        batchId: d.batch_id,
                      },
                      name: batchName,
                    },
                  ],
                  events: {
                    click: function (e: unknown) {
                      const event = z
                        .object({
                          point: z.object({
                            custom: z.object({
                              batchId: z.number().nullable(),
                            }),
                          }),
                        })
                        .safeParse(e);

                      if (!event.success) return;

                      const clickedBatchId = event.data.point.custom.batchId;

                      if (!clickedBatchId) return;

                      const clickedBatch = batches.find(
                        (batch) => batch.batchId === clickedBatchId,
                      );

                      if (!clickedBatch) return;

                      dispatch({
                        type: "pick batch",
                        selectedBatch: clickedBatch,
                      });
                      navigate("/analytics/lyophilizer/batch/diagnostic");
                    },
                  },
                };
              })}
            />
          </Box>
        </Box>
        {isDragActive && (
          <DragHandle
            onPointerDown={(e) => controls.start(e)}
            sx={{
              cursor: "grab",
              margin: "auto",
            }}
          />
        )}
      </LightContainer>
    </Reorder.Item>
  );
}
