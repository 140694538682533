import { TableParser } from "@/utils/TableParser";
import { GoldenInfo } from "../api/useGetGoldenInfo";
import { BasicTable } from "@/components/BasicTable";

export const InfoTable = ({ goldenInfo }: { goldenInfo: GoldenInfo[] }) => {
  const { columns, rows } = TableParser.parseData<GoldenInfo>({
    data: goldenInfo,
    columnsMap: {
      subphase_name: "Subphase",
      recipe: "Recipe",
      timestampstart: "From",
      phase_name: "Phase",
      timestampend: "To",
    },
    columnsOrder: [
      "phase_name",
      "subphase_name",
      "recipe",
      "timestampstart",
      "timestampend",
    ],
  });
  return (
    <BasicTable columns={columns} rows={rows} fileName="golden_info_data" />
  );
};
