import { useQuery } from "@tanstack/react-query";
import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "@/context/firebase-context";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { performanceViewStore } from "@/features/OverallLineEfficiency/store/performance-view-store";
import { useHistoricLineSelectedFrame } from "../context/historic-line-selected-frame.hook";

type MachineNodeDataResponse = ThingworxError | MachineNodeData;

const MachineNodeData = z.object({
  response: z.literal(true),
  state: z.string(),
  state_color: z.string().optional(),
  category: z.enum([
    "Disconnected",
    "Producing",
    "Not Producing",
    "Line",
    "Setup Pre-Production",
    "Intentional No Production",
  ]),
  hasSpeed: z.boolean(),
  oee: z.number().optional(),
  lineLosses: z.number(),
  processLosses: z.number(),
  machineLosses: z.number(),
  extraCapacity: z.number(),
  total_products: z.number(),
  rejected_products: z.number(),
  products: z.number(),
  machine_type: z.enum(["cyclic", "process"]),
  machineLogic: z.enum(["static", "parametric"]),
  hasBatch: z.boolean(),
  label: z.string().optional(),
  alarm_code: z.number().optional(),
  alarm_description: z.string().optional(),
});

export type MachineNodeData = z.infer<typeof MachineNodeData>;

type MachineNodeDataPayload = {
  machineName: string;
  view_mode: string;
  id: number;
};

export const useGetHistoricMachineData = ({ machine }: { machine: string }) => {
  const { id } = useHistoricLineSelectedFrame();
  const { view } = performanceViewStore();
  const { appKey } = useFirebaseContext();

  return useQuery<MachineNodeData>({
    queryKey: ["historic-machine-data", machine, view, id],
    queryFn: async () => {
      const response = await FetchClient<
        MachineNodeDataPayload,
        MachineNodeDataResponse
      >({
        url: "dig.c.plantOverview_thing/services/getHistoricMachineDetail",
        appKey,
        payload: {
          id,
          machineName: machine,
          view_mode: view,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return MachineNodeData.parse(response);
    },
    retry: 3,
  });
};
