import { toast } from "react-hot-toast";
import { Button, CircularProgress } from "@mui/material";
import { useGetUserProductionLineById } from "@/features/OverallLineEfficiency/api/useGetUserProductionLineById";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "@/i18n/config";
import { useCheckUserPage } from "@/hooks/check-user-page";

export const LineDetailButton = ({ lineId }: { lineId: number }) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { hasAccess } = useCheckUserPage("overall-line-efficiency");
  const { mutate: getLineData, isLoading } = useGetUserProductionLineById();
  const handleClick = () => {
    getLineData(
      { lineId },
      {
        onSuccess(data) {
          if (data.line.is_configured) {
            navigate("../../../overview/overall-line-efficiency/line-detail", {
              relative: "path",
              replace: true,
              state: { view_mode: "automatic", line: data.line },
            });
          } else {
            toast.error("Line not configured");
          }
        },
        onError() {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    );
  };

  return hasAccess ? (
    <Button variant="outlined" onClick={handleClick} disabled={isLoading}>
      Line detail
      {isLoading && <CircularProgress size={16} sx={{ ml: 1 }} />}
    </Button>
  ) : null;
};
