import { TimeHelpers } from "@/utils/TimeHelpers";
import {
  AlarmStream,
  DataRow,
  Recipe,
  Result,
} from "../api/useGetDownloadData";

export type AlarmDetails = {
  alarmStream: AlarmStream[];
  alarmSummary: Result[]; //! potrebbe rompersi per il no data AlarmNoData
};

export interface GenericsData {
  timelapse: string;
  oee: number;
  availability?: number;
  performance?: number;
  quality: number;
  recipe: Recipe[];
  alarmDetails: AlarmDetails;
  dataStartTimelapse: number;
  uptime: number;
  allTurnTime: number;
  counter?: number;
  wasteCounterLine?: number;
  wasteCounter?: number;
  wasteCounterInteraction?: number;
}
export type DynamicKeys = Record<
  string,
  number | string | Recipe[] | AlarmDetails
>;
export type GenericsDataWithDynamicKeys = GenericsData & DynamicKeys;

export function parseDownloadData({ dataTable }: { dataTable: DataRow[] }) {
  const processedDataTable: Array<GenericsDataWithDynamicKeys> = [];
  dataTable.forEach((item) => {
    const { alarmCountDuration, alarmStream, formatSummary, ...rest } = item;
    const { stateParametrics, ...others } = rest;

    const stateParametricsMapped = Object.fromEntries(
      Object.entries(stateParametrics).map(([key, value]) => [
        key.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
          if (+match === 0) return ""; // Remove leading digits
          return index === 0 ? match.toLowerCase() : match.toUpperCase();
        }),
        TimeHelpers.parseDurationToString({ duration: value * 1000 }),
      ]),
    );

    /** @description destrutturazione di alarmCountDuration */
    const { Result } = alarmCountDuration;
    //! MI VIENE PASSATO O UN OGGETTO O UN "NO DATA"
    const alarmSummary =
      Result === "No Data"
        ? []
        : typeof Result === "object"
        ? Object.values(Result).map((value) => value)
        : Result;
    const alarmDetails = {
      alarmStream,
      alarmSummary,
    };
    const restWithStates = {
      ...others,
      ...stateParametricsMapped,
      recipe: formatSummary.Recipe,
      alarmDetails: alarmDetails,
    };
    processedDataTable.push(restWithStates);
  });
  return processedDataTable;
}
