import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import {
  useGetProcessData,
  ProcessDataResult,
} from "@/features/MachineDetail/api/ProcessLive/useGetProcessData";
import { GaugeLayout } from "@/features/MachineDetail/components/GaugeLayout";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { colors } from "@/styles/colors";
import { Box, Stack } from "@mui/material";
import { CurrentMachineState } from "@/features/MachineDetail/components/CurrentMachineState";
import { Recipe } from "@/features/MachineDetail/components/Recipe";
import { GridItem } from "@/features/MachineDetail/components/GridItem";
import { Card } from "@/components/Layout/Card";
import { Piechart } from "@/components/highcharts/piechart/Piechart";
import { useGetRecipe } from "@/features/MachineDetail/api/useGetRecipe";
import { NavButton } from "@/components/NavButton";
import {
  AreaSeries,
  Chart,
  HighchartsChart,
  Legend,
  LineSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from "react-jsx-highcharts";
import { HighchartsCustomProvider } from "@/components/highcharts/highcharts";
import { DateTime } from "luxon";
import { useTimeSelection } from "@/store/useTimeSelection";
import { ProcessLoadingView } from "./ProcessLoadingView";
import { useCheckMachinePage } from "@/hooks/check-machine-page";
import { useTranslate } from "@/i18n/config";
import { generatePlotBands } from "@/features/MachineDetail/utils/generatePLotBands";
import { LineDetailButton } from "@/features/MachineDetail/components/LineDetailButton";
import { useMachineContext } from "@/context/machine-context";

export const ProcessLiveView = () => {
  const { data: processData, isLoading, error } = useGetProcessData();
  const translate = useTranslate();
  const { hasAccess } = useCheckMachinePage("historicMachineDetail");
  const { machine } = useMachineContext();

  if (isLoading) {
    return <ProcessLoadingView />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!processData) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection exclude={["Custom"]} />
        {hasAccess ? (
          <NavButton
            to="/machine-detail/process/historic"
            text={translate("view_historic")}
          />
        ) : null}
        {machine?.lineId && <LineDetailButton lineId={machine.lineId} />}
      </MachineViewNavBar>
      {processData ? <ProcessDataView data={processData} /> : null}
    </>
  );
};

function ProcessDataView({ data }: { data: ProcessDataResult }) {
  const { data: recipeContent } = useGetRecipe();
  const { timeSelection } = useTimeSelection();
  const translate = useTranslate();
  const { hasAccess } = useCheckMachinePage("stateLog");

  const minimumDate =
    data.efficiencyData.oee[0]?.[0] ?? DateTime.now().toMillis();

  const maxDate =
    data.efficiencyData.oee[data.efficiencyData.oee.length - 1]?.[0] ??
    DateTime.now().minus({ days: 1 }).toMillis();

  const plotBands = generatePlotBands({
    data: data.efficiencyData.plotBands.data,
    currentState: data.stateString,
    minimumDate: minimumDate,
    maxDate: maxDate,
  });
  return (
    <Stack gap={2}>
      <Grid2 container columns={12} alignItems="center" spacing={2}>
        <Grid2 xs={6} md={4}>
          <GaugeLayout
            min={0}
            color={colors.kpi.oee}
            max={100}
            dimension="large"
            title={translate("kpi.poee")}
            type="percentage"
            value={data.kpiData.oee}
          ></GaugeLayout>
        </Grid2>

        <Grid2 xs={12} md={4}>
          <Stack gap={2}>
            <CurrentMachineState
              category={data.category}
              color={data.stateColor}
              state={data.stateString}
              linkTo={hasAccess ? "/state-log" : undefined}
              dateTimeHandler={() => {
                useTimeSelection.setState((prevState) => ({
                  ...prevState,
                  dates: {
                    dateStart:
                      timeSelection === "Day"
                        ? DateTime.now().minus({ days: 1 })
                        : timeSelection === "Week"
                        ? DateTime.now().minus({ weeks: 1 })
                        : timeSelection === "Month"
                        ? DateTime.now().minus({ months: 1 })
                        : DateTime.now().minus({ hours: 8 }),
                    dateEnd: DateTime.now(),
                  },
                }));
              }}
            />
            <Recipe
              recipe={data.recipe}
              recipeChanged
              recipeClient={data.recipeClient}
              recipeContent={recipeContent}
            />
          </Stack>
        </Grid2>
        <Grid2 xs={12} md={4}>
          <Box
            sx={{
              paddingX: "15%",
              height: {
                sx: "200px",
                md: "auto",
              },
            }}
          >
            <Piechart
              data={data.pieChartData}
              label=""
              title=""
              version="machineDetail"
            />
          </Box>
        </Grid2>
      </Grid2>

      <Grid2 container columns={12} alignItems="center" spacing={2}>
        <GridItem>
          <GaugeLayout
            dimension="small"
            title={translate("kpi.process_quality")}
            value={data.kpiData.processQuality}
            min={0}
            max={100}
            type={"percentage"}
            color={colors.kpi.availability}
          />
        </GridItem>
        <GridItem>
          <GaugeLayout
            dimension="small"
            title={translate("machine.elapsed_time")}
            value={data.kpiData.plannedProductionTime * 1000}
            min={0}
            max={data.kpiData.totalTime * 1000}
            type={"duration"}
            color={colors.kpi.uptime}
          />
        </GridItem>
        <GridItem>
          <GaugeLayout
            dimension="small"
            title={translate("machine.planned_time")}
            value={data.kpiData.plannedProductionTime * 1000}
            min={0}
            max={data.kpiData.totalTime * 1000}
            type={"duration"}
            color={colors.kpi.quality}
          />
        </GridItem>
        <GridItem>
          <GaugeLayout
            dimension="small"
            title={translate("machine.net_uptime")}
            value={data.kpiData.netUptime * 1000}
            min={0}
            max={data.kpiData.totalTime * 1000}
            type={"duration"}
            color={colors.kpi.elapsedTime}
          />
        </GridItem>
        <GridItem>
          <GaugeLayout
            dimension="small"
            title={translate("machine.inactive_time")}
            value={data.kpiData.inactiveTime * 1000}
            min={0}
            max={data.kpiData.totalTime * 1000}
            type={"duration"}
            color={colors.kpi.inactiveTime}
          />
        </GridItem>
      </Grid2>

      <Card>
        <HighchartsCustomProvider>
          <HighchartsChart
            exporting={{
              enabled: false,
            }}
            accessibility={{ enabled: false }}
            plotOptions={{}}
          >
            <Chart
              backgroundColor={"transparent"}
              type=""
              zooming={{ type: "x" }}
              style={{
                color: "white",
              }}
            />
            <Title style={{ color: "white" }} align="left">
              KPIs performance
            </Title>
            <Tooltip shared useHTML />

            <Legend
              itemStyle={{
                color: "white",
              }}
            ></Legend>
            <XAxis
              labels={{ style: { color: "white" } }}
              scrollbar={{
                enabled: false,
              }}
              type={"datetime"}
            ></XAxis>

            <YAxis
              labels={{ style: { color: "white" }, format: "{value} " + "%" }}
              showEmpty
              type={"linear"}
              opposite
              max={100}
              min={0}
              alignTicks={false}
            >
              <LineSeries
                marker={{
                  enabled: false,
                  states: {
                    hover: {
                      enabled: false,
                    },
                  },
                }}
                borderColor="none"
                color={colors.kpi.processOee}
                dashStyle={"Solid"}
                type={"line"}
                name={translate("kpi.process_oee")}
                data={data.efficiencyData.oee}
              />
              <LineSeries
                marker={{
                  enabled: false,
                  states: {
                    hover: {
                      enabled: false,
                    },
                  },
                }}
                dashStyle={"LongDash"}
                color={colors.kpi.processQuality}
                name={translate("kpi.process_quality")}
                data={data.efficiencyData.quality}
              />
              <AreaSeries
                color={"#0ab126"}
                opacity={0.4}
                name={
                  data.efficiencyData.plotBands.name ?? translate("production")
                }
                data={plotBands}
                enableMouseTracking={false}
              />
            </YAxis>
          </HighchartsChart>
        </HighchartsCustomProvider>
      </Card>
    </Stack>
  );
}
