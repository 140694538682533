import { Stack } from "@mui/material";
import { toast } from "react-hot-toast";
import { useTranslate } from "@/i18n/config";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { intellectaVersionStore } from "../../store/intellecta-version";
import { HistoricChat } from "./HistoricChat";
import { Chat } from "../../types";
import { useGetHistoricChatList } from "../../api/useGetHistoricChatList";

export const Historic = () => {
  const translate = useTranslate();
  const { version } = intellectaVersionStore();
  const { isLoading, data, error } = useGetHistoricChatList();

  if (isLoading) {
    return (
      <Stack p={2} gap={1}>
        <SkeletonCard height="42px" sx={{ borderRadius: 4 }} />
        <SkeletonCard height="42px" sx={{ borderRadius: 4 }} />
        <SkeletonCard height="42px" sx={{ borderRadius: 4 }} />
      </Stack>
    );
  }

  if (error) {
    return toast.error(translate("user_feedback.an_error_occurred"));
  }

  if (!data) {
    return toast.error(translate("user_feedback.no_data"));
  }

  const historicChats = [...data.chat_history];
  const filteredByVersion = historicChats.filter(
    (chat) => chat.version === version,
  );

  const pinnedChats: Chat[] = [];
  const unpinnedChats: Chat[] = [];
  filteredByVersion.forEach((chat) => {
    if (chat.is_pinned) {
      pinnedChats.push(chat);
    } else {
      unpinnedChats.push(chat);
    }
  });
  const sortedPinnedChats = pinnedChats.sort(
    (a, b) => b.timestamp - a.timestamp,
  );
  const sortedUnpinnedChats = unpinnedChats.sort(
    (a, b) => b.timestamp - a.timestamp,
  );

  return (
    <Stack p={2} gap={1}>
      {sortedPinnedChats.map((chat) => (
        <HistoricChat key={chat.id} chat={chat} />
      ))}
      {sortedUnpinnedChats.map((chat) => (
        <HistoricChat key={chat.id} chat={chat} />
      ))}
    </Stack>
  );
};
