import { Button, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import {
  Person2Outlined,
  LogoutOutlined,
  SettingsOutlined,
  HelpOutlineOutlined,
} from "@mui/icons-material";
import { useFirebaseContext } from "../../../context/firebase-context";
import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useDisclosure } from "@/hooks/useDisclosure";
import { HelpModal } from "./HelpManual";
import { useTranslate } from "@/i18n/config";
import { useState } from "react";

export const UserMenu = () => {
  const translate = useTranslate();
  const { palette } = useTheme();

  const client = useQueryClient();
  const { authService, user } = useFirebaseContext();
  const {
    open: openHelpModal,
    close: closeHelpModal,
    isOpen: isOpenHelpModal,
  } = useDisclosure();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.clear();
    sessionStorage.clear();
    client.clear();
    signOut(authService);
  };

  const handleOpenHelpModal = () => {
    openHelpModal();
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        sx={{
          backgroundColor: palette.info.dark,
          color: palette.text.primary,
          marginLeft: "auto",
        }}
      >
        <Person2Outlined />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose} disabled>
          {user?.email}
        </MenuItem>
        <Link
          to={"/user/settings"}
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <MenuItem>
            <SettingsOutlined />
            <Typography sx={{ paddingLeft: "1rem" }}>
              {translate("settings")}
            </Typography>
          </MenuItem>
        </Link>
        <MenuItem onClick={handleOpenHelpModal}>
          <HelpOutlineOutlined />
          <Typography sx={{ paddingLeft: "1rem" }}>
            {translate("help")}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutOutlined />
          <Typography sx={{ paddingLeft: "1rem" }}>
            {translate("logout")}
          </Typography>
        </MenuItem>
      </Menu>
      {isOpenHelpModal && (
        <HelpModal isOpen={isOpenHelpModal} close={closeHelpModal} />
      )}
    </>
  );
};
