import { Tabs, Tab, Box } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { SummaryNavigation } from "../../components/Navigation/SummaryNavigation";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const routes = [
  "/analytics/lyophilizer/summary/production-overview",
  "/analytics/lyophilizer/summary/phase-overview",
  "/analytics/lyophilizer/summary/predictive-analytics-overview",
];

export const SummaryPage = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <SummaryNavigation />

      <Tabs value={pathname} aria-label="basic tabs example">
        <Tab
          component={Link}
          to={routes[0]}
          value={routes[0]}
          label="Production Overview"
          {...a11yProps(0)}
        />
        <Tab
          component={Link}
          to={routes[1]}
          value={routes[1]}
          label="Phase Overview"
          {...a11yProps(1)}
        />{" "}
        <Tab
          component={Link}
          to={routes[2]}
          value={routes[2]}
          label="Predictive Analytics Overview"
          {...a11yProps(2)}
        />
      </Tabs>
      <Box sx={{ paddingTop: "1rem" }} />
      <Outlet />
    </div>
  );
};
