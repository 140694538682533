import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { QueryBuilder } from "@/utils/query-builder";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type MaterialsSummaryResponse = ThingworxError | MaterialsSummaryResult;

const MaterialSummaryDetail = z.object({
  ErrorDescription: z.string(),
  FamilyDescription: z.string(),
  Timestamp: z.number(),
  ZoneDescription: z.string(),
});

export type MaterialSummaryDetail = z.infer<typeof MaterialSummaryDetail>;

const MaterialsSummaryResult = z.object({
  response: z.literal(true),
  Result: z.array(MaterialSummaryDetail),
});

type MaterialsSummaryResult = z.infer<typeof MaterialsSummaryResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateEnd?: DateTime;
  dateStart?: DateTime;
};

/**
 * returns data for the big table in the lower section of the original page
 */
export const useGetMaterialStream = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  const { timeSelection, dates } = useTimeSelection();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: ["materials-summary-data", machine?.machine, timeSelection],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload = {
    machineName: machine?.machine || "",
    timeSelection,
  };

  return useQuery<MaterialSummaryDetail[]>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, MaterialsSummaryResponse>({
        appKey,
        payload:
          timeSelection === "Custom"
            ? { ...payload, dateStart: dates.dateStart, dateEnd: dates.dateEnd }
            : payload,
        url: "dig.c.material_thing/Services/getStreamSelector",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return MaterialsSummaryResult.parse(response).Result;
    },
  });
};
