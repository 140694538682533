import { ArrowRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { chatStore } from "../../store/chat-store";
import { useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";

export const NewChatButton = () => {
  const translate = useTranslate();
  const { user } = useFirebaseContext();
  const email = user?.email || "";
  const queryClient = useQueryClient();
  const { resetTimer } = sentryModeStore();
  const { clearChat } = chatStore();

  const handleClick = () => {
    clearChat();
    resetTimer();
    queryClient.invalidateQueries({
      queryKey: ["intellecta-historic", email],
    });
  };

  return (
    <Button
      fullWidth
      endIcon={<ArrowRight />}
      variant="outlined"
      color="primary"
      onClick={handleClick}
    >
      {translate("intellecta.new_chat")}
    </Button>
  );
};
