import { z } from "zod";
import { Box } from "@mui/material";
import { toast } from "react-hot-toast";
import { XrangePointOptionsObject } from "highcharts";
import { useTranslate } from "@/i18n/config";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Card } from "@/components/Layout/Card";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Evaluation, Frame } from "../../types";
import { useMotorsContext } from "../../context/useMotorsContext";
import { useMotorsDispatchContext } from "../../context/useMotorsDispatchContext";
import { MAX_FRAMES_NUMBER } from "../../context/motors-context";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";
import { humanizeString } from "../../utils/humanizeString";
import { useGetFrames } from "../../api/useGetFrames";

const parseData = ({
  data,
  selectedFrames,
}: {
  data: Frame[];
  selectedFrames: Frame[];
}): XrangePointOptionsObject[] => {
  return data.map((occurrence) => {
    const { timelapse, dateStart, dateEnd, id, status } = occurrence;
    const isSelected = selectedFrames.find((frame) => frame.id === id);
    const color =
      EVALUATION_COLORS[status][isSelected ? "selected" : "notSelected"];

    return {
      x: dateStart,
      x2: dateEnd,
      y: 0,
      color,
      name: timelapse,
      custom: {
        status,
      },
    };
  });
};

export const FramesSelection = () => {
  const translate = useTranslate();
  const { data: apiData, error, isLoading } = useGetFrames();
  const { frames } = useMotorsContext();
  const dispatch = useMotorsDispatchContext();

  if (isLoading) return <SkeletonCard height={180} />;
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!apiData) return <Card>{translate("user_feedback.no_data")}</Card>;

  const data = parseData({ data: apiData.frames, selectedFrames: frames });

  const onClickFrame = ({ start, end }: { start: number; end: number }) => {
    const clonedApiData = [...apiData.frames];
    const selectedFrame = clonedApiData.find(
      (item) => item.dateStart === start && item.dateEnd === end,
    );
    if (selectedFrame) {
      const isAlreadySelected =
        frames.findIndex((frame) => frame.id === selectedFrame.id) > -1;
      if (isAlreadySelected) {
        dispatch({ type: "REMOVE_FRAME", frame: selectedFrame });
      } else {
        if (frames.length >= MAX_FRAMES_NUMBER) {
          toast.error(
            translate("user_feedback.max_n_selected", {
              max: MAX_FRAMES_NUMBER,
            }),
          );
        } else {
          dispatch({ type: "ADD_FRAME", frame: selectedFrame });
        }
      }
    }
  };

  return (
    <Box height={100}>
      <XRangeChart
        customHeight={100}
        allowExporting={false}
        categories={[translate("frame_other")]}
        title=""
        data={data}
        tooltipFormatter={function () {
          const parseResult = z
            .object({
              x: z.number(),
              x2: z.number(),
              color: z.string(),
              key: z.string(),
              point: z.object({
                custom: z.object({
                  status: Evaluation,
                }),
              }),
            })
            .safeParse(this);
          if (!parseResult.success) return;

          const {
            color,
            key,
            x,
            x2,
            point: {
              custom: { status },
            },
          } = parseResult.data;

          const dateStart = TimeHelpers.parseTimestampToString({
            timestamp: x,
          });
          const dateEnd = TimeHelpers.parseTimestampToString({
            timestamp: x2,
          });
          const durationString = TimeHelpers.parseDurationToString({
            duration: x2 - x,
          });

          const category = key;
          return `<span style="color:${color}">●</span> ${category}<br>
                    ${translate("start")}: <b>${dateStart}</b><br>
                    ${translate("end")}: <b>${dateEnd}</b><br>
                    ${translate("duration")}: <b>${durationString}</b><br>
                    Status: <b>${humanizeString(status)}</b>
                  `;
        }}
        seriesOptions={{
          cursor: "pointer",
          events: {
            click: function (event) {
              const point: XrangePointOptionsObject = event.point;
              onClickFrame({ start: point.x!, end: point.x2! });
            },
          },
        }}
      />
    </Box>
  );
};
