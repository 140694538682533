import { Card } from "@/components/Layout/Card";
import { RecipeModalContent } from "@/features/MachineDetail/components/RecipeModal";
import { useDisclosure } from "@/hooks/useDisclosure";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { InfoOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { ColumnDefinition, Recipe } from "../api/useGetDownloadData";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import { AlarmModalContent } from "./AlarmModalContent";
import { FullScreenDialog } from "@/components/FullScreenDialog";
import { GenericsDataWithDynamicKeys } from "../utils/parse-cyclic-data";
import { DownloadParser } from "../utils/download-parser";
import { useTranslate } from "@/i18n/config";

export const DownloadTable = ({
  dataTable,
  columnsDef,
  maxBad = 50,
  maxMedium = 80,
}: {
  dataTable: GenericsDataWithDynamicKeys[];
  columnsDef: ColumnDefinition[];
  maxBad?: number;
  maxMedium?: number;
}) => {
  const { columns, rows } =
    dataTable.length > 0
      ? DownloadParser.parse({
          columnsDefinitions: columnsDef,
          rowValues: dataTable,
        })
      : { columns: [], rows: [] };

  const oeeColumnDef = columns.findIndex((item) => item.field === "oee");
  if (oeeColumnDef !== -1) {
    columns[oeeColumnDef] = {
      ...columns[oeeColumnDef],
      cellClassName: (params): string => {
        return params.value < maxBad
          ? "bad"
          : params.value >= maxBad && params.value <= maxMedium
          ? "medium"
          : "good";
      },
    };
  }

  const recipeColumnDef = columns.findIndex((item) => item.field === "recipe");
  if (recipeColumnDef !== -1) {
    columns[recipeColumnDef].renderCell = (params: GridRenderCellParams) => (
      <RecipeContent {...params} />
    );
  }
  const alarmDetailsColumnDef = columns.findIndex(
    (item) => item.field === "alarmDetails",
  );
  if (alarmDetailsColumnDef !== -1) {
    columns[alarmDetailsColumnDef].renderCell = (
      params: GridRenderCellParams,
    ) => <AlarmDetailContent {...params} />;
  }

  return (
    <Card>
      <Box
        height={"70vh"}
        width={"100%"}
        sx={{
          "& .bad": {
            color: "red",
            fontWeight: "bold",
          },
          "& .medium": {
            color: "orange",
            fontWeight: "bold",
          },
          "& .good": {
            color: "green",
            fontWeight: "bold",
          },
        }}
      >
        <DataGridPremium
          components={{ Toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              excelOptions: {
                fileName: "Sentinel-Download",
              },
              csvOptions: {
                fileName: "Sentinel-Download",
              },
            },
          }}
          columns={columns}
          rows={rows}
        />
      </Box>
    </Card>
  );
};

const RecipeContent = (params: GridRenderCellParams) => {
  const { value } = params;
  const translate = useTranslate();
  const { open, isOpen, close } = useDisclosure();
  return (
    <>
      <GridActionsCellItem
        icon={
          <InfoOutlined width={48} height={48} sx={{ cursor: "pointer" }} />
        }
        label={translate("machine.recipe")}
        onClick={() => {
          open();
        }}
      />
      <RecipeModalContent
        recipeName={""}
        handleClose={() => {
          close();
        }}
        recipeContent={value.map((item: Recipe) => ({
          ...item,
          Duration: TimeHelpers.parseDurationToString({
            duration: item.Duration * 1000,
          }),
        }))}
        openModal={isOpen}
      />
    </>
  );
};

const AlarmDetailContent = (params: GridRenderCellParams) => {
  const { value } = params;
  const translate = useTranslate();
  const { open, isOpen, close } = useDisclosure();
  return (
    <>
      <GridActionsCellItem
        icon={<ZoomInOutlinedIcon sx={{ cursor: "pointer" }} />}
        label={translate("machine.recipe")}
        onClick={() => {
          open();
        }}
      />
      <FullScreenDialog
        handleClose={() => {
          close();
        }}
        open={isOpen}
        title={translate("alarms")}
      >
        <AlarmModalContent alarmDetailsContent={value} />
      </FullScreenDialog>
    </>
  );
};
