import { useState } from "react";
import { Box } from "@mui/material";
import { NoveltyAnomalyAnalyticsSelection } from "../components/Diagnostic/NoveltyAnomalyAnalyticsSelection";
import { NoveltyAnomalyAnalytics } from "../components/Diagnostic/NoveltyAnomalyAnalytics";

export const NoveltyAnomalyDetection = () => {
  const [noveltyAnalytics, setNoveltyAnalytics] = useState<string[]>([]);
  const [anomalyAnalytics, setAnomalyAnalytics] = useState<string[]>([]);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <NoveltyAnomalyAnalyticsSelection
        noveltyAnalytics={noveltyAnalytics}
        anomalyAnalytics={anomalyAnalytics}
        setNoveltyAnalytics={setNoveltyAnalytics}
        setAnomalyAnalytics={setAnomalyAnalytics}
      />
      <NoveltyAnomalyAnalytics
        noveltyAnalytics={noveltyAnalytics}
        anomalyAnalytics={anomalyAnalytics}
      />
    </Box>
  );
};
