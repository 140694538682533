import { Card } from "@/components/Layout/Card";
import { ChevronRightOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { NavLink } from "react-router-dom";
import { StateCategory } from "../api/CyclicLive/useGetSentinelLiveData";
import { CategoryIcon } from "./CategoryIcon";

export const CurrentMachineState = ({
  color,
  category,
  state,
  linkTo,
  dateTimeHandler,
  iconSize = 64,
}: {
  color: string;
  category: StateCategory;
  state: string;
  linkTo?: string;
  dateTimeHandler?: () => void;
  iconSize?: number;
}) => {
  return (
    <Card sx={{ color }}>
      <Grid2 columns={12} container alignItems="center" spacing={1}>
        <Grid2
          flex={1}
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <CategoryIcon
            width={iconSize}
            height={iconSize}
            category={category}
            fill={color}
          />
        </Grid2>
        <Grid2
          flex={3}
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <Typography textAlign="center">{state}</Typography>
        </Grid2>
        {linkTo ? (
          <Grid2
            flex={1}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <NavLink
              to={linkTo}
              style={{ color, display: "flex" }}
              onClick={dateTimeHandler}
            >
              <ChevronRightOutlined color="inherit" />
            </NavLink>
          </Grid2>
        ) : (
          <Grid2
            flex={1}
            display="flex"
            justifyContent="center"
            alignContent="center"
          />
        )}
      </Grid2>
    </Card>
  );
};
