import { useState } from "react";
import { Tabs, Tab, Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Decomposition } from "./Decomposition";
import { NoveltyAnomalyDetection } from "../pages/NoveltyAnomalyDetection";
import { LongTermDrift } from "./LongTermDrift";
import { BatchRecipeSelection } from "../components/Selections/BatchRecipeSelection";
import { MotorSelection } from "../components/Selections/MotorSelection";

const SECTIONS = {
  DECOMPOSITION: 0,
  NOVELTY_ANOMALY_DETECTION: 1,
  LONG_TERM_DRIFT: 2,
};

export const Diagnostic = () => {
  const [section, setSection] = useState(SECTIONS.DECOMPOSITION);
  const translate = useTranslate();

  return (
    <>
      <Tabs
        value={section}
        aria-label="motors monitoring tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}
      >
        <Tab
          value={SECTIONS.DECOMPOSITION}
          label={translate("diagnostic.decomposition")}
        />
        <Tab
          value={SECTIONS.NOVELTY_ANOMALY_DETECTION}
          label={translate("diagnostic.novelty_anomaly_detection")}
        />
        <Tab
          value={SECTIONS.LONG_TERM_DRIFT}
          label={translate("diagnostic.long_term_drift")}
          disabled
        />
      </Tabs>
      <Stack gap={1}>
        <BatchRecipeSelection />
        <MotorSelection />
        {section === SECTIONS.DECOMPOSITION ? (
          <Decomposition />
        ) : section === SECTIONS.NOVELTY_ANOMALY_DETECTION ? (
          <NoveltyAnomalyDetection />
        ) : section === SECTIONS.LONG_TERM_DRIFT ? (
          <LongTermDrift />
        ) : null}
      </Stack>
    </>
  );
};
