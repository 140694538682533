import { useQuery } from "@tanstack/react-query";
import { useEditStore } from "../store/edit-management-store";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { PieData } from "@/features/MachineDetail/api/CyclicLive/useGetSentinelLiveData";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { z } from "zod";

type ConfigurableOeeResponse = ThingworxError | ConfigurableOeeResult;

const StateInfo = z.object({
  stateName: z.string(),
  oeeGain: z.number(),
  availabilityGain: z.number(),
  performanceGain: z.number(),
  duration: z.number(),
  id: z.number(),
  isClientRelevance: z.boolean(),
  isEfficiencyRelevance: z.boolean(),
});

export type StateInfo = z.infer<typeof StateInfo>;

const KpiProperty = z.object({
  oee: z.number(),
  availability: z.number(),
  inactiveTime: z.number(),
  downtime: z.number(),
  performance: z.number(),
});

export type KpiProperty = z.infer<typeof KpiProperty>;

export const ComparisonKpiType = z.object({
  standardKpi: KpiProperty,
  customKpi: KpiProperty,
});

export type ComparisonKpiType = z.infer<typeof ComparisonKpiType>;

const ConfigurableOeeResult = z.object({
  pieData: z.array(PieData),
  standardAvailability: z.number(),
  standardOee: z.number(),
  customAvailability: z.number(),
  customOee: z.number(),
  statesInfo: z.array(StateInfo),
  comparisonKpi: ComparisonKpiType,
  performance: z.number(),
  standardPerformance: z.number().transform((v) => Number(v.toFixed(2))),
  customPerformance: z.number().transform((v) => Number(v.toFixed(2))),
  quality: z.number(),
  productionTime: z.number(),
  response: z.literal(true),
  calculatedAt: z.number(),
});

type ConfigurableOeeResult = z.infer<typeof ConfigurableOeeResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
};

export const useGetConfigurableOeeData = () => {
  const isEditable = useEditStore((state) => state.isEditable);
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();

  const payload = {
    machineName: machine!.machine,
    timeSelection: timeSelection,
  };

  return useQuery<ConfigurableOeeResult>({
    queryKey: ["configurable-oee", machine?.machine, timeSelection],
    queryFn: async () => {
      const response = await FetchClient<Payload, ConfigurableOeeResponse>({
        appKey,
        payload,
        url: "dig.c.configurableOee_thing/services/getData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }
      const validatedResponse = ConfigurableOeeResult.parse(response);
      return validatedResponse;
    },
    refetchInterval: isEditable ? false : 1000 * 30,
  });
};
