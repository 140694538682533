import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Card } from "@/components/Layout/Card";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/system";
import { CurrentMachineState } from "../MachineDetail/components/CurrentMachineState";
import { Recipe } from "../MachineDetail/components/Recipe";
import { GaugesLayout } from "./components/GaugesLayout";
import { LeftSide } from "./components/LeftSideSection";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { LogState, useGetLogState } from "./api/useGetLogState";
import { colors } from "@/styles/colors";
import { useAcceptanceTestContext } from "./context/acceptanceTest-context";
import { LiveTable } from "./components/LiveTable";
import { ControlPanel } from "./components/ControlPanel";
import { useGetRecipe } from "../MachineDetail/api/useGetRecipe";
import { DateTime } from "luxon";
import { LoaderCard } from "@/components/Layout/LoaderCard";
import { HistoricTable } from "./components/HistoricTable";
import { AcceptanceTestData, GaugesData, SplineChartData } from "./types";
import { XrangePointOptionsObject } from "highcharts/highstock";
import { useTranslate } from "@/i18n/config";

const RightSection = ({
  viewMode,
  generalInfos,
  oeeStream,
}: {
  viewMode: "Historic" | "Live";
  generalInfos: AcceptanceTestData["generalInfos"];
  oeeStream: AcceptanceTestData["oeeStream"];
}) => {
  const translate = useTranslate();

  const { data: recipeContent } = useGetRecipe();
  const kpisData: GaugesData[] = [
    createKpiData({
      name: translate("kpi.oee"),
      value: generalInfos[0].OEE,
      color: colors.kpi.oee,
    }),
    createKpiData({
      name: translate("kpi.availability"),
      value: generalInfos[0].Availability,
      color: colors.kpi.availability,
    }),
    createKpiData({
      name: translate("kpi.performance"),
      value: generalInfos[0].Performance,
      color: colors.kpi.performance,
    }),
    createKpiData({
      name: translate("kpi.quality"),
      value: generalInfos[0].Quality,
      color: colors.kpi.quality,
    }),
  ];

  const oeeStreamData = oeeStream.sort((a, b) => a.Timestamp - b.Timestamp);
  const lineChartDataArray: SplineChartData[] = [
    createLineChartData(
      translate("kpi.oee"),
      oeeStreamData.map((item) => [item.Timestamp, item.Oee]),
      colors.kpi.oee,
    ),
    createLineChartData(
      translate("kpi.availability"),
      oeeStreamData.map((item) => [item.Timestamp, item.Availability]),
      colors.kpi.availability,
    ),
    createLineChartData(
      translate("kpi.performance"),
      oeeStreamData.map((item) => [item.Timestamp, item.Performance]),
      colors.kpi.performance,
    ),
    createLineChartData(
      translate("kpi.quality"),
      oeeStreamData.map((item) => [item.Timestamp, item.Quality]),
      colors.kpi.quality,
    ),
  ];
  return (
    <Stack gap={2}>
      <Grid2
        container
        direction={{ xs: "column", md: "row" }}
        gap={1}
        justifyContent="space-between"
      >
        <Grid2 md={7.5} sx={{ padding: 0 }} order={{ md: 0, xs: 1 }}>
          <GaugesLayout gaugesData={kpisData} />
        </Grid2>
        <Grid2 md={4.3} sx={{ padding: 0 }}>
          <Stack gap={1}>
            {viewMode === "Live" ? <ControlPanel /> : null}
            <CurrentMachineState
              category={generalInfos[0].category}
              color={generalInfos[0].color}
              state={generalInfos[0].state}
              key={1}
              iconSize={34}
            />
            <Recipe
              recipe={generalInfos[0].Format}
              recipeChanged={false}
              recipeClient={translate("machine.recipe")}
              recipeContent={recipeContent}
            />
          </Stack>
        </Grid2>
      </Grid2>
      <Card sx={{ height: { md: "299px" } }}>
        <LineChart.Custom
          title={""}
          lineType="spline"
          xAxisOptions={{
            scrollbar: {
              enabled: false,
            },
          }}
          legend={{ enabled: true }}
          yAxis={[
            {
              uom: "%",
              options: {
                max: 100,
              },
              series: lineChartDataArray,
            },
          ]}
        />
      </Card>
    </Stack>
  );
};

const FooterSide = ({
  tableData,
}: {
  tableData: AcceptanceTestData["tableData"];
}) => {
  const { viewMode } = useAcceptanceTestContext();
  //* TABLE
  return (
    <Stack gap={2}>
      {viewMode === "Live" ? (
        <LiveTable tableData={tableData} />
      ) : (
        <HistoricTable tableData={tableData} />
      )}
      <LogStateChart />
    </Stack>
  );
};

const LogStateChart = () => {
  const { isSatActive, startUtc, viewMode, historicTimeLapse } =
    useAcceptanceTestContext();

  const { data: stateLogData } = useGetLogState(
    viewMode === "Live"
      ? {
          isSatActive,
          startTime: startUtc,
          endTime: DateTime.now().toMillis(),
        }
      : {
          startTime: historicTimeLapse.startTime,
          endTime: historicTimeLapse.endTime,
        },
  );

  if (!stateLogData) {
    return <LoaderCard />;
  }
  const XRangeData = mapXrangeData({
    rawData: stateLogData.Result,
    statesList: stateLogData.stateList,
  });
  return (
    <Card>
      <XRangeChart
        categories={stateLogData.stateList}
        data={XRangeData}
        title=""
      />
    </Card>
  );
};

function mapXrangeData({
  rawData,
  statesList,
}: {
  rawData: LogState[];
  statesList: string[];
}) {
  const transformedData: XrangePointOptionsObject[] = rawData.reduce<
    XrangePointOptionsObject[]
  >((acc: XrangePointOptionsObject[], item) => {
    const index = statesList.indexOf(item.State);
    if (index !== -1) {
      acc.push({
        name: item.State,
        x: item.TimestampStart,
        x2: item.TimestampEnd,
        y: index,
        color: item.color,
      });
    }
    return acc;
  }, []);
  return transformedData;
}

function createKpiData({ name, value, color }: GaugesData) {
  return {
    name: name,
    value: value,
    color,
  };
}

function createLineChartData(
  name: string,
  data: [number, number][],
  color: string,
): SplineChartData {
  return {
    name: name,
    type: "spline",
    data: data,
    color: color,
  };
}

export const AcceptanceTestContentWrapper = ({
  data,
}: {
  data: AcceptanceTestData;
}) => {
  const { customerInfo, generalInfos, machineInfo } = data;
  const infoData = {
    setpointSpeed: generalInfos[0].SetpointSpeed,
    goodProducts: generalInfos[0].GoodProducts || 0,
    startTime: generalInfos[0].StartTime || 0,
    duration: generalInfos[0].SatDuration || 0,
  };

  const machineData = {
    downtime: machineInfo[0].RelatedDownTimeSs,
    speedLosses: machineInfo[0].RelatedSpeedLosses,
    rejects: machineInfo[0].RelatedRejects,
    manualCorrection: machineInfo[0].ManualCorrectionRelated,
  };

  const customerData = {
    downtime: customerInfo[0].UnrelatedDownTimeSs,
    speedLosses: customerInfo[0].UnrelatedSpeedLosses,
    rejects: customerInfo[0].UnrelatedRejects,
    manualCorrection: customerInfo[0].ManualCorrectionUnrelated,
  };
  return (
    <Stack gap={2}>
      <Grid2
        container
        spacing={2}
        direction={{ md: "row", sm: "column", xs: "column" }}
        flexWrap="nowrap"
      >
        <Grid2 md={3} xs={12} order={{ md: 0, xs: 1 }}>
          <LeftSide
            info={infoData}
            machine={machineData}
            customer={customerData}
          />
        </Grid2>
        <Grid2 md={9} xs={12}>
          <RightSection
            viewMode={data.viewMode}
            generalInfos={data.generalInfos}
            oeeStream={data.oeeStream}
          />
        </Grid2>
      </Grid2>
      <FooterSide tableData={data.tableData} />
    </Stack>
  );
};
