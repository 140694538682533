import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { UserMenu } from "../../../../components/Layout/UserMenu/UserMenu";
import { useMachineContext } from "../../../../context/machine-context";
import { useState } from "react";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";

interface TopNavigationBarProps {
  children?: React.ReactNode;
}

const drawerWidth = 240;

export const MachineViewNavBar: React.FC<TopNavigationBarProps> = ({
  children,
}) => {
  const { machine } = useMachineContext();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { showBanner } = useCheckIfMessageBanner();

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          component="nav"
          sx={{
            width: "calc(100% - 64px)",
            mt: showBanner ? "32px" : 0,
            // boxShadow: "none",
            borderBottom: "1px solid #151B2F",
            backgroundImage: "none",
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={() => {
                setMobileOpen(true);
              }}
              sx={{
                display: { sm: "none" },
              }}
            >
              <Menu />
            </IconButton>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Button variant="contained" disabled>
                {machine?.machine}
              </Button>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    sm: "row",
                    xs: "column",
                  },
                  flex: 1,
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                {children}
              </Box>
              <UserMenu />
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            anchor="right"
            onClose={() => setMobileOpen(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: {
                xs: "block",
                sm: "none",
              },

              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                p: 2,
              }}
            >
              <Button variant="contained" disabled>
                {machine?.machine}
              </Button>
              {children}
              <UserMenu />
            </Box>
          </Drawer>
        </Box>
      </Box>
      <div style={{ height: "calc(64px)" }}></div>
    </>
  );
};
